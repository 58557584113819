import { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  Link
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { MainListItems, SecondaryListItems } from './NavListItems';
import UserList from './UserList';
import ProfileList from './ProfileList';
import CouponList from './CouponList';
import PlanList from './PlanList';
import SubscriptionList from './SubscriptionList';
import CustomerList from './CustomerList';
import DeletedUsersList from './DeletedUsersList';
import TransactionList from './TransactionList';
import SubscriptionsDashboard from './SubscriptionsDashboard';
import Settings from './Settings';
import VersionInfo from './VersionInfo';

function Copyright() {
  return (
    <>
      <Typography variant='body2' color='textSecondary' align='center'>
        {'Copyright © '}
        <Link color='inherit' href='https://material-ui.com/'>
          <s>Dave's</s> <s>James'</s> Jez's Awesome Admin Panel
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <VersionInfo />
    </>
  );
}

const drawerWidth = 240;
const closedDrawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: '#1c8893'
  },
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#1c8893'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginLeft: closedDrawerWidth,
    width: `calc(100% - ${closedDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  contentShift: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  doubleFixedHeight: {
    height: 480
  },
  button: {
    backgroundColor: '#58c09c',
    '&:hover': {
      backgroundColor: '#1c8893'
    }
  },
  fab: {
    color: '#fff',
    backgroundColor: '#58c09c',
    '&:hover': {
      backgroundColor: '#1c8893'
    }
  },
  q83: {
    color: '#1c8893'
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [transactionTotals, setTransactionTotals] = useState({
    total: 0,
    influencerTotal: 0,
    talentAgencyTotal: 0,
    brandTotal: 0,
    mediaPRTotal: 0
  });

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  if (!sessionStorage.getItem('auth-token')) {
    return <Navigate replace to='/' />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='absolute' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerToggle}>{open ? <ChevronLeftIcon /> : <MenuIcon />}</IconButton>
        </div>
        <Divider />
        <List>
          <MainListItems />
        </List>
        <Divider />
        <List>
          <SecondaryListItems />
        </List>
      </Drawer>
      <main className={open ? classes.contentShift : classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Routes>
            <Route path={`/users`} element={<UserList />} />
            <Route path={`/users/:type`} element={<UserList />} />
            <Route path={`/profiles/:type`} element={<ProfileList />} />
            <Route path={`/coupons`} element={<CouponList />} />
            <Route path={`/plans`} element={<PlanList />} />
            <Route path={`/subscriptions`} element={<SubscriptionList />} />
            <Route path={`/customers`} element={<CustomerList />} />
            <Route path={`/deleted`} element={<DeletedUsersList />} />
            <Route
              path={`/transactions`}
              element={
                <TransactionList
                  classes={classes}
                  transactionTotals={transactionTotals}
                  setTransactionTotals={setTransactionTotals}
                />
              }
            />
            <Route path={`/settings`} element={<Settings />} />
            <Route path='/' element={<SubscriptionsDashboard classes={classes} />} />
          </Routes>
        </Container>
        <Copyright />
      </main>
    </div>
  );
}
