import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

interface Props {
  onSetDialogContent: (content: string | null) => void;
  dialogContent: string;
}

export const SocialDialog = (props: Props): JSX.Element => {
  const { onSetDialogContent, dialogContent } = props;

  return (
    <Dialog
      open={true}
      onClose={() => {
        onSetDialogContent(null);
      }}
    >
      <DialogTitle>Result...</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSetDialogContent(null);
          }}
          color='primary'
          autoFocus
        >
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
