import axios from 'axios';
import AppConfig from './appConfig';

const api = (path: string, payload?: any, headers?: Record<string, string>) => {
  let config = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      ...headers
    }
  };

  if (payload) {
    return axios.post(`${AppConfig.API_URL}${path}`, payload, config);
  } else {
    return axios.get(`${AppConfig.API_URL}${path}`, config);
  }
};

export default api;
