import { ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import FaceIcon from '@mui/icons-material/Face';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import PeopleIcon from '@mui/icons-material/People';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useParams } from 'react-router-dom';

export const MainListItems = () => {
  const location = useParams();
  const path = location['*'];
  return (
    <div className='navigation-panel'>
      <ListItemButton component={Link} to={``} selected={path === ''}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary='Dashboard' />
      </ListItemButton>
      <ListItemButton component={Link} to={`users`} selected={path === 'users'}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary='Users' />
      </ListItemButton>
      <ListItemButton component={Link} to={`users/INFLUENCER`} sx={{ pl: 4 }} selected={path === 'users/INFLUENCER'}>
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText primary='Kitly App' />
      </ListItemButton>
      <ListItemButton component={Link} to={`users/BRAND`} sx={{ pl: 4 }} selected={path === 'users/BRAND'}>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary='Kitly Business' />
      </ListItemButton>
      <ListItemButton component={Link} to={`deleted`} sx={{ pl: 4 }} selected={path === 'deleted'}>
        <ListItemIcon>
          <MoodBadIcon />
        </ListItemIcon>
        <ListItemText primary='Deleted Accounts' />
      </ListItemButton>
      <ListItemButton component={Link} to={`profiles`} selected={path === 'profiles'}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary='Profiles' />
      </ListItemButton>
      <ListItemButton component={Link} to={`coupons`} selected={path === 'coupons'}>
        <ListItemIcon>
          <LoyaltyIcon />
        </ListItemIcon>
        <ListItemText primary='Coupon Codes' />
      </ListItemButton>
      <ListItemButton component={Link} to={`plans`} selected={path === 'plans'}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary='Plans' />
      </ListItemButton>
      <ListItemButton component={Link} to={`subscriptions`} selected={path === 'subscriptions'}>
        <ListItemIcon>
          <AutorenewIcon />
        </ListItemIcon>
        <ListItemText primary='Subscriptions' />
      </ListItemButton>
      <ListItemButton component={Link} to={`transactions`} selected={path === 'transactions'}>
        <ListItemIcon>
          <ImportExportIcon />
        </ListItemIcon>
        <ListItemText primary='Transactions' />
      </ListItemButton>
      <ListItemButton component={Link} to={`settings`} selected={path === 'settings'}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary='Settings' />
      </ListItemButton>
    </div>
  );
};

export const SecondaryListItems = () => {
  return (
    <div>
      <ListItemButton component={Link} to={`/`}>
        <ListItemIcon>
          <MeetingRoomIcon />
        </ListItemIcon>
        <ListItemText primary='LOGOUT' />
      </ListItemButton>
    </div>
  );
};
