import CustomTable from 'components/CustomTable';
import { pick } from 'lodash';
import React from 'react';
import Loader from './Loader/Loader';
import { useGetDeletedUserListQuery, useUpdatePersistedUserMutation, PersistedUser } from '../graphql/generated';

const DeletedUserList = (): JSX.Element => {
  const { loading: queryLoading, error, data } = useGetDeletedUserListQuery({
    errorPolicy: 'all'
  });

  const [updatePersistedUser, { loading: mutationLoading }] = useUpdatePersistedUserMutation({
    errorPolicy: 'all'
  });

  let customerList: PersistedUser[] = data?.adminGetDeletedUserList || [];

  if (error) console.log('DeletedUserList ERROR', error.message);

  const handleRowUpdate = async (data: any) => {
    const userUpdates = pick(data, ['notes']);

    await updatePersistedUser({ variables: { userId: data._id, userUpdates } });
  };

  return (
    <>
      {(queryLoading || mutationLoading) && <Loader />}
      <CustomTable data={customerList} title='Deleted Users' onRowUpdate={handleRowUpdate} />
    </>
  );
};

export default DeletedUserList;
