import React, { forwardRef, useRef, useState, useMemo, useEffect } from 'react';
import { FormControlLabel } from '@mui/material';
import {
  Button,
  ButtonBase,
  ButtonGroup,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import useStore from '../store';
import { makeStyles } from '@mui/styles';

import Papa from 'papaparse';
import AddBox from '@mui/icons-material/AddBox';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import Computer from '@mui/icons-material/Computer';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Notes from '@mui/icons-material/Notes';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import ViewColumn from '@mui/icons-material/ViewColumn';
import Link from '@mui/icons-material/Link';
import ListIcon from '@mui/icons-material/List';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import AppConfig from 'config/appConfig';
import { usePageNo } from 'hooks/usePageNo';
import { userTypes } from 'lib/data';
import { displayDate, displayDateTime } from 'lib/formatting';
import { map, omit, startCase } from 'lodash';
import MaterialTable, { MTableToolbar } from 'material-table';
import moment from 'moment';
import omitDeep from 'omit-deep-lodash';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import countries from '../lib/country.json';
import ImageUploader from './ImageUploader';
import UserEmailDisplay from './UserEmailDisplay';
import { SocialDialog } from './SocialDialog';
import { User, SocialProfile } from '../graphql/generated';
import Settings from './Settings';

const pageNoRegex = /([&?]+pageNo=)[0-9]+/g;

const tableIcons = {
  Add: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ViewColumn {...props} ref={ref} />)
};

interface Props {
  showIdColumn?: boolean;
  data: object[];
  title?: string;
  onRowUpdate?: (data: any) => Promise<void>;
  onRowDelete?: (userId: string) => Promise<void>;
  onRowAdd?: (data: any) => Promise<void>;
  underToolBarComponent?: JSX.Element;
  csvDownloadName?: string;
  csvDownloadData?: Array<any>;
  totalPages?: number;
}

const pageSizeOptions = [20, 50, 100];

const CustomTable = (props: Props) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const dbQueryInputRef = useRef<HTMLInputElement>();
  const isUsersRoute = window.location.href.includes('/users');

  const { data, title, onRowUpdate, onRowDelete, showIdColumn, onRowAdd, underToolBarComponent, totalPages } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('query');
  const profileQuery = searchParams.get('profileQuery');

  useEffect(() => {
    if (data && typeof query === 'string' && dbQueryInputRef.current) {
      dbQueryInputRef.current.value = query;
    }

    // Added data to dependencies to force update when page is loaded
  }, [dbQueryInputRef, query, data]);

  useEffect(() => {
    if (data && typeof profileQuery === 'string' && dbQueryInputRef.current) {
      dbQueryInputRef.current.value = profileQuery;
    }

    // Added data to dependencies to force update when page is loaded
  }, [dbQueryInputRef, profileQuery, data]);

  const [pageNumber, setPageNumber] = usePageNo();
  const [pageSize, setPageSize] = useState(20);

  const initialColumns = data[0];

  const flattenedData = data.map((e) => omitDeep(e, '__typename'));

  const isKb = window.location.href.includes('/BRAND');
  const isPersistedUsersRoute = window.location.href.includes('/deleted');

  const columns = map(omit(initialColumns, ['__typename']), (_, key) => {
    const dateTimeCols = [
      'created_at',
      'createdAt',
      'lastLoggedIn',
      'profile_cached_at',
      'cache_completed_at',
      'signupDate',
      'deletedDate'
    ];
    const isNotEditableCols = [
      '_id',
      'adminVerificationToken',
      'profiles',
      'delegate',
      'accountProgressPercentage',
      'created_at',
      'activeSubscription',
      'lastLoggedIn',
      'reportsUsage',
      'mediaKitsUsage',
      'collaborationsUsage',
      'invoicesUsage',
      'profilesUsage',
      ...((isPersistedUsersRoute && ['signupDate', 'deletedDate', 'email', 'deletedBy']) || [])
    ];
    const dateOnlyCols = ['nextBillingDate', 'firstBillingDate', 'expiresAt'];
    const selectCols = ['type', 'country'];
    const booleanCols = ['isEmailVerified', 'isAddedToMonday', 'isMfaEnabled'];
    const notFilterableCols = ['adminVerificationToken', 'accountProgressPercentage', ...booleanCols];
    const kbHiddenCols = ['invoicesUsage', 'collaborationsUsage'];
    const hiddenCols = [
      ...((isKb && kbHiddenCols) || []),
      !showIdColumn && '_id',
      'adminVerificationToken',
      'cacheSeconds',
      'braintreeSubscription',
      'stripeCustomer',
      'longToken',
      'facebookUserId',
      'googleUserId',
      'appleUserId',
      'tiktokUnionId',
      'isFacebookVerified',
      'isTiktokVerified',
      'isAdmin'
    ];

    const imageCols = ['headerImage', 'headerImageMobile'];
    const usageCols = ['reportsUsage', 'mediaKitsUsage', 'collaborationsUsage', 'invoicesUsage', 'profilesUsage'];

    let title;
    switch (key) {
      case 'user':
        title = 'Email';
        break;
      case 'displayName':
        title = 'Name';
        break;
      case 'signupDate':
        title = 'Sign Up Date';
        break;
      case 'profiles':
        title = 'Username/s';
        break;
      case 'delegate':
        title = 'Delegate Email';
        break;
      case 'accountProgressPercentage':
        title = 'Progress';
        break;
      case 'created_at':
        title = 'Created';
        break;
      case 'influencerPlan':
        title = 'Code';
        break;
      case 'activeSubscription':
        title = 'Subscription';
        break;
      case 'lastLoggedIn':
        title = 'Last Login';
        break;
      case 'isMfaEnabled':
        title = 'Is MFA Enabled';
        break;
      case 'isAdmin':
        title = 'Admin';
        break;
      case 'isEmailVerified':
        title = 'Email Verified';
        break;
      case 'isFacebookVerified':
        title = 'Facebook Verified';
        break;
      case 'isAddedToMonday':
        title = 'Added to Monday';
        break;
      case 'reportsUsage':
        title = 'Reports';
        break;
      case 'mediaKitsUsage':
        title = 'Media Kits';
        break;
      case 'collaborationsUsage':
        title = 'Collaborations';
        break;
      case 'invoicesUsage':
        title = 'Invoices';
        break;
      case 'profilesUsage':
        title = 'Profiles';
        break;
      default:
        title = startCase(key);
        break;
    }

    return {
      cellStyle: { padding: '8px 16px' },
      title,
      field: key,
      editComponent: (nativeProps: any) => {
        let value = nativeProps.value || '';

        return <TextField value={value} onChange={(event) => nativeProps.onChange(event.target.value)} />;
      },
      editable: isNotEditableCols.includes(key) ? ('never' as 'never') : ('always' as 'always'),
      ...(imageCols.includes(key) && {
        editComponent: (nativeProps: any) => {
          return (
            <>
              <ImageUploader onUpload={(value) => nativeProps.onChange(value)} />
              <TextField
                style={{ marginLeft: 4 }}
                value={nativeProps.value || ''}
                onChange={(event) => nativeProps.onChange(event.target.value)}
              />
            </>
          );
        }
      }),
      ...(booleanCols.includes(key) && {
        editComponent: (nativeProps: any) => {
          return <Checkbox checked={nativeProps.value || false} onChange={(_, value) => nativeProps.onChange(value)} />;
        }
      }),
      ...([...dateTimeCols, ...dateOnlyCols].includes(key) && {
        editComponent: (nativeProps: any) => {
          return (
            <DesktopDatePicker
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => nativeProps.onChange(null)}>
                    <Clear />
                  </IconButton>
                )
              }}
              InputAdornmentProps={{
                position: 'start'
              }}
              renderInput={(params) => <TextField {...params} />}
              showToolbar={false}
              inputFormat='dd/MM/yyyy'
              value={nativeProps.value || null}
              onChange={(value) => nativeProps.onChange(value)}
            />
          );
        }
      }),
      ...(selectCols.includes(key) && {
        editComponent: (nativeProps: any) => {
          let selectOptions: any[] = [];

          switch (key) {
            case 'type':
              selectOptions = userTypes;
              break;
            case 'country':
              selectOptions = countries;
              break;
            default:
              break;
          }

          return (
            <Select value={nativeProps.value} onChange={(event) => nativeProps.onChange(event.target.value)}>
              {selectOptions.map((e) => (
                <MenuItem value={e.value}>{e.label}</MenuItem>
              ))}
            </Select>
          );
        }
      }),
      ...(notFilterableCols.includes(key) && { filtering: false }),
      ...(hiddenCols.includes(key) && { hidden: true }),
      ...(usageCols.includes(key) && {
        customSort: (a: any, b: any) => a[key].count - b[key].count
      }),
      ...(usageCols.includes(key) && {
        customFilterAndSearch: (query: string, rowData: { [key: string]: any }): boolean => {
          const value = `${rowData[key].count}/${rowData.mediaKitsUsage.limit}`;

          return value.toLowerCase().includes(query.toLowerCase());
        }
      }),
      ...(key === 'user' && {
        customFilterAndSearch: (query: string, rowData: { [key: string]: any }) => {
          const value = rowData[key]?.email;

          return value ? value.toLowerCase().includes(query.toLowerCase()) : false;
        }
      }),
      ...(key === 'activeSubscription' && {
        customFilterAndSearch: (query: string, rowData: { [key: string]: any }): boolean => {
          const value = rowData[key]?.id;

          return Boolean(value ? value === query : false);
        }
      }),
      ...(dateTimeCols.includes(key) && {
        customFilterAndSearch: (query: string, rowData: { [key: string]: any }) => {
          const value = displayDateTime(rowData[key]);

          return value.toLowerCase().includes(query.toLowerCase());
        }
      }),
      ...(dateOnlyCols.includes(key) && {
        customFilterAndSearch: (query: string, rowData: { [key: string]: any }) => {
          const value = displayDate(rowData[key]);

          return value.toLowerCase().includes(query.toLowerCase());
        }
      }),
      render: (rowData: { [key: string]: any }) => {
        const value = rowData[key];
        switch (key) {
          case 'email':
            return <UserEmailDisplay user={rowData as User} />;
          case 'activeSubscription':
            return <SubscriptionCell user={rowData as User} />;
          case 'accountProgressPercentage':
            return <ProgressCell user={rowData as User} />;
          case 'reportsUsage':
            return `${rowData.reportsUsage.count}/${rowData.reportsUsage.limit}`;
          case 'mediaKitsUsage':
            return `${rowData.mediaKitsUsage.count}/${rowData.mediaKitsUsage.limit}`;
          case 'collaborationsUsage':
            return `${rowData.collaborationsUsage.count}/${rowData.collaborationsUsage.limit}`;
          case 'invoicesUsage':
            return `${rowData.invoicesUsage.count}/${rowData.invoicesUsage.limit}`;
          case 'profilesUsage':
            return `${rowData.profilesUsage.count}/${rowData.profilesUsage.limit}`;
          default:
            break;
        }

        if (value === 0) return 0;

        if (!value) return null;

        switch (key) {
          case 'displayName':
            return <SocialAccountLink account={rowData as SocialProfile} />;
          case 'instagram':
            return <SocialAccountList data={rowData['instagram']} longToken={rowData['longToken']} />;

          case 'facebook':
            return <SocialAccountList data={rowData['facebook']} longToken={rowData['longToken']} />;
          case 'youtube':
            return <SocialAccountList data={rowData['youtube']} />;
          case 'profileCategory':
            return value.map((e: string) => e).join(', ');
          case 'user':
            return value.email;
          case 'country':
            return countries.find((e) => e.value === value)!.label;
          case 'isMfaEnabled':
          case 'isAdmin':
          case 'isEmailVerified':
          case 'isFacebookVerified':
          case 'isAddedToMonday':
            return <VerifiedUser />;
          case 'adminVerificationToken':
            return renderLoginCell(rowData);
          case 'influencerPlan':
            return value.code;
          case 'delegate':
            return value.master ? value.master.email : null;
          case 'profiles':
            return value.map((e: { displayName: string }) => e.displayName).join(', ');
          default:
            break;
        }

        if (dateTimeCols.includes(key)) return displayDateTime(value);

        if (dateOnlyCols.includes(key)) return displayDate(value);

        return value;
      }
    };
  });

  const { isInternalChecked, setIsInternalChecked } = useStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInternalChecked(event.target.checked);
  };

  return (
    <>
      <MaterialTable
        editable={{
          ...(onRowAdd && {
            onRowAdd: (newData) => onRowAdd(newData)
          }),
          ...(onRowUpdate && {
            onRowUpdate: (newData: any) => onRowUpdate(newData)
          }),
          ...(onRowDelete && {
            onRowDelete: (data: any) => onRowDelete(data._id)
          })
        }}
        actions={
          isUsersRoute
            ? [
                {
                  icon: () => <span style={{ fontSize: 20, fontWeight: 'bold' }}>Login</span>,
                  onClick: (_, user: any) => {
                    handleAdminLogin(
                      user.type === 'INFLUENCER' ? AppConfig.INFLUENCER_APP_URL : AppConfig.APP_URL,
                      user.adminVerificationToken
                    );
                  }
                },
                {
                  icon: () => <Computer />,
                  onClick: (_, user: any) => {
                    handleAdminLogin(
                      user.type === 'INFLUENCER' ? 'http://localhost:3011' : 'http://localhost:3000',
                      user.adminVerificationToken
                    );
                  }
                },
                {
                  icon: () => <Notes />,
                  tooltip: 'View in Mixpanel',
                  onClick: (_, user: any) => {
                    window.open(`${AppConfig.MIXPANEL_URL}/profile#distinct_id=${user._id}`, '_blank');
                  }
                }
              ]
            : []
        }
        components={{
          Toolbar: (nativeProps) => {
            return (
              <>
                <MTableToolbar
                  {...nativeProps}
                  title={
                    <Grid container justifyContent={'space-around'}>
                      <Grid item>
                        <Typography variant='h5' color='primary'>
                          {nativeProps.title}
                        </Typography>
                      </Grid>
                      {props.csvDownloadName && (
                        <Grid item>
                          <Button
                            disabled={!Boolean(props.csvDownloadData)}
                            onClick={() => {
                              const csvString = Papa.unparse(props.csvDownloadData!);
                              downloadCSV(csvString, props.csvDownloadName!);
                            }}
                          >
                            Download CSV
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  }
                />
                <Grid container justifyContent='flex-end' style={{ paddingRight: 16 }}>
                  {isUsersRoute && (
                    <Grid container item justifyContent='flex-end' alignItems='flex-end' wrap='nowrap'>
                      <Grid item>
                        <FormControlLabel
                          control={<Checkbox checked={isInternalChecked} onChange={handleChange} />}
                          label='Exclude Internal User Accounts'
                        />

                        <TextField
                          label='Search entire database'
                          inputProps={{ ref: dbQueryInputRef }}
                          style={{ width: 200 }}
                        />
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent='flex-end' alignItems='flex-end'>
                          <Button
                            onClick={() => {
                              if (dbQueryInputRef.current?.value) {
                                setSearchParams({ query: dbQueryInputRef.current.value });
                              }
                            }}
                          >
                            Search
                          </Button>
                          <Button
                            onClick={() => {
                              if (dbQueryInputRef.current?.value) {
                                setSearchParams({ profileQuery: dbQueryInputRef.current?.value });
                              }
                            }}
                          >
                            Search Profiles
                          </Button>
                          <Button
                            disabled={!Boolean(query || profileQuery)}
                            onClick={() => {
                              setSearchParams({ search: `` });
                            }}
                          >
                            Clear
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {underToolBarComponent}
                </Grid>
              </>
            );
          },
          Pagination: (nativeProps) => {
            const { onChangePage, onChangeRowsPerPage } = nativeProps;

            const numberOfPages = totalPages || Math.ceil(data.length / pageSize);

            const changePageNumber = (i: number) => {
              setPageNumber(i);
              onChangePage(null, i);

              const currentParams = `${location.search}`;
              let paramsToUse;

              const matches = pageNoRegex.exec(currentParams);

              if (matches && matches.length > 1) {
                if (i === 0) {
                  paramsToUse = currentParams.replace(pageNoRegex, '');
                } else {
                  paramsToUse = currentParams.replace(pageNoRegex, `${matches[1]}${i + 1}`);
                }
              } else {
                paramsToUse = `${currentParams}${currentParams.length ? '&' : '?'}pageNo=${i + 1}`;
              }

              navigate(`${location.pathname}${paramsToUse}`);
            };

            const CustomTableNav = () => {
              const pageLinkLimit = 10;

              const createLinks = (): JSX.Element[] => {
                let startPage = 0;
                const pagesMidpoint = Math.ceil(pageLinkLimit / 2);
                if (numberOfPages > pageLinkLimit) {
                  // pages > 10
                  if (pageNumber > pagesMidpoint) {
                    // current page > 5
                    if (pageNumber > numberOfPages - pagesMidpoint) {
                      // current page > pages - 5
                      startPage = numberOfPages - pageLinkLimit;
                    } else {
                      startPage = pageNumber - pagesMidpoint;
                    }
                  }
                }

                let buttonList = [];
                for (let i = startPage; i < Math.min(numberOfPages, startPage + pageLinkLimit); i++) {
                  buttonList.push(
                    <Button
                      key={i}
                      disabled={pageNumber === 0 + i}
                      onClick={() => {
                        changePageNumber(i);
                      }}
                    >
                      {i + 1}
                    </Button>
                  );
                }

                return buttonList;
              };

              const createPageSizeLinks = (): JSX.Element[] => {
                return pageSizeOptions.map((option, index) => {
                  return (
                    <Grid item key={index}>
                      <Button
                        color='primary'
                        disabled={pageSize === option}
                        onClick={() => {
                          setPageSize(option);
                          setPageNumber(0);
                          onChangeRowsPerPage({ target: { value: option } });
                        }}
                      >
                        {option}
                      </Button>
                    </Grid>
                  );
                });
              };

              const renderSetPageSize = () => {
                if (isUsersRoute) {
                  return;
                }

                return (
                  <Grid container justifyContent='center' alignItems='center' direction='row' wrap='nowrap' spacing={3}>
                    <Grid item>Set page size: </Grid>
                    {createPageSizeLinks()}
                  </Grid>
                );
              };

              return (
                <TableCell
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Grid container justifyContent='center' alignItems='center' direction='row' wrap='nowrap' spacing={3}>
                    <Grid item>
                      <Button
                        color='primary'
                        disabled={pageNumber === 0}
                        onClick={() => {
                          changePageNumber(pageNumber - 1);
                        }}
                      >
                        Previous
                      </Button>
                    </Grid>
                    {!xsDown && (
                      <Grid item>
                        <ButtonGroup color='primary' size='small'>
                          {createLinks()}
                        </ButtonGroup>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        color='primary'
                        disabled={!Boolean(pageNumber < numberOfPages - 1)}
                        onClick={() => {
                          changePageNumber(pageNumber + 1);
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                    {renderSetPageSize()}
                  </Grid>
                </TableCell>
              );
            };

            return <CustomTableNav />;
          }
        }}
        icons={tableIcons}
        options={{
          addRowPosition: 'first',
          initialPage: pageNumber,
          pageSize: 20,
          pageSizeOptions,
          search: false,
          filtering: true,
          headerStyle: {
            fontSize: 16,
            fontWeight: 'bold'
          }
        }}
        columns={columns}
        data={flattenedData}
        title={title ? `${title} (${data.length})` : ''}
      />
    </>
  );
};

export default CustomTable;

const linearProgressStyles = makeStyles(() => ({
  root: {
    height: 20
  },
  colorPrimary: {
    backgroundColor: 'RGBA(28, 136, 147, 0.5)'
  },
  barColorPrimary: {
    backgroundColor: 'RGBA(28, 136, 147)'
  }
}));

const SocialAccountList = ({ data, longToken }: { data: any[]; longToken?: string }) => {
  const isError = useMemo(() => {
    if (!data?.length) {
      return false;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].cache_error) {
        return true;
      }
      break;
    }
    return false;
  }, [data]);

  return (
    <>
      {data?.map((account: SocialProfile) => (
        <SocialAccountLink key={account._id} account={account} />
      ))}
      {longToken && (
        <ButtonBase
          style={{ margin: 4 }}
          target='_blank'
          href={`https://developers.facebook.com/tools/debug/accesstoken/?access_token=${longToken}`}
        >
          <ListIcon style={{ fontSize: 16, color: isError ? '#ff0000' : '#1c8893' }} />
        </ButtonBase>
      )}
    </>
  );
};

const SocialAccountLink = ({ account }: { account: SocialProfile }) => {
  const [dialogContent, setDialogContent] = useState<string | null>(null);

  return (
    <Tooltip title={account.cache_error || 'Profile is HEALTHY'}>
      <div>
        {dialogContent && <SocialDialog dialogContent={dialogContent} onSetDialogContent={setDialogContent} />}
        <ButtonBase style={{ margin: 4 }} target='_blank' href={`${AppConfig.MEDIA_KIT_URL}/#/profile/${account._id}`}>
          <span style={{ fontSize: 16, color: '#1c8893' }}>{account.displayName}</span>
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            setDialogContent(`${AppConfig.MEDIA_KIT_URL}/#/profile/${account._id}`);
          }}
        >
          <Link style={{ fontSize: 16, color: '#1c8893' }} />
        </ButtonBase>
      </div>
    </Tooltip>
  );
};

const ProgressCell = ({ user }: { user?: User }) => {
  const classes = linearProgressStyles();
  if (!user?.accountProgressPercentage) {
    return null;
  }
  return (
    <Grid container direction='column' style={{ position: 'relative' }}>
      <LinearProgress variant='determinate' value={user.accountProgressPercentage!} classes={classes} />
      <span style={{ position: 'absolute', left: 8, color: '#fff' }}>{`${user.accountProgressPercentage}%`}</span>
    </Grid>
  );
};

const SubscriptionCell = ({ user }: { user?: User }) => {
  if (!user) {
    return null;
  }
  return (
    <Grid>
      <div style={{ fontWeight: 700 }}>
        {user.stripeCustomer?.firstSubscription?.firstItem?.price?.nickname ||
          user.braintreeSubscription?.planId ||
          user.activeSubscription}
      </div>
      {user.stripeCustomer?.firstSubscription?.firstItem?.price?.unit_amount_decimal && (
        <Tooltip
          title={`Next billing date: ${moment(user.stripeCustomer.firstSubscription?.current_period_end).format('ll')}`}
        >
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{`$${(
              parseFloat(user.stripeCustomer?.firstSubscription?.firstItem?.price?.unit_amount_decimal) / 100
            ).toFixed(2)} (${user.stripeCustomer.firstSubscription.id})`}</span>
            <b>{user.stripeCustomer.firstSubscription.status}</b>
          </span>
        </Tooltip>
      )}
      {user.braintreeSubscription?.price && (
        <Tooltip title={`Next billing date: ${moment(user.braintreeSubscription.nextBillingDate).format('ll')}`}>
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{`$${parseFloat(user.braintreeSubscription?.price)} (${user.braintreeSubscription.id})`}</span>
            <b>{user.braintreeSubscription.status}</b>
          </span>
        </Tooltip>
      )}
    </Grid>
  );
};

const handleAdminLogin = (appUrl: string, token: string) => {
  const referralString = encodeURIComponent(
    btoa(
      JSON.stringify({
        bearer: sessionStorage.getItem('auth-token'),
        token
      })
    )
  );

  window.open(`${appUrl}/#/login-admin/${referralString}`, '_blank');
};

const renderLoginCell = (user: any) => {
  const handleAdminLogin = (appUrl: string, token: string) => {
    const referralString = encodeURIComponent(
      btoa(
        JSON.stringify({
          bearer: sessionStorage.getItem('auth-token'),
          token
        })
      )
    );

    window.open(`${appUrl}/#/login-admin/${referralString}`, '_blank');
  };

  return (
    <Grid container justifyContent='center' direction='row' wrap='nowrap' spacing={1}>
      <Grid item>
        <ButtonBase
          onClick={() => {
            handleAdminLogin(
              user.type === 'INFLUENCER' ? AppConfig.INFLUENCER_APP_URL : AppConfig.APP_URL,
              user.adminVerificationToken
            );
          }}
        >
          <span style={{ fontSize: 20, fontWeight: 'bold', color: '#1c8893' }}>Login</span>
        </ButtonBase>
      </Grid>
      <Grid item>
        <ButtonBase
          onClick={() => {
            handleAdminLogin(
              user.type === 'INFLUENCER' ? 'http://localhost:3011' : 'http://localhost:3000',
              user.adminVerificationToken
            );
          }}
        >
          <Computer />
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

const downloadCSV = (csvString: string, fileName: string) => {
  var blob = new Blob([csvString]);
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
  document.body.removeChild(a);
};
