import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper
} from '@mui/material';
import Papa from 'papaparse';
import Title from './Title';
import ColumnHeadWithSort from './ColumnHeadWithSort';
import PageNav from './PageNav';
import { SortField } from '../lib/types';
import Loader from './Loader/Loader';
import moment from 'moment';
import { omit, orderBy, isEmpty } from 'lodash';
import { usePageNo } from 'hooks/usePageNo';

import { useGetTransactionListQuery, BraintreeTransaction } from '../graphql/generated';

interface TransactionListProps {
  classes?: any;
  transactionTotals: any;
  setTransactionTotals: (totals: any) => void;
}

interface TransactionRowProps {
  classes?: any;
  transaction: BraintreeTransaction;
}

const TransactionList = (props: TransactionListProps): JSX.Element => {
  const { classes } = props;

  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageNumber, setPageNumber] = usePageNo();
  const [pageSize, setPageSize] = useState(20);

  const [sortField, setSortField] = useState<SortField>({
    field: '',
    sortAscending: false
  });

  const handleClose = () => {
    setErrorAlertOpen(false);
    setErrorMessage('');
  };

  const { loading, error, data } = useGetTransactionListQuery({
    errorPolicy: 'all'
  });

  let sortedTransactionList: BraintreeTransaction[] = [];
  const amountFields = ['amount', 'discountAmount'];
  const condition = amountFields.includes(sortField.field)
    ? (amt: any) => {
        return parseInt(amt[sortField.field]);
      }
    : sortField.field;

  if (data?.adminGetTransactionList) {
    const order = sortField.sortAscending ? 'asc' : 'desc';
    sortedTransactionList = sortField.field
      ? orderBy(data.adminGetTransactionList, [condition], [order])
      : data.adminGetTransactionList;
  }

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (error) {
    console.log('TransactionList ERROR', error.message);
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* Error Alert Modal */}
        <Dialog
          open={errorAlertOpen}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{'Your Error, Sir/Madame'}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary' autoFocus>
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper}>
          <Grid container justifyContent={'space-around'}>
            <Grid item>
              <Title>
                Transactions
                {sortedTransactionList.length && <span>{` (${sortedTransactionList.length})`}</span>}
              </Title>
            </Grid>

            <Grid item>
              <Button
                disabled={isEmpty(sortedTransactionList)}
                onClick={() => {
                  const recordsForExport = sortedTransactionList.map((o) => ({
                    ...omit(o, ['subscription']),
                    subscription: o.subscription?.id,
                    firstName: o.subscription?.user?.firstName,
                    lastName: o.subscription?.user?.lastName,
                    email: o.subscription?.user?.email,
                    country: o.subscription?.user?.country,
                    createdAt: moment(o.createdAt).format(),
                    updatedAt: moment(o.updatedAt).format()
                  }));
                  const csvString = Papa.unparse(recordsForExport);
                  downloadCSV(csvString);
                }}
              >
                Download CSV
              </Button>
            </Grid>
          </Grid>

          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    ID
                  </p>
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='createdAt'
                    label='Date'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort field='amount' label='Amount' sortField={sortField} setSortField={setSortField} />
                </TableCell>

                <TableCell>
                  <ColumnHeadWithSort
                    field='subscription.id'
                    label='Subscription ID'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='subscription.user.firstName'
                    label='Name'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='subscription.user.email'
                    label='Email'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='subscription.user.country'
                    label='Country'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort field='type' label='Type' sortField={sortField} setSortField={setSortField} />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='planId'
                    label='Plan Id'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort field='status' label='Status' sortField={sortField} setSortField={setSortField} />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='discountAmount'
                    label='Discount Amount'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    Refunded Transaction Id
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(sortedTransactionList) &&
                sortedTransactionList
                  .slice(0 + pageNumber * pageSize, pageSize + pageNumber * pageSize)
                  .map((transaction, i) => {
                    return <TransactionRow key={i} transaction={transaction} classes={classes} />;
                  })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {sortedTransactionList.length && (
            <PageNav
              classes={classes}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              listLength={sortedTransactionList.length}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

const TransactionRow = (props: TransactionRowProps) => {
  const { transaction } = props;
  return (
    <TableRow>
      <TableCell>{transaction.id}</TableCell>
      <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(transaction.createdAt).format('YYYY-MM-DD')}</TableCell>
      <TableCell>{transaction.amount}</TableCell>

      <TableCell>{transaction.subscription?.id}</TableCell>
      <TableCell>
        {transaction.subscription?.user
          ? `${transaction.subscription?.user?.firstName} ${transaction.subscription?.user?.lastName}`
          : ''}
      </TableCell>
      <TableCell>{transaction.subscription?.user?.email || ''}</TableCell>
      <TableCell>{transaction.subscription?.user?.country || ''}</TableCell>
      <TableCell>{transaction.type}</TableCell>
      <TableCell>{transaction.planId}</TableCell>
      <TableCell>{transaction.status}</TableCell>
      <TableCell>{transaction.discountAmount}</TableCell>
      <TableCell>{transaction.refundedTransactionId || ''}</TableCell>
    </TableRow>
  );
};

const downloadCSV = (csvString: string) => {
  var blob = new Blob([csvString]);
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = 'transactions.csv';
  document.body.appendChild(a);
  a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
  document.body.removeChild(a);
};

export default TransactionList;
