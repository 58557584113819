import { useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Typography, Container, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import AppConfig from '../config/appConfig';

const apiInstance = axios.create({ baseURL: `${AppConfig.API_URL}` });

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const navigate = useNavigate();

  sessionStorage.clear();

  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            value={emailInput}
            onChange={(e) => {
              setEmailInput(e.target.value);
            }}
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={passwordInput}
            onChange={(e) => {
              setPasswordInput(e.target.value);
            }}
          />
          {Boolean(loginError) && <p>{loginError}</p>}
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            onClick={(e) => {
              e.preventDefault();
              setIsLoading(true);
              apiInstance
                .post('/admin/login', {
                  email: emailInput.toLowerCase(),
                  password: passwordInput
                })
                .then((response) => {
                  setIsLoading(false);
                  if (response && response.data && response.data.data && response.data.data.token) {
                    sessionStorage.setItem('auth-token', response.data.data.token);
                    navigate('/dashboard');
                  } else {
                    const message =
                      response && response.data && response.data.message
                        ? response.data.message
                        : 'Credentials were invalid';
                    setLoginError(message);
                  }
                })
                .catch((ex) => {
                  setIsLoading(false);
                  setLoginError(ex.message);
                });
            }}
          >
            {isLoading ? <CircularProgress /> : 'Sign In'}
          </Button>
        </form>
      </div>
    </Container>
  );
}
