import React from 'react';
import CustomTable from './CustomTable';
import Loader from './Loader/Loader';
import omit from 'lodash/omit';
import moment from 'moment';
import { useGetSubscriptionlistQuery, BraintreeSubscription } from '../graphql/generated';

const SubscriptionList = (): JSX.Element => {
  const { loading, error, data } = useGetSubscriptionlistQuery({
    errorPolicy: 'all'
  });

  const subscriptionList: BraintreeSubscription[] = data?.adminGetSubscriptionList || [];

  if (error) console.log('SubscriptionList ERROR', error.message);

  return (
    <>
      {loading && <Loader />}
      <CustomTable
        data={subscriptionList}
        title='Subscriptions'
        csvDownloadName={'subscriptions'}
        csvDownloadData={subscriptionList.map((o) => ({
          ...omit(o, ['user']),
          firstName: o.user?.firstName,
          lastName: o.user?.lastName,
          email: o.user?.email,
          country: o.user?.country,
          createdAt: moment(o.createdAt).format()
        }))}
      />
    </>
  );
};

export default SubscriptionList;
