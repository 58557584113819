import { useEffect } from 'react';
import config from '../config/appConfig';

const TestDiscoveryWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${config.API_URL}static/whitelabel_widget/discovery_dynamic.js`;
    script.async = true;
    script.onload = () => {
      // @ts-ignore
      window.kitlyDiscoverySearchInit(
        '98127g3612763f2', // Your API Key
        'kitly-discovery-search', // id of the parent continer for the form
        'chrisq83prodtestco',
        'chris',
        'taylor',
        'chrisq83prodtestcoemailaccountowner@q-83.com'
      );
    };

    document.body.appendChild(script);

    const head = document.head;
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `${config.API_URL}static/whitelabel_widget/discovery.css`;

    head.appendChild(link);

    return () => {
      head.removeChild(link);
      document.removeChild(script);
    };
  }, []);

  return <div id='kitly-discovery-search'></div>;
};

export default TestDiscoveryWidget;
