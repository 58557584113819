import { Button, ButtonGroup, Grid, useMediaQuery, useTheme } from '@mui/material';
import { parse } from 'query-string';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface PageNavProps {
  classes?: any;
  setPageNumber: (pgNumber: number) => void;
  pageNumber: number;
  listLength: number;
  pageSize: number;
  setPageSize: (pgSize: number) => void;
}

const pageLinkLimit = 10;

const PageNav = (props: PageNavProps) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { classes, pageNumber: defaultPageNumber, setPageNumber, listLength, pageSize, setPageSize } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const queryStr = parse(location.search);
  const pageNumber = queryStr.page_no ? parseInt(queryStr.page_no as string) - 1 : defaultPageNumber;

  const createLinks = (listSize: number, pageSize: number) => {
    let numberOfPages = Math.ceil(listSize / pageSize);

    let startPage = 0;
    const pagesMidpoint = Math.ceil(pageLinkLimit / 2);
    if (numberOfPages > pageLinkLimit) {
      // pages > 10
      if (pageNumber > pagesMidpoint) {
        // current page > 5
        if (pageNumber > numberOfPages - pagesMidpoint) {
          // current page > pages - 5
          startPage = numberOfPages - pageLinkLimit;
        } else {
          startPage = pageNumber - pagesMidpoint;
        }
      }
    }

    let buttonList = [];
    for (let i = startPage; i < Math.min(numberOfPages, startPage + pageLinkLimit); i++) {
      buttonList.push(
        <Button
          key={i}
          disabled={pageNumber === 0 + i}
          onClick={() => {
            setPageNumber(i);
            navigate(`${location.pathname}?page_no=${i + 1}`);
          }}
        >
          {i + 1}
        </Button>
      );
    }

    return buttonList;
  };

  return (
    <div className={classes.seeMore}>
      <Grid container justifyContent='center' alignItems='center' direction='row' wrap='nowrap' spacing={3}>
        <Grid item>
          <Button
            color='primary'
            disabled={pageNumber === 0}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          >
            Previous
          </Button>
        </Grid>
        {!xsDown && (
          <Grid item>
            <ButtonGroup color='primary' size='small'>
              {createLinks(listLength, pageSize)}
            </ButtonGroup>
          </Grid>
        )}
        <Grid item>
          <Button
            color='primary'
            disabled={listLength - pageNumber * pageSize < pageSize}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' alignItems='center' direction='row' wrap='nowrap' spacing={3}>
        <Grid item>Set page size: </Grid>
        <Grid item>
          <Button
            color='primary'
            disabled={pageSize === 20}
            onClick={() => {
              setPageSize(20);
              setPageNumber(0);
            }}
          >
            20
          </Button>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            disabled={pageSize === 50}
            onClick={() => {
              setPageSize(50);
              setPageNumber(0);
            }}
          >
            50
          </Button>
        </Grid>
        <Grid item>
          <Button
            color='primary'
            disabled={pageSize === 100}
            onClick={() => {
              setPageSize(100);
              setPageNumber(0);
            }}
          >
            100
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageNav;
