import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePageNo = (): [number, (value: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNo = searchParams.get('pageNo');

  const setPageNo = useCallback(
    (value: number) => {
      setSearchParams({ pageNo: String(value) });
    },
    [setSearchParams]
  );

  return [pageNo ? parseInt(pageNo) : 0, setPageNo];
};
