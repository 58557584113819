import { ApolloError } from '@apollo/client';
import { pick } from 'lodash';
import React, { useState, useEffect } from 'react';
import CustomTable from './CustomTable';
import Loader from './Loader/Loader';
import {
  useGetCouponListQuery,
  CouponCode,
  useUpdateCouponMutation,
  useCreateCouponMutation,
  useDeleteCouponMutation,
  GetCouponListDocument
} from '../graphql/generated';

const CouponList = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      console.log('CouponList errorMessage', errorMessage);
    }
  }, [errorMessage]);

  const handleClose = () => {
    setErrorMessage('');
  };

  const handleError = (errMsg: ApolloError) => {
    setErrorMessage(`Your Error: ${errMsg}`);
  };

  const { loading: queryLoading, error, data } = useGetCouponListQuery({
    errorPolicy: 'all'
  });

  const [updateCoupon, { loading: updateMutationLoading }] = useUpdateCouponMutation({
    onError: (err) => {
      handleError(err);
    }
  });

  const [createCoupon, { loading: createMutationLoading }] = useCreateCouponMutation({
    onError: (err) => {
      handleError(err);
    }
  });

  const [deleteCoupon, { loading: deleteMutationLoading }] = useDeleteCouponMutation({
    onError: (err) => {
      handleError(err);
    }
  });

  const couponList: CouponCode[] = data?.adminGetCouponList || [];

  if (error) console.log('CouponList ERROR', error.message);

  const couponFields = [
    'code',
    'firstName',
    'lastName',
    'planId',
    'discountId',
    'headerImage',
    'headerImageMobile',
    'notes'
  ];

  const handleRowAdd = async (data: CouponCode) => {
    const updates = pick(data, couponFields);

    await createCoupon({
      variables: { updates },
      update: (cache, res) => {
        const newCouponCode = res.data?.adminCreateCoupon;
        const data: any = cache.readQuery({ query: GetCouponListDocument });
        data.adminGetCouponList = [newCouponCode, ...data.adminGetCouponList];

        cache.writeQuery({ query: GetCouponListDocument, data });
      }
    });
  };

  const handleRowUpdate = async (data: CouponCode) => {
    const updates = pick(data, couponFields);

    await updateCoupon({ variables: { couponCodeId: data._id, updates } });
  };

  const handleRowDelete = async (couponCodeId: string) => {
    await deleteCoupon({
      variables: { couponCodeId },
      update: (cache) => {
        const data: any = cache.readQuery({ query: GetCouponListDocument });
        data.adminGetCouponList = data.adminGetCouponList.filter((e: { _id: String }) => e._id !== couponCodeId);

        cache.writeQuery({ query: GetCouponListDocument, data });
      }
    });
    handleClose();
  };

  return (
    <>
      {(queryLoading || createMutationLoading || updateMutationLoading || deleteMutationLoading) && <Loader />}
      <CustomTable
        data={couponList}
        title='Coupons'
        onRowDelete={handleRowDelete}
        onRowUpdate={handleRowUpdate}
        onRowAdd={handleRowAdd}
      />
    </>
  );
};

export default CouponList;
