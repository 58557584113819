import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import React, { useMemo } from 'react';
import CustomTable from './CustomTable';
import Loader from './Loader/Loader';
import { useGetProfileListQuery } from '../graphql/generated';

const ProfileList = (): JSX.Element => {
  const theme = useTheme();

  const { loading, error, data } = useGetProfileListQuery({
    errorPolicy: 'all'
  });

  const profileList = useMemo(() => data?.adminGetProfileList || [], [data]);

  const averageCacheSeconds = useMemo(() => {
    let cacheSecondsArray = [];
    let cacheSecondsSum = 0;

    profileList.forEach((o: any) => {
      if (o && o.cacheSeconds && o.cacheSeconds > 0) {
        cacheSecondsArray.push(o.cacheSeconds);
        cacheSecondsSum += o.cacheSeconds;
      }
    });

    return Boolean(cacheSecondsArray.length > 0 && cacheSecondsSum > 0)
      ? Math.round(cacheSecondsSum / cacheSecondsArray.length)
      : 0;
  }, [profileList]);

  const cacheRangeMinutes = useMemo(() => {
    let newest: any;
    let oldest: any;

    profileList.forEach((o: any) => {
      if (o && o.cache_completed_at && !o.cacheError) {
        let completedDate = moment(o.cache_completed_at);
        if (!newest || completedDate.isAfter(newest)) newest = completedDate;
        if (!oldest || completedDate.isBefore(oldest)) oldest = completedDate;
      }
    });

    return newest && oldest ? moment.duration(newest.diff(oldest)).minutes() : 0;
  }, [profileList]);

  if (error) console.log('ProfileList ERROR', error.message);

  const renderCacheData = (): JSX.Element => {
    return (
      <Grid item container style={{ color: theme.palette.primary.main, width: 'auto', marginTop: 16 }}>
        <Typography variant='body2' style={{ marginRight: 16 }}>{`Avg cache: ${averageCacheSeconds}s`}</Typography>
        <Typography variant='body2'>{`Range: ${cacheRangeMinutes}m`}</Typography>
      </Grid>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <CustomTable data={profileList} title='Profiles' underToolBarComponent={renderCacheData()} />
    </>
  );
};

export default ProfileList;
