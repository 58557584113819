import { ApolloError } from '@apollo/client';
import { pick, compact, pickBy, identity } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CustomTable from './CustomTable';
import Loader from './Loader/Loader';
import moment from 'moment';
import qs from 'query-string';
import useStore from '../store';

import {
  useGetUserListQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  User,
  GetUserListDocument,
  SocialProfile,
  UserUpdates
} from '../graphql/generated';

const UserList = (): JSX.Element => {
  const { isInternalChecked, setIsInternalChecked } = useStore();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const { query, pageNo, profileQuery } = qs.parse(location.search);
  const { type } = useParams<{ type?: string }>();

  useEffect(() => {
    if (errorMessage) {
      console.log('UserList errorMessage', errorMessage);
      alert(errorMessage);
    }
  }, [errorMessage]);

  const handleClose = () => {
    setErrorMessage('');
  };

  const handleError = (errMsg: ApolloError) => {
    setErrorMessage(`Your Error: ${errMsg}`);
  };

  let userListVariables: any = {};

  if (query) {
    userListVariables.query = query;
  }

  if (profileQuery) {
    userListVariables.profileQuery = profileQuery;
  }

  if (pageNo) {
    userListVariables.pageNo = parseInt(pageNo as string);
  }

  if (isInternalChecked) {
    userListVariables.hasFilterOutInternal = true;
  }

  if (type) {
    const filterTypes: { [key: string]: string } = { demo: 'isDemoAccount' };
    if (filterTypes[type]) {
      userListVariables.filter = filterTypes[type];
    } else {
      userListVariables.type = type;
    }
  }

  const { loading: queryLoading, error, data } = useGetUserListQuery({
    errorPolicy: 'all',
    variables: userListVariables
  });

  const [updateUser, { loading: updateMutationLoading }] = useUpdateUserMutation({
    onError: (err) => {
      handleError(err);
    }
  });

  const [deleteUser, { loading: deleteMutationLoading }] = useDeleteUserMutation({
    onError: (err) => {
      handleError(err);
    }
  });

  const userList: User[] = data?.adminGetUserList?.list || [];
  const totalPages = data?.adminGetUserList?.totalPages;

  if (error) console.log('UserList ERROR', error.message);

  const handleRowDelete = async (userId: string) => {
    await deleteUser({
      variables: { userId },
      update: (cache) => {
        const data: any = cache.readQuery({ query: GetUserListDocument, variables: userListVariables });
        data.adminGetUserList.list = data.adminGetUserList.list.filter((e: { _id: String }) => e._id !== userId);

        cache.writeQuery({ query: GetUserListDocument, data });
      }
    });
    handleClose();
  };

  const handleRowUpdate = async (data: User) => {
    const updates: UserUpdates = {
      ...pick(pickBy(data, identity), [
        'firstName',
        'lastName',
        'email',
        'type',
        'country',
        'referrer',
        'isAdmin',
        'isEmailVerified',
        'isFacebookVerified',
        'isMfaEnabled',
        'isAddedToMonday'
      ]),
      influencerPlan: (data.influencerPlan as unknown) as string
    };

    await updateUser({ variables: { userId: data._id, updates } });
  };

  return (
    <>
      {(queryLoading || updateMutationLoading || deleteMutationLoading) && <Loader />}
      <CustomTable
        totalPages={totalPages || 0}
        title={'Users'}
        data={userList}
        onRowDelete={handleRowDelete}
        onRowUpdate={handleRowUpdate}
        csvDownloadName={'users'}
        csvDownloadData={userList.map((o) => ({
          firstName: o.firstName,
          lastName: o.lastName,
          email: o.email,
          country: o.country,
          lastLoggedIn: moment(o.lastLoggedIn).format(),
          createdAt: moment(o.created_at).format(),
          subscriptionPrice:
            o.stripeCustomer?.firstSubscription?.firstItem?.price?.unit_amount_decimal ||
            o.braintreeSubscription?.price,
          subscriptionPlan:
            o.stripeCustomer?.firstSubscription?.firstItem?.price?.nickname || o.braintreeSubscription?.planId,
          subscriptionStatus: o.stripeCustomer?.firstSubscription?.status || o.braintreeSubscription?.status,
          instagramAccounts: o?.instagram ? listDisplayNames(o?.instagram!) : undefined,
          youtubeAccounts: o?.youtube ? listDisplayNames(o?.youtube!) : undefined,
          facebookAccounts: o?.facebook ? listDisplayNames(o?.facebook!) : undefined
        }))}
      />
    </>
  );
};

const listDisplayNames = (profiles?: Array<SocialProfile | null>) => {
  return profiles
    ? compact(profiles)
        .map((o) => o.displayName)
        .join(', ')
    : undefined;
};

export default UserList;
