import { create } from 'zustand';

interface StoreState {
  isInternalChecked: boolean;
}

interface StoreActions {
  setIsInternalChecked: (isChecked: boolean) => void;
}

type StoreType = StoreState & StoreActions;

const useStore = create<StoreType>((set) => ({
  isInternalChecked: (() => {
    const value = localStorage.getItem('filterInternal');
    return value !== 'false';
  })(),

  setIsInternalChecked: (isChecked: boolean) => {
    localStorage.setItem('filterInternal', String(isChecked));
    set({ isInternalChecked: isChecked });
  }
}));

export default useStore;
