import React from 'react';
import { ButtonBase } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface ColumnHeadWithSortProps {
  label: string;
  field: string;
  sortField: {
    field: string;
    sortAscending: boolean;
  };
  setSortField: (props: any) => void;
  setPageNumber?: (pageNumber: number) => void;
}

const ColumnHeadWithSort = (props: ColumnHeadWithSortProps) => {
  const { label, field, sortField, setSortField, setPageNumber } = props;

  return (
    <ButtonBase
      className='column-head'
      onClick={() => {
        setSortField({
          field,
          sortAscending: !sortField.sortAscending
        });
        if (setPageNumber) {
          setPageNumber(0);
        }
      }}
    >
      <p
        style={{
          fontSize: 16,
          fontWeight: 'bold'
        }}
      >
        {label}
      </p>
      {sortField.field !== field ? null : sortField.sortAscending ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
    </ButtonBase>
  );
};

export default ColumnHeadWithSort;
