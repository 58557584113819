import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import useStore from '../store';
import TestButtons from './TestButtons';
import TestDiscoveryWidget from './TestDiscoveryWidget';

const Settings = (): JSX.Element => {
  const { isInternalChecked, setIsInternalChecked } = useStore();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInternalChecked(event.target.checked);
  };

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={isInternalChecked} onChange={handleChange} />}
        label='Exclude Internal User Accounts'
      />

      <TestDiscoveryWidget />
      <TestButtons />
    </>
  );
};

export default Settings;
