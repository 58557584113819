/* eslint-disable no-script-url */

import React, { useState } from 'react';

// import { createStyles } from '@mui/material/styles';
// import { Theme } from '@mui/material/styles/createMuiTheme';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Title from './Title';

import ColumnHeadWithSort from './ColumnHeadWithSort';
import Loader from './Loader/Loader';
import PageNav from './PageNav';
import { SortField } from '../lib/types';

import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import { usePageNo } from 'hooks/usePageNo';
import { useGetCustomerListQuery, BraintreeCustomer } from '../graphql/generated';

interface CustomerListProps {
  classes?: any;
}

interface CustomerRowProps {
  classes?: any;
  customer: BraintreeCustomer;
}

// const styles = ({ spacing }: Theme) => createStyles({
//   seeMore: {
//     marginTop: spacing(3),
//   },
// });

const CustomerList = (props: CustomerListProps): JSX.Element => {
  const { classes } = props;

  const { loading, error, data } = useGetCustomerListQuery({
    errorPolicy: 'all'
  });

  const [sortField, setSortField] = useState<SortField>({
    field: '',
    sortAscending: false
  });
  const [pageNumber, setPageNumber] = usePageNo();
  const [pageSize, setPageSize] = useState(20);

  let sortedCustomerList: BraintreeCustomer[] = [];

  if (data && data.adminGetCustomerList) {
    const order = sortField.sortAscending ? 'asc' : 'desc';
    sortedCustomerList = orderBy(data.adminGetCustomerList, [sortField.field], [order]);
  }

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (error) {
    console.log('CustomerList ERROR', error.message);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>
            Customers
            {data?.adminGetCustomerList?.length && <span>{` (${data.adminGetCustomerList.length})`}</span>}
          </Title>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold'
                    }}
                  >
                    ID
                  </p>
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='firstName'
                    label='First Name'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
                <TableCell>
                  <ColumnHeadWithSort
                    field='lastName'
                    label='Last Name'
                    sortField={sortField}
                    setSortField={setSortField}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(!isEmpty(sortedCustomerList)) &&
                sortedCustomerList
                  .slice(0 + pageNumber * pageSize, pageSize + pageNumber * pageSize)
                  .map((customer: BraintreeCustomer, i: number) => {
                    return <CustomerRow key={i} customer={customer} classes={classes} />;
                  })}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {Boolean(data && data.adminGetCustomerList) && (
            <PageNav
              classes={classes}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              listLength={data?.adminGetCustomerList?.length || 0}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

const CustomerRow = (props: CustomerRowProps) => {
  const { customer } = props;

  return (
    <TableRow>
      <TableCell>{customer.id}</TableCell>
      <TableCell>{customer.firstName}</TableCell>
      <TableCell>{customer.lastName}</TableCell>
    </TableRow>
  );
};

export default CustomerList; //withStyles(styles)(withRouter(CustomerList))
