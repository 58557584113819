import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { Grid, Paper, Button, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { filter, get } from 'lodash';
import Papa from 'papaparse';

import { DateRangePicker } from 'react-dates';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip, CartesianGrid } from 'recharts';
import Title from './Title';
import Loader from './Loader/Loader';

import { useGetSubscriptionGrowthQuery, PlanStat } from '../graphql/generated';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

interface MemoOutput {
  chartData: Array<ChartRecord>;
  planIds: Array<string>;
}

interface ChartRecord {
  date: number;
  title: string;
  plans: {
    [key: string]: PlanStat;
  };
}

interface HomeProps {
  classes: any;
}

const getColorForId = (id: string) => {
  switch (id) {
    case 'kitly-usd-1':
    case 'kitly-usd-1-yearly':
    case 'co.kitly.kitly_1':
    case 'co.kitly.kitly_1_yearly':
      return '#FCE3ED';
    case 'kitly-usd-2':
    case 'kitly-usd-2-yearly':
    case 'co.kitly.kitly_2':
    case 'co.kitly.kitly_2_yearly':
      return '#cc215d';

    case 'kitly-business-usd-1':
    case 'kitly-business-usd-1-yearly':
      return '#5C5CB9';
    case 'kitly-business-usd-2':
    case 'kitly-business-usd-2-yearly':
      return '#1B1B65';
    case 'brand-0':
    case 'brand-usd-1':
    case 'brand-usd-2':
    case 'brand-usd-3':
      return '#F25116';
    case 'talent-0':
    case 'talent-usd-1':
      return '#05F29B';
    case 'influencer-0':
    case 'influencer-usd-1':
    case 'influencer-usd-2':
      return '#048ABF';
    default:
      return '#C2EDE8';
  }
};

const Home = (props: HomeProps) => {
  const fixedHeightPaper = clsx(props.classes.paper, props.classes.fixedHeight);

  const [start, setStart] = useState(moment().subtract(3, 'months'));
  const [end, setEnd] = useState(moment());
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

  const { loading, error, data } = useGetSubscriptionGrowthQuery({
    fetchPolicy: 'no-cache',
    variables: {
      // start: start.toDate(),
      // end: end.toDate()
    }
  });

  const { chartData, planIds }: MemoOutput = useMemo(() => {
    let planIds: Array<string> = [];
    let chartData: Array<ChartRecord> = [];

    if (data && data.adminGetSubscriptionGrowth) {
      chartData = data.adminGetSubscriptionGrowth.map((o) => {
        const date = moment(o.date);
        let chartRecord: ChartRecord = { date: date.valueOf(), title: date.format("Do MMM 'YY"), plans: {} };
        o?.plans?.forEach((plan) => {
          if (plan?.id) {
            chartRecord.plans[plan.id] = plan;
            if (!planIds.includes(plan.id)) planIds.push(plan.id);
          }
        });

        return chartRecord;
      });
    }
    return { chartData, planIds };
  }, [data]);

  const filteredChartData = useMemo(() => {
    return filter(chartData, (o) => {
      return moment(o.date).isBetween(start, end);
    });
  }, [chartData, start, end]);

  const latestRecord = useMemo(
    () => (filteredChartData.length ? filteredChartData[filteredChartData.length - 1] : undefined),
    [filteredChartData]
  );

  const minDate: Moment | undefined = useMemo(() => {
    return Boolean(chartData[0] && chartData[0].date) ? moment(chartData[0].date) : undefined;
  }, [chartData]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (error) return <div>{`Error! ${error.message}`}</div>;

  const now = moment();

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <DateRangePicker
            isOutsideRange={(day) => {
              const isInsideRange = minDate ? moment(day).isBetween(minDate, now) : true;
              return !isInsideRange;
            }}
            startDate={start}
            endDate={end}
            startDateId={'chart_date_start'}
            endDateId={'chart_date_end'}
            onDatesChange={({ startDate, endDate }) => {
              if (startDate) setStart(startDate);
              if (endDate) setEnd(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            onClick={() => {
              const plansByDate = filteredChartData.map((o) => {
                let plansList: { [key: string]: number } = {};

                if (o.plans) {
                  Object.keys(o.plans).forEach((key) => {
                    if (o.plans[key].label && o.plans[key].amount) {
                      plansList[o.plans[key].label!] = o.plans[key].amount!;
                    }
                  });
                }

                return {
                  date: o.title,
                  ...plansList
                };
              });

              const csvString = Papa.unparse(plansByDate);
              downloadCSV(csvString);
            }}
          >
            Download CSV
          </Button>
        </Grid>
        {latestRecord && <PlansList plans={latestRecord.plans} />}
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper} style={{ overflow: 'visible' }}>
            <Title>Subscriptions</Title>
            {planIds && (
              <ResponsiveContainer>
                <LineChart
                  data={filteredChartData}
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24
                  }}
                >
                  <CartesianGrid />
                  <XAxis
                    key={Math.random()} //To fix bug in recharts https://github.com/recharts/recharts/issues/655#issuecomment-542981873
                    dataKey='date'
                    type='number'
                    scale='time'
                    tickFormatter={(tick) => moment(tick).format("Do MMM 'YY")}
                    domain={[start.valueOf(), end.valueOf()]}
                  />
                  <YAxis />
                  {planIds.map((o) => {
                    return <Line key={o} type='monotone' dataKey={`plans.${o}.amount`} stroke={getColorForId(o)} />;
                  })}
                  <Tooltip content={CustomTooltip} />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    const plans = get(payload, [0, 'payload', 'plans']);
    return (
      <Paper style={{ width: 400, zIndex: 1000 }}>
        {payload && (
          <Grid container>
            <Grid item xs={12} style={{ fontWeight: 700 }}>
              {moment(label).format("Do MMM 'Do")}
            </Grid>
            {plans && <PlansList plans={plans} />}
          </Grid>
        )}
      </Paper>
    );
  }

  return null;
};

const PlansList = ({ plans }: { plans: any }) => {
  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} sm={6}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: 'bold' }}>Q-83 GRANDFATHERED ACCOUNTS</Typography>
        </Grid>
        <PlanRow id={'influencer-0'} plans={plans} />
        <PlanRow id={'influencer-usd-1'} plans={plans} />
        <PlanRow id={'influencer-usd-2'} plans={plans} />

        <PlanRow id={'brand-0'} plans={plans} />
        <PlanRow id={'brand-usd-1'} plans={plans} />
        <PlanRow id={'brand-usd-2'} plans={plans} />
        <PlanRow id={'brand-usd-3'} plans={plans} />

        <PlanRow id={'talent-0'} plans={plans} />
        <PlanRow id={'talent-usd-1'} plans={plans} />
      </Grid>
      <Grid item container xs={12} sm={6}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: 'bold' }}>NEW KITLY and KITLY BUSINESS USERS</Typography>
        </Grid>
        <PlanRow id={'kitly-usd-0'} plans={plans} />
        <PlanRow id={'kitly-usd-1'} plans={plans} />
        <PlanRow id={'kitly-usd-1-yearly'} plans={plans} />
        <PlanRow id={'co.kitly.kitly_1'} plans={plans} />
        <PlanRow id={'co.kitly.kitly_1_yearly'} plans={plans} />
        <PlanRow id={'kitly-usd-2'} plans={plans} />
        <PlanRow id={'kitly-usd-2-yearly'} plans={plans} />
        <PlanRow id={'co.kitly.kitly_2'} plans={plans} />
        <PlanRow id={'co.kitly.kitly_2_yearly'} plans={plans} />

        <PlanRow id={'kitly-business-usd-0'} plans={plans} />
        <PlanRow id={'kitly-business-usd-1'} plans={plans} />
        <PlanRow id={'kitly-business-usd-1-yearly'} plans={plans} />
        <PlanRow id={'kitly-business-usd-2'} plans={plans} />
        <PlanRow id={'kitly-business-usd-2-yearly'} plans={plans} />
      </Grid>
    </Grid>
  );
};

const PlanRow = ({ id, plans }: { id: string; plans: any }) => {
  if (!plans[id]) return null;

  return (
    <Grid item container xs={12} justifyContent={'flex-start'} spacing={1}>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}>
        <div style={{ backgroundColor: getColorForId(id), width: 12, height: 12, borderRadius: 6 }}></div>
      </Grid>
      <Grid item xs={8} style={{ textAlign: 'left' }}>{`${plans[id].label}: `}</Grid>
      <Grid item xs={2}>
        <Typography style={{ fontWeight: 'bold' }}>{`${plans[id].amount}`}</Typography>
      </Grid>
    </Grid>
  );
};

const downloadCSV = (csvString: string) => {
  var blob = new Blob([csvString]);
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = 'subscriptions.csv';
  document.body.appendChild(a);
  a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
  document.body.removeChild(a);
};

export default Home;
