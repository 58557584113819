import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { User } from '../graphql/generated';
import FacebookIcon from '../assets/icons/facebook.svg';
import GoogleIcon from '../assets/icons/google.svg';
import AppleIcon from '../assets/icons/apple.svg';
import TiktokIcon from '../assets/icons/tiktok.svg';
import PhoneLockIcon from '../assets/icons/ic_phone_locked_48px.svg';

interface IUserEmailDisplay {
  user: User;
}

const styles = makeStyles(() => ({
  socialIcon: {
    display: 'block',
    marginLeft: 2
  }
}));

const UserEmailDisplay = ({ user }: IUserEmailDisplay) => {
  const classes = styles();
  return (
    <Grid container justifyContent='space-between' wrap='nowrap' alignItems='center' spacing={1}>
      <Grid item>
        <Typography style={{ whiteSpace: 'nowrap' }}>{user.email}</Typography>
      </Grid>
      <Grid item container justifyContent='flex-end' wrap='nowrap' alignItems='center'>
        {user.isMfaEnabled && (
          <Grid item>
            <img src={PhoneLockIcon} width={20} height={20} alt='MFA login' className={classes.socialIcon} />
          </Grid>
        )}
        {user.googleUserId && (
          <Grid item>
            <img src={GoogleIcon} width={20} height={20} alt='Google login' className={classes.socialIcon} />
          </Grid>
        )}
        {user.facebookUserId && (
          <Grid item>
            <img src={FacebookIcon} width={20} height={20} alt='Facebook login' className={classes.socialIcon} />
          </Grid>
        )}
        {user.appleUserId && (
          <Grid item>
            <img src={AppleIcon} width={20} height={20} alt='Apple login' className={classes.socialIcon} />
          </Grid>
        )}
        {user.tiktokUnionId && (
          <Grid item>
            <img src={TiktokIcon} width={20} height={20} alt='TikTok login' className={classes.socialIcon} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default UserEmailDisplay;
