import { ApolloError } from '@apollo/client';
import { pick } from 'lodash';
import React, { useState, useEffect } from 'react';
import CustomTable from './CustomTable';
import Loader from './Loader/Loader';

import {
  useGetPlansListQuery,
  useUpdatePlanMutation,
  useCreatePlanMutation,
  useDeletePlanMutation,
  InfluencerPlan,
  GetPlansListDocument
} from '../graphql/generated';

const PlanList = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      console.log('PlanList errorMessage', errorMessage);
      alert(errorMessage);
    }
  }, [errorMessage]);

  const handleClose = () => {
    setErrorMessage('');
  };

  const handleError = (errMsg: ApolloError) => {
    setErrorMessage(`Your Error: ${errMsg}`);
  };

  const { loading: queryLoading, error, data, refetch } = useGetPlansListQuery({
    errorPolicy: 'all'
  });

  const [updatePlan, { loading: updateMutationLoading }] = useUpdatePlanMutation({
    onError: (err) => {
      handleError(err);
    },
    onCompleted: () => {
      refetch();
    }
  });

  const [createPlan, { loading: createMutationLoading }] = useCreatePlanMutation({
    onError: (err) => {
      handleError(err);
    },
    onCompleted: () => {
      refetch();
    }
  });

  const [deletePlan, { loading: deleteMutationLoading }] = useDeletePlanMutation({
    onError: (err) => {
      handleError(err);
    },
    onCompleted: () => {
      refetch();
    }
  });

  const planList: InfluencerPlan[] = data?.adminGetPlansList || [];

  if (error) console.log('PlanList ERROR', error.message);

  const influencerPlanFields = ['code', 'expiresAt', 'notes', 'planId'];

  const handleRowAdd = async (data: InfluencerPlan) => {
    const updates = pick(data, influencerPlanFields);

    await createPlan({
      variables: { updates }
    });
  };

  const handleRowUpdate = async (data: InfluencerPlan) => {
    const updates = pick(data, influencerPlanFields);

    await updatePlan({ variables: { planId: data._id, updates } });
  };

  const handleRowDelete = async (planId: string) => {
    await deletePlan({
      variables: { planId }
    });
    handleClose();
  };

  return (
    <>
      {(queryLoading || createMutationLoading || updateMutationLoading || deleteMutationLoading) && <Loader />}
      <CustomTable
        title='Plans'
        data={planList}
        showIdColumn
        onRowDelete={handleRowDelete}
        onRowUpdate={handleRowUpdate}
        onRowAdd={handleRowAdd}
      />
    </>
  );
};

export default PlanList;
