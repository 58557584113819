import React, { useState } from 'react';
import api from '../config/api';
import config from '../config/appConfig';
import { InsightIqAvailableChannel } from 'graphql/generated';

const TestButtons = () => {
  const [redirectUrl, setRedirectUrl] = useState('');

  const [payload, setPayload] = useState({
    companyName: '',
    email: '',
    firstName: '',
    lastName: '',
    handle: ''
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setPayload({
      ...payload,
      [name]: value
    });
  };

  const handleTestButton = () => {
    const queryString = Object.keys(payload)
      // @ts-ignore
      .map((key) => `${key}=${encodeURIComponent(payload[key])}`)
      .join('&');

    api(`api/discovery/credits?${queryString}`, null, { 'x-kitly-api-key': config.DISCOVERY_API_KEY });
  };

  const handleTestPostButton = () => {
    api(
      'api/discovery/register',
      { ...payload, channel: InsightIqAvailableChannel.Instagram },
      { 'x-kitly-api-key': config.DISCOVERY_API_KEY }
    ).then((resp) => {
      if (resp.data.redirectUrl) {
        setRedirectUrl(resp.data.redirectUrl);
      }
    });
  };

  return (
    <>
      <div>
        <input type='text' name='handle' value={payload.handle} onChange={handleInputChange} placeholder='IG Handle' />
      </div>
      <div>
        <input
          type='text'
          name='companyName'
          value={payload.companyName}
          onChange={handleInputChange}
          placeholder='Company Name'
        />
        <input type='text' name='email' value={payload.email} onChange={handleInputChange} placeholder='Email' />
        <input
          type='text'
          name='firstName'
          value={payload.firstName}
          onChange={handleInputChange}
          placeholder='First Name'
        />
        <input
          type='text'
          name='lastName'
          value={payload.lastName}
          onChange={handleInputChange}
          placeholder='Last Name'
        />
      </div>
      <button onClick={handleTestButton}>Test Discovery Credits</button>
      <button onClick={handleTestPostButton}>Test Discovery Register</button>
      <hr />
      <div>
        <a href={redirectUrl} target='_blank' rel='noopener noreferrer'>
          {redirectUrl}
        </a>
      </div>
    </>
  );
};

export default TestButtons;
