let { hostname, protocol } = window.location;

const config = {
  PROFILE_PIC_PATH: 'https://cache-global.q-83.com/profile_pics/',
  TEST_TOKEN: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1ZDU3NmY0MGE3OThhODAwMTg2YWZhMjUiLCJpYXQiOjE1NzE2MjkzOTZ9.earvbDhBAeQoE1ZBsjRBRraq6tsVIRVaCg3bIdApPlE`,
  API_URL: `${protocol}//${hostname}:3333/`,
  APP_URL: `${protocol}//${hostname}:3000`,
  INFLUENCER_APP_URL: `${protocol}//${hostname}:3011`,
  MEDIA_KIT_URL: `${protocol}//${hostname}:3004`,
  MIXPANEL_URL: `https://mixpanel.com/project/2118841/view/270069/app`,
  DISCOVERY_API_KEY: '98127g3612763f2'
};

if (process.env.REACT_APP_DEPLOYMENT === 'staging' || process.env.REACT_APP_DATABASE === 'staging') {
  config.API_URL = 'https://api-v2-staging.q-83.com/';
} else if (process.env.REACT_APP_DEPLOYMENT === 'production' || process.env.REACT_APP_DATABASE === 'production') {
  config.API_URL = 'https://api-v2.q-83.com/';
}

if (process.env.REACT_APP_DEPLOYMENT === 'staging') {
  config.APP_URL = 'https://app-staging.kitlybusiness.co';
  config.INFLUENCER_APP_URL = 'https://app-staging.kitly.co';
  config.MEDIA_KIT_URL = 'https://kit-staging.kitly.co';
  config.MIXPANEL_URL = `https://mixpanel.com/project/2118841/view/270069/app`;
} else if (process.env.REACT_APP_DEPLOYMENT === 'production') {
  config.APP_URL = 'https://app.kitlybusiness.co';
  config.INFLUENCER_APP_URL = 'https://app.kitly.co';
  config.MEDIA_KIT_URL = 'https://kit.kitly.co';
  config.MIXPANEL_URL = `https://mixpanel.com/project/2120247/view/271457/app`;
}

export default config;
