import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** Convert Braintree Date to JS Date */
  BraintreeDate: any;
  /** Date custom scalar type */
  Date: any;
  /** Date in the format of YYYY-MM-DD */
  DateString: any;
  /** For parsing a javascript object into a string */
  JSObject: any;
  /** Mongo object id scalar type */
  ObjectId: any;
  /** Convert Stripe Timestamp to JS Date */
  StripeTimestamp: any;
  /** Time in hours from 0 to 2399 */
  TimeHours: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AwsTemporaryCredentials = {
  __typename?: 'AWSTemporaryCredentials';
  AccessKeyId?: Maybe<Scalars['String']>;
  SecretAccessKey?: Maybe<Scalars['String']>;
  SessionToken?: Maybe<Scalars['String']>;
  Expiration?: Maybe<Scalars['Date']>;
  region?: Maybe<Scalars['String']>;
  apiVersion?: Maybe<Scalars['String']>;
};

export type ActiveNotifications = {
  __typename?: 'ActiveNotifications';
  isAssignNewDeliverables?: Maybe<Scalars['Boolean']>;
  isMediaKitActivity?: Maybe<Scalars['Boolean']>;
  isPromotionsAndMarketing?: Maybe<Scalars['Boolean']>;
  isRememberDeliverables?: Maybe<Scalars['Boolean']>;
  isReportActivity?: Maybe<Scalars['Boolean']>;
  isSyncDeliverableToCalendar?: Maybe<Scalars['Boolean']>;
  isNotifiedOfWeeklyReport?: Maybe<Scalars['Boolean']>;
};

export type ActiveNotificationsUpdate = {
  isAssignNewDeliverables?: Maybe<Scalars['Boolean']>;
  isMediaKitActivity?: Maybe<Scalars['Boolean']>;
  isPromotionsAndMarketing?: Maybe<Scalars['Boolean']>;
  isRememberDeliverables?: Maybe<Scalars['Boolean']>;
  isReportActivity?: Maybe<Scalars['Boolean']>;
  isSyncDeliverableToCalendar?: Maybe<Scalars['Boolean']>;
  isNotifiedOfWeeklyReport?: Maybe<Scalars['Boolean']>;
};

export enum AdType {
  Kitlyweb = 'KITLYWEB',
  Kitlyapp = 'KITLYAPP',
  Kitlybusiness = 'KITLYBUSINESS'
}

export type AdminProfileInfo = {
  __typename?: 'AdminProfileInfo';
  profileInfo?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

export type AdminReportStats = {
  __typename?: 'AdminReportStats';
  reports?: Maybe<Array<Maybe<ReportConfig>>>;
  predictionStats?: Maybe<PredictionStats>;
};

export type AgeGroup = {
  __typename?: 'AgeGroup';
  ageGroup?: Maybe<Scalars['String']>;
  viewerPercentage?: Maybe<Scalars['Float']>;
};

export type Ambassador = {
  __typename?: 'Ambassador';
  _id: Scalars['ObjectId'];
  acceptedInvite?: Maybe<Scalars['Boolean']>;
  ambassadorId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  brandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  disconnectedAt?: Maybe<Scalars['Date']>;
  inviteSent?: Maybe<Scalars['Date']>;
  /** @deprecated Use inviteSent */
  invite_sent?: Maybe<Scalars['Date']>;
  isArchive?: Maybe<Scalars['Boolean']>;
  talentNotes?: Maybe<Scalars['String']>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  couponCode?: Maybe<Scalars['ObjectId']>;
  info?: Maybe<AmbassadorInfo>;
  clientIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  attachments?: Maybe<Array<Maybe<LinkPair>>>;
  invitedPublicUsername?: Maybe<Scalars['String']>;
  userDeletedDate?: Maybe<Scalars['Date']>;
  ambassadorAuditReportLink?: Maybe<Scalars['String']>;
  status?: Maybe<AmbassadorStatus>;
  user?: Maybe<User>;
  brand?: Maybe<User>;
  profile?: Maybe<Profile>;
  profiles?: Maybe<Array<Profile>>;
  profileUser?: Maybe<User>;
  contact?: Maybe<Contact>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  activity?: Maybe<Array<AmbassadorActivity>>;
  clients?: Maybe<Array<Contact>>;
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  collabsCount?: Maybe<Scalars['Int']>;
  deliverablesCount?: Maybe<Scalars['Int']>;
  instagramReelPerformance?: Maybe<Scalars['String']>;
  instagramFeedPerformance?: Maybe<Scalars['String']>;
  instagramStoryPerformance?: Maybe<Scalars['String']>;
  tiktokVideoPerformance?: Maybe<Scalars['String']>;
  youtubeVideoPerformance?: Maybe<Scalars['String']>;
  facebookPostPerformance?: Maybe<Scalars['String']>;
  facebookVideoPerformance?: Maybe<Scalars['String']>;
  networkAgency?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<AmbassadorHistory>>>;
};

export type AmbassadorActivity = {
  __typename?: 'AmbassadorActivity';
  itemId?: Maybe<Scalars['ObjectId']>;
  timestamp?: Maybe<Scalars['Date']>;
  action?: Maybe<AmbassadorActivityAction>;
  type?: Maybe<AmbassadorActivityType>;
  profileId?: Maybe<Scalars['ObjectId']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profile?: Maybe<Profile>;
  ambassador?: Maybe<Ambassador>;
  mediaKitLink?: Maybe<MediaKitLink>;
  reportConfig?: Maybe<ReportConfig>;
  deliverable?: Maybe<Deliverable>;
};

export enum AmbassadorActivityAction {
  Sent = 'SENT',
  Opened = 'OPENED',
  Added = 'ADDED',
  SetUp = 'SET_UP'
}

export enum AmbassadorActivityType {
  MediaKit = 'MEDIA_KIT',
  Report = 'REPORT',
  Deliverable = 'DELIVERABLE'
}

export type AmbassadorAgentDetails = {
  __typename?: 'AmbassadorAgentDetails';
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type AmbassadorAgentDetailsInput = {
  name?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export enum AmbassadorBusinessType {
  Company = 'COMPANY',
  SoleTrader = 'SOLE_TRADER'
}

export type AmbassadorContractDetails = {
  __typename?: 'AmbassadorContractDetails';
  contractName?: Maybe<Scalars['String']>;
  renewalDate?: Maybe<Scalars['Date']>;
};

export type AmbassadorContractDetailsInput = {
  contractName?: Maybe<Scalars['String']>;
  renewalDate?: Maybe<Scalars['Date']>;
};

export type AmbassadorFrequentFlyerDetails = {
  __typename?: 'AmbassadorFrequentFlyerDetails';
  carrier?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type AmbassadorFrequentFlyerDetailsInput = {
  carrier?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type AmbassadorHistory = {
  __typename?: 'AmbassadorHistory';
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  profileId?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
};

export type AmbassadorInfo = {
  __typename?: 'AmbassadorInfo';
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  agentDetails?: Maybe<AmbassadorAgentDetails>;
  dateOfBirth?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  businessType?: Maybe<AmbassadorBusinessType>;
  dietaryRequirements?: Maybe<Scalars['String']>;
  businessStructure?: Maybe<Scalars['String']>;
  gstRegistration?: Maybe<Scalars['Boolean']>;
  frequentFlyerDetails?: Maybe<AmbassadorFrequentFlyerDetails>;
  contractDetails?: Maybe<AmbassadorContractDetails>;
  contractRenews?: Maybe<Scalars['Date']>;
};

export type AmbassadorInfoInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  phoneNumber?: Maybe<Scalars['String']>;
  agentDetails?: Maybe<AmbassadorAgentDetailsInput>;
  dateOfBirth?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  businessType?: Maybe<AmbassadorBusinessType>;
  dietaryRequirements?: Maybe<Scalars['String']>;
  businessStructure?: Maybe<Scalars['String']>;
  gstRegistration?: Maybe<Scalars['Boolean']>;
  frequentFlyerDetails?: Maybe<AmbassadorFrequentFlyerDetailsInput>;
  contractDetails?: Maybe<AmbassadorContractDetailsInput>;
};

export type AmbassadorInput = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedStatus?: Maybe<Scalars['Boolean']>;
  talentNotes?: Maybe<Scalars['String']>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  info?: Maybe<AmbassadorInfoInput>;
  clientIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  attachments?: Maybe<Array<Maybe<FileInput>>>;
  invitedPublicUsername?: Maybe<Scalars['String']>;
};

export type AmbassadorLoginResult = {
  __typename?: 'AmbassadorLoginResult';
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type AmbassadorRegistrationResult = {
  __typename?: 'AmbassadorRegistrationResult';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  data?: Maybe<AmbassadorLoginResult>;
  message?: Maybe<Scalars['String']>;
};

export enum AmbassadorStatus {
  Accepted = 'ACCEPTED',
  PendingInviteFromInfluencer = 'PENDING_INVITE_FROM_INFLUENCER',
  PendingInviteFromBrand = 'PENDING_INVITE_FROM_BRAND',
  DeclinedInviteFromInfluencer = 'DECLINED_INVITE_FROM_INFLUENCER',
  DeclinedInviteFromBrand = 'DECLINED_INVITE_FROM_BRAND'
}

export type AppPurchaseRecord = {
  __typename?: 'AppPurchaseRecord';
  acknowledged?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  purchaseTime?: Maybe<Scalars['Date']>;
  purchaseState?: Maybe<Scalars['String']>;
  transactionReceipt?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['String']>;
  originalPurchaseTime?: Maybe<Scalars['Float']>;
};

export type AppSubscription = {
  __typename?: 'AppSubscription';
  acknowledged?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  purchaseTime?: Maybe<Scalars['Date']>;
  purchaseState?: Maybe<Scalars['String']>;
  transactionReceipt?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['String']>;
  originalPurchaseTime?: Maybe<Scalars['Float']>;
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  invalidatedById?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  invalidatedBy?: Maybe<AppSubscription>;
};

export type AppSubscriptionInput = {
  acknowledged?: Maybe<Scalars['Boolean']>;
  productId?: Maybe<Scalars['String']>;
  transactionReceipt?: Maybe<Scalars['String']>;
  purchaseTime?: Maybe<Scalars['Date']>;
  purchaseState?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  originalOrderId?: Maybe<Scalars['String']>;
  originalPurchaseTime?: Maybe<Scalars['Float']>;
};

export type AppUpdate = {
  __typename?: 'AppUpdate';
  version?: Maybe<Scalars['String']>;
  panels?: Maybe<Array<Maybe<AppUpdatePanel>>>;
  minimumVersion?: Maybe<Scalars['String']>;
  notifyUpToVersion?: Maybe<Scalars['String']>;
  notifyDownToVersion?: Maybe<Scalars['String']>;
  navigateTo?: Maybe<Scalars['String']>;
};

export type AppUpdatePanel = {
  __typename?: 'AppUpdatePanel';
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  widthPercentage?: Maybe<Scalars['Float']>;
  navigateTo?: Maybe<Scalars['String']>;
};

export type AudienceDemographicsContent = {
  __typename?: 'AudienceDemographicsContent';
  type?: Maybe<ElementType>;
  audience_demographics_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type AudienceGender = {
  __typename?: 'AudienceGender';
  male?: Maybe<MetricSummary>;
  female?: Maybe<MetricSummary>;
  unspecified?: Maybe<MetricSummary>;
};

export enum BankCode {
  Bsb = 'BSB',
  Bic = 'BIC',
  Swift = 'SWIFT',
  Chips = 'CHIPS',
  Bncc = 'BNCC',
  Bsc = 'BSC',
  Ifsc = 'IFSC'
}

export type BitlinkSummary = {
  __typename?: 'BitlinkSummary';
  unit_reference?: Maybe<Scalars['String']>;
  total_clicks?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type BitlyReportStats = {
  __typename?: 'BitlyReportStats';
  totalsByCountry?: Maybe<Scalars['JSObject']>;
  totalsByType?: Maybe<Scalars['JSObject']>;
};

export type BraintreeCustomer = {
  __typename?: 'BraintreeCustomer';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
  addresses?: Maybe<Array<Scalars['JSObject']>>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Scalars['JSObject']>>;
  user?: Maybe<User>;
  transactions?: Maybe<Array<BraintreeTransaction>>;
};

export type BraintreePaymentMethod = {
  __typename?: 'BraintreePaymentMethod';
  token?: Maybe<Scalars['String']>;
  billingAgreementId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  payerId?: Maybe<Scalars['String']>;
  revokedAt?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['JSObject']>;
  bin?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  cardholderName?: Maybe<Scalars['String']>;
  commercial?: Maybe<Scalars['String']>;
  countryOfIssue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  customerId?: Maybe<Scalars['String']>;
  customer?: Maybe<BraintreeCustomer>;
  customerLocation?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  durbinRegulated?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  healthcare?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  issuingBank?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  maskedNumber?: Maybe<Scalars['String']>;
  payroll?: Maybe<Scalars['String']>;
  prepaid?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  subscriptions?: Maybe<Array<Maybe<BraintreeSubscription>>>;
  uniqueNumberIdentifier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
};

export type BraintreeSubscription = {
  __typename?: 'BraintreeSubscription';
  id: Scalars['ID'];
  balance?: Maybe<Scalars['String']>;
  billingPeriodStartDate?: Maybe<Scalars['String']>;
  billingPeriodEndDate?: Maybe<Scalars['String']>;
  billingDayOfMonth?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  currentBillingCycle?: Maybe<Scalars['Int']>;
  daysPastDue?: Maybe<Scalars['Int']>;
  transactions?: Maybe<Array<BraintreeTransaction>>;
  firstBillingDate?: Maybe<Scalars['Date']>;
  price?: Maybe<Scalars['String']>;
  merchantAccountId?: Maybe<Scalars['String']>;
  nextBillAmount?: Maybe<Scalars['String']>;
  nextBillingDate?: Maybe<Scalars['BraintreeDate']>;
  nextBillingPeriodAmount?: Maybe<Scalars['String']>;
  neverExpires?: Maybe<Scalars['Boolean']>;
  paidThroughDate?: Maybe<Scalars['Date']>;
  paymentMethodToken?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
  trialPeriod?: Maybe<Scalars['Boolean']>;
  failureCount?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<BraintreePaymentMethod>;
  user?: Maybe<User>;
};

export type BraintreeTransaction = {
  __typename?: 'BraintreeTransaction';
  id: Scalars['ID'];
  additionalProccessorResponse?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['BraintreeDate']>;
  currencyIsoCode?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  taxAmount?: Maybe<Scalars['String']>;
  processorResponseCode?: Maybe<Scalars['String']>;
  processorResponseText?: Maybe<Scalars['String']>;
  processorSettlementResponseCode?: Maybe<Scalars['String']>;
  processorSettlementResponseText?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['BraintreeDate']>;
  subscriptionId?: Maybe<Scalars['ID']>;
  refundedTransactionId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  discountAmount?: Maybe<Scalars['String']>;
  subscription?: Maybe<BraintreeSubscription>;
  refundedTransaction?: Maybe<BraintreeTransaction>;
  paymentMethodType?: Maybe<Scalars['String']>;
  paymentMethodDescription?: Maybe<Scalars['String']>;
  paymentMethodImageUrl?: Maybe<Scalars['String']>;
};

export type ChannelReport = {
  __typename?: 'ChannelReport';
  day?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
  /** @deprecated Use views */
  redViews?: Maybe<Scalars['Int']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  /** @deprecated Use estimatedMinutesWatched */
  estimatedRedMinutesWatched?: Maybe<Scalars['Int']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  /** @deprecated Not supported */
  subscribersLost?: Maybe<Scalars['Int']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
};

export type ChannelStats = {
  __typename?: 'ChannelStats';
  viewCount?: Maybe<Scalars['BigInt']>;
  commentCount?: Maybe<Scalars['BigInt']>;
  subscriberCount?: Maybe<Scalars['Int']>;
  hiddenSubscriberCount?: Maybe<Scalars['Boolean']>;
  videoCount?: Maybe<Scalars['Int']>;
};

export type Collaboration = {
  __typename?: 'Collaboration';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  avatar?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CollaborationStatus>;
  stage?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['ObjectId']>;
  collaborationStart?: Maybe<Scalars['Date']>;
  collaborationEnd?: Maybe<Scalars['Date']>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  hasShareWithTalent?: Maybe<Scalars['Boolean']>;
  fileUploads?: Maybe<Array<Maybe<LinkPair>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  reportId?: Maybe<Scalars['ObjectId']>;
  delegateId?: Maybe<Scalars['ObjectId']>;
  assignedToMaster?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  canEdit?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Contact>;
  profiles?: Maybe<Array<Profile>>;
  ambassadors?: Maybe<Array<Ambassador>>;
  trackingLinks?: Maybe<Array<TrackingLink>>;
  deliverables?: Maybe<Array<Deliverable>>;
  userRows?: Maybe<Array<CollaborationUserRow>>;
  profileRows?: Maybe<Array<Maybe<CollaborationProfileRow>>>;
  delegateUser?: Maybe<User>;
};

export type CollaborationInvoice = {
  __typename?: 'CollaborationInvoice';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  inactive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  status?: Maybe<InvoiceStatus>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['String']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
  deliverableIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  invoiceToContactId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  stripeInvoiceId?: Maybe<Scalars['String']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  taxStatus?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  taxPercentage?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  currency?: Maybe<UserCurrency>;
  user?: Maybe<User>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
  collaboration?: Maybe<Collaboration>;
  profiles?: Maybe<Array<Profile>>;
  stripeInvoice?: Maybe<StripeInvoice>;
  pdfLink?: Maybe<Scalars['String']>;
  links?: Maybe<Array<CollaborationInvoiceLink>>;
};

export type CollaborationInvoiceLink = {
  __typename?: 'CollaborationInvoiceLink';
  _id: Scalars['ObjectId'];
  collaborationInvoiceId?: Maybe<Scalars['ObjectId']>;
  shortenedLink?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactDetails?: Maybe<InvoiceShareContact>;
  isOpened?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  contact?: Maybe<Contact>;
};

export type CollaborationInvoiceLinkCreationResult = {
  __typename?: 'CollaborationInvoiceLinkCreationResult';
  collaborationInvoiceId?: Maybe<Scalars['ObjectId']>;
  link?: Maybe<Scalars['String']>;
};

export type CollaborationInvoiceTotals = {
  __typename?: 'CollaborationInvoiceTotals';
  currency?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Float']>;
  due?: Maybe<Scalars['Float']>;
  overdue?: Maybe<Scalars['Float']>;
  allPercentage?: Maybe<Scalars['Float']>;
  paidPercentage?: Maybe<Scalars['Float']>;
  duePercentage?: Maybe<Scalars['Float']>;
  contact?: Maybe<Contact>;
};

export type CollaborationInvoiceTotalsResponse = {
  __typename?: 'CollaborationInvoiceTotalsResponse';
  totals?: Maybe<Array<Maybe<CollaborationInvoiceTotals>>>;
  invoices?: Maybe<Array<Maybe<CollaborationInvoice>>>;
  stripeInvoices?: Maybe<Array<Maybe<StripeInvoice>>>;
};

export type CollaborationInvoiceUpdates = {
  name?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  status?: Maybe<InvoiceStatus>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
  deliverableIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  invoiceToContactId?: Maybe<Scalars['ObjectId']>;
  taxStatus?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  taxPercentage?: Maybe<Scalars['Float']>;
  stripeTaxRateId?: Maybe<Scalars['String']>;
};

export type CollaborationProfileRow = {
  __typename?: 'CollaborationProfileRow';
  profile?: Maybe<Profile>;
  ambassador?: Maybe<Ambassador>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
};

export enum CollaborationStatus {
  Draft = 'DRAFT',
  Quoted = 'QUOTED',
  Invoiced = 'INVOICED',
  Negotiation = 'NEGOTIATION',
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Archived = 'ARCHIVED'
}

export type CollaborationTotals = {
  __typename?: 'CollaborationTotals';
  currency?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  totalPrice?: Maybe<Scalars['Float']>;
};

export type CollaborationTotalsResponse = {
  __typename?: 'CollaborationTotalsResponse';
  totals?: Maybe<Array<Maybe<CollaborationTotals>>>;
  collaborations?: Maybe<Array<Maybe<Collaboration>>>;
};

export type CollaborationUpdateResponse = {
  __typename?: 'CollaborationUpdateResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  collaboration?: Maybe<Collaboration>;
};

export type CollaborationUpdates = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CollaborationStatus>;
  brandId?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['Upload']>;
  hasShareWithTalent?: Maybe<Scalars['Boolean']>;
  fileUploads?: Maybe<Array<Maybe<FileInput>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  notes?: Maybe<Scalars['String']>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  collaborationStart?: Maybe<Scalars['Date']>;
  collaborationEnd?: Maybe<Scalars['Date']>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  delegateId?: Maybe<Scalars['ObjectId']>;
  assignedToMaster?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
};

export type CollaborationUserRow = {
  __typename?: 'CollaborationUserRow';
  user?: Maybe<User>;
  deliverables?: Maybe<Array<Maybe<Deliverable>>>;
};

export type CompressedImageInput = {
  url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type ConnectReponse = {
  __typename?: 'ConnectReponse';
  isKBUserExisted?: Maybe<Scalars['Boolean']>;
};

export type Contact = {
  __typename?: 'Contact';
  _id: Scalars['ObjectId'];
  type?: Maybe<ContactType>;
  parentId?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  socialHandle?: Maybe<Scalars['String']>;
  socialChannel?: Maybe<SocialChannel>;
  connectedUserId?: Maybe<Scalars['ObjectId']>;
  userConnectionStatus?: Maybe<ContactUserConnectionStatus>;
  userConnectionInviteSentAt?: Maybe<Scalars['Date']>;
  collaborations?: Maybe<Array<Collaboration>>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  parent?: Maybe<Contact>;
  children?: Maybe<Array<Contact>>;
  activity?: Maybe<Array<ContactActivity>>;
  connectedUser?: Maybe<User>;
};

export type ContactActivity = {
  __typename?: 'ContactActivity';
  itemId?: Maybe<Scalars['ObjectId']>;
  action?: Maybe<ContactActivityAction>;
  type?: Maybe<ContactActivityType>;
  timestamp?: Maybe<Scalars['Date']>;
  contact?: Maybe<Contact>;
  user?: Maybe<User>;
};

export enum ContactActivityAction {
  Sent = 'SENT',
  Opened = 'OPENED',
  Added = 'ADDED',
  SetUp = 'SET_UP'
}

export enum ContactActivityType {
  MediaKit = 'MEDIA_KIT',
  Report = 'REPORT',
  Deliverable = 'DELIVERABLE',
  Collaboration = 'COLLABORATION'
}

export enum ContactType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL'
}

export type ContactUpdates = {
  type?: Maybe<ContactType>;
  parentId?: Maybe<Scalars['ObjectId']>;
  avatar?: Maybe<Scalars['Upload']>;
  companyName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  socialHandle?: Maybe<Scalars['String']>;
  socialChannel?: Maybe<SocialChannel>;
};

export enum ContactUserConnectionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED'
}

export type ContentfulPlatformAd = {
  __typename?: 'ContentfulPlatformAd';
  mediaUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  altText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  freeUserAd?: Maybe<Scalars['Boolean']>;
  type?: Maybe<AdType>;
};

export type ContentfulPlatformAdParams = {
  containerWidth?: Maybe<Scalars['Float']>;
  containerHeight?: Maybe<Scalars['Float']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<AdType>;
};

export type CouponCode = {
  __typename?: 'CouponCode';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  code?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  headerImageMobile?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  stripePromotionCodeId?: Maybe<Scalars['String']>;
};

export type CouponCodeUpdates = {
  code?: Maybe<Scalars['String']>;
  discountId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  headerImageMobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export enum DataAccessType {
  All = 'ALL',
  Demographics = 'DEMOGRAPHICS',
  Media = 'MEDIA'
}

export type Delegate = {
  __typename?: 'Delegate';
  _id: Scalars['ObjectId'];
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  delegateEmail?: Maybe<Scalars['String']>;
  masterId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  /** @deprecated Use brandProfiles. */
  brand_profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'companyName'. */
  agency?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  user?: Maybe<User>;
  master?: Maybe<User>;
  brandProfiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DelegateCreationInput = {
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  delegateEmail?: Maybe<Scalars['String']>;
  masterId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  brand_profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  companyName?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
};

export type DelegateLoginResult = {
  __typename?: 'DelegateLoginResult';
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type DelegateRegistrationInput = {
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  admin_verification_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  detected_timezone?: Maybe<Scalars['String']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  type?: Maybe<UserType>;
  agency?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export type DelegateRegistrationResult = {
  __typename?: 'DelegateRegistrationResult';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  delegate?: Maybe<Delegate>;
  data?: Maybe<DelegateLoginResult>;
  message?: Maybe<Scalars['String']>;
};

export type DelegateUserUpdates = {
  delegateId: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type Deliverable = {
  __typename?: 'Deliverable';
  _id: Scalars['ObjectId'];
  collaborationId?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  customizeSocialDeliverable?: Maybe<Scalars['String']>;
  deliverableName?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateString']>;
  endTime?: Maybe<Scalars['TimeHours']>;
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<UserCurrency>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  socialChannel?: Maybe<SocialChannel>;
  socialDeliverable?: Maybe<SocialDeliverable>;
  startDate?: Maybe<Scalars['DateString']>;
  startTime?: Maybe<Scalars['DateString']>;
  updated_at?: Maybe<Scalars['Date']>;
  trackingLinkIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  fileUploads?: Maybe<Array<Maybe<LinkPair>>>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  valueAddedMediaIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  storyFrameCount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  profiles?: Maybe<Array<Profile>>;
  ambassadors?: Maybe<Array<Ambassador>>;
  collaboration?: Maybe<Collaboration>;
  canEdit?: Maybe<Scalars['Boolean']>;
  trackingLinks?: Maybe<Array<TrackingLink>>;
  isViewed?: Maybe<Scalars['Boolean']>;
  media?: Maybe<DeliverableMedia>;
};

export type DeliverableMedia = {
  __typename?: 'DeliverableMedia';
  instagramMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
  youtubeMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  facebookPosts?: Maybe<Array<Maybe<FacebookPost>>>;
  tiktokMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
};

export type DeliverableRateField = {
  __typename?: 'DeliverableRateField';
  channel?: Maybe<SocialChannel>;
  deliverable?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type DeliverableRateInput = {
  channel?: Maybe<SocialChannel>;
  deliverable?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type DeliverableRatesContent = {
  __typename?: 'DeliverableRatesContent';
  type?: Maybe<ElementType>;
  deliverable_rates_content?: Maybe<Array<Maybe<DeliverableRateField>>>;
};

export type DeliverableUpdates = {
  collaborationId?: Maybe<Scalars['ObjectId']>;
  customizeSocialDeliverable?: Maybe<Scalars['String']>;
  deliverableName?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['DateString']>;
  endTime?: Maybe<Scalars['TimeHours']>;
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<UserCurrency>;
  profileIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  socialChannel?: Maybe<SocialChannel>;
  socialDeliverable?: Maybe<SocialDeliverable>;
  trackingLinkIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  startDate?: Maybe<Scalars['DateString']>;
  startTime?: Maybe<Scalars['TimeHours']>;
  fileUploads?: Maybe<Array<Maybe<FileInput>>>;
  ambassadorIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  storyFrameCount?: Maybe<Scalars['Int']>;
};

export type DemographicFilter = {
  value?: Maybe<Scalars['String']>;
  minPercentage?: Maybe<Scalars['Float']>;
};

export type DiscoverySearchResult = {
  __typename?: 'DiscoverySearchResult';
  rows?: Maybe<Array<Maybe<DiscoverySearchRow>>>;
  metadata?: Maybe<InsightIqDiscoveryMetadata>;
};

export type DiscoverySearchRow = {
  __typename?: 'DiscoverySearchRow';
  publicSearchProfile?: Maybe<InsightIqDiscoverySearchProfile>;
  talentNetworkResult?: Maybe<ProfileBrowseResult>;
  displayName?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  followersCount?: Maybe<Scalars['Int']>;
  engagementRate?: Maybe<Scalars['Float']>;
  profileType?: Maybe<SocialChannel>;
};

export type ElementContentType =
  | TextContent
  | RichTextContent
  | ImageContent
  | ImageListContent
  | GalleryContent
  | LinksContent
  | FilesContent
  | RatesContent
  | DeliverableRatesContent
  | LinkedAccountsContent
  | FeaturedInsightsContent
  | VideoContent
  | AudienceDemographicsContent
  | SelectedMediaContent;

export enum ElementTarget {
  Template = 'TEMPLATE',
  Share = 'SHARE'
}

export enum ElementType {
  Text = 'TEXT',
  RichText = 'RICH_TEXT',
  Image = 'IMAGE',
  ImageList = 'IMAGE_LIST',
  Video = 'VIDEO',
  Gallery = 'GALLERY',
  Links = 'LINKS',
  Rates = 'RATES',
  DeliverableRates = 'DELIVERABLE_RATES',
  Files = 'FILES',
  LinkedAccounts = 'LINKED_ACCOUNTS',
  FeaturedInsights = 'FEATURED_INSIGHTS',
  AudienceDemographics = 'AUDIENCE_DEMOGRAPHICS',
  SelectedMedia = 'SELECTED_MEDIA'
}

export type FacebookActionStats = {
  __typename?: 'FacebookActionStats';
  checkin?: Maybe<Scalars['Int']>;
  coupon?: Maybe<Scalars['Int']>;
  event?: Maybe<Scalars['Int']>;
  fan?: Maybe<Scalars['Int']>;
  mention?: Maybe<Scalars['Int']>;
  page_post?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['Int']>;
  user_post?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['Int']>;
};

export type FacebookFanSource = {
  __typename?: 'FacebookFanSource';
  ads?: Maybe<FacebookFanSourceStats>;
  feed?: Maybe<FacebookFanSourceStats>;
  page_suggestions?: Maybe<FacebookFanSourceStats>;
  deactivated_accounts?: Maybe<FacebookFanSourceStats>;
  search?: Maybe<FacebookFanSourceStats>;
  other?: Maybe<FacebookFanSourceStats>;
};

export type FacebookFanSourceStats = {
  __typename?: 'FacebookFanSourceStats';
  adds?: Maybe<Scalars['Int']>;
  removes?: Maybe<Scalars['Int']>;
  netChange?: Maybe<Scalars['Int']>;
};

export type FacebookMarketingInsights = {
  __typename?: 'FacebookMarketingInsights';
  reach?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
};

export type FacebookMediaAverages = {
  __typename?: 'FacebookMediaAverages';
  date?: Maybe<Scalars['Date']>;
  insights?: Maybe<FacebookPostAverages>;
};

export type FacebookMediaTrends = {
  __typename?: 'FacebookMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<FacebookMediaAverages>>>;
  recentAverages?: Maybe<FacebookMediaAverages>;
  comparisonAverages?: Maybe<FacebookMediaAverages>;
};

export type FacebookPageCategory = {
  __typename?: 'FacebookPageCategory';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type FacebookPageEngagement = {
  __typename?: 'FacebookPageEngagement';
  count?: Maybe<Scalars['Int']>;
  social_sentence?: Maybe<Scalars['String']>;
};

export type FacebookPageInfo = {
  __typename?: 'FacebookPageInfo';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<FacebookProfilePicture>;
  instagram_business_account?: Maybe<InstagramAccount>;
};

export type FacebookPageStats = {
  __typename?: 'FacebookPageStats';
  _id?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
  /** @deprecated Use profileId instead */
  pageId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  change?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostAverages>;
  page_impressions?: Maybe<Scalars['Int']>;
  page_post_engagements?: Maybe<Scalars['Int']>;
  page_total_actions?: Maybe<Scalars['Int']>;
  page_video_views?: Maybe<Scalars['Int']>;
  page_actions_post_reactionsTotal?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_anger_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_haha_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_like_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_love_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_sorry_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_wow_total?: Maybe<Scalars['Int']>;
  page_positive_feedbackTotal?: Maybe<Scalars['Int']>;
  page_negative_feedback_by_type?: Maybe<Page_Negative_Feedback_By_Type>;
  page_negative_feedbackTotal?: Maybe<Scalars['Int']>;
  page_positive_feedback_by_type?: Maybe<Page_Positive_Feedback_By_Type>;
  page_call_phone_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_content_activity?: Maybe<Scalars['Int']>;
  page_content_activity_by_action_type_unique?: Maybe<FacebookActionStats>;
  page_get_directions_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_places_checkin_mobile?: Maybe<Scalars['Int']>;
  page_posts_impressions?: Maybe<Scalars['Int']>;
  page_views_by_profile_tab_total?: Maybe<FacebookTabStats>;
  page_views_total?: Maybe<Scalars['Int']>;
  page_website_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  post_activity_unique?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  page_fans?: Maybe<Scalars['Int']>;
  page_fans_locale?: Maybe<Scalars['JSObject']>;
  page_fans_city?: Maybe<Scalars['JSObject']>;
  page_fans_country?: Maybe<Scalars['JSObject']>;
  page_fans_gender?: Maybe<AudienceGender>;
  page_fans_age?: Maybe<Scalars['JSObject']>;
  page_fan_adds?: Maybe<Scalars['Int']>;
  page_fan_adds_unique?: Maybe<Scalars['Int']>;
  page_fans_by_like_source?: Maybe<Scalars['JSObject']>;
  page_fans_by_like_source_unique?: Maybe<Scalars['JSObject']>;
  page_fan_removes?: Maybe<Scalars['Int']>;
  page_fan_removes_unique?: Maybe<Scalars['Int']>;
  page_fans_by_unlike_source_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_gender_unique?: Maybe<AudienceGender>;
  page_content_activity_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_gender_unique?: Maybe<AudienceGender>;
  page_impressions_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_cta_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_call_phone_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_get_directions_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_website_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_gender?: Maybe<AudienceGender>;
  page_places_checkins_by_age?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_locale?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_country?: Maybe<Scalars['JSObject']>;
  page_fans_netChange?: Maybe<Scalars['Int']>;
  page_fans_bySource?: Maybe<FacebookFanSource>;
  page_tab_clicksTotal?: Maybe<Scalars['Int']>;
  topGender?: Maybe<TopDemographic>;
  topCity?: Maybe<TopDemographic>;
};

export type FacebookPost = {
  __typename?: 'FacebookPost';
  _id: Scalars['ObjectId'];
  /** @deprecated Use profileId instead */
  pageId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  created_time?: Maybe<Scalars['Date']>;
  full_picture?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  instagram_eligibility?: Maybe<Scalars['String']>;
  is_eligible_for_promotion?: Maybe<Scalars['Boolean']>;
  is_expired?: Maybe<Scalars['Boolean']>;
  is_hidden?: Maybe<Scalars['Boolean']>;
  is_instagram_eligible?: Maybe<Scalars['Boolean']>;
  is_popular?: Maybe<Scalars['Boolean']>;
  is_published?: Maybe<Scalars['Boolean']>;
  is_spherical?: Maybe<Scalars['Boolean']>;
  permalink_url?: Maybe<Scalars['String']>;
  privacy?: Maybe<FacebookPrivacy>;
  promotable_id?: Maybe<Scalars['String']>;
  status_type?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  story_tags?: Maybe<Array<Maybe<FacebookTag>>>;
  message_tags?: Maybe<Array<Maybe<FacebookTag>>>;
  subscribed?: Maybe<Scalars['Boolean']>;
  updated_time?: Maybe<Scalars['Date']>;
  video_buying_eligibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  insights?: Maybe<FacebookPostInsights>;
  marketingInsights?: Maybe<FacebookMarketingInsights>;
  message?: Maybe<Scalars['String']>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  archived_at?: Maybe<Scalars['Date']>;
  latest_record?: Maybe<FacebookPost>;
  timestamp?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<FacebookPostHistorical>>>;
};

export type FacebookPostAverages = {
  __typename?: 'FacebookPostAverages';
  post_reactionsTotal?: Maybe<Scalars['Float']>;
  post_clicks?: Maybe<Scalars['Float']>;
  post_activity?: Maybe<Scalars['Float']>;
  post_activity_percentageOfReach?: Maybe<Scalars['Float']>;
  post_engaged_users?: Maybe<Scalars['Float']>;
  post_engaged_users_percentageOfReach?: Maybe<Scalars['Float']>;
  post_impressions?: Maybe<Scalars['Float']>;
  post_impressions_unique?: Maybe<Scalars['Float']>;
  post_reactions_like_total?: Maybe<Scalars['Float']>;
};

export type FacebookPostHistorical = {
  __typename?: 'FacebookPostHistorical';
  id?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  insights?: Maybe<FacebookPostInsights>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type FacebookPostInsights = {
  __typename?: 'FacebookPostInsights';
  post_engaged_users?: Maybe<Scalars['Int']>;
  post_engaged_fan?: Maybe<Scalars['Int']>;
  post_clicks?: Maybe<Scalars['Int']>;
  post_clicks_unique?: Maybe<Scalars['Int']>;
  post_impressions?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  post_impressions_fan?: Maybe<Scalars['Int']>;
  post_impressions_fan_unique?: Maybe<Scalars['Int']>;
  post_impressions_organic?: Maybe<Scalars['Int']>;
  post_impressions_organic_unique?: Maybe<Scalars['Int']>;
  post_impressions_nonviral?: Maybe<Scalars['Int']>;
  post_impressions_nonviral_unique?: Maybe<Scalars['Int']>;
  post_reactions_like_total?: Maybe<Scalars['Int']>;
  post_activity?: Maybe<Scalars['Int']>;
  post_activity_unique?: Maybe<Scalars['Int']>;
  post_clicks_by_type?: Maybe<Scalars['JSObject']>;
  post_clicks_by_type_unique?: Maybe<Scalars['JSObject']>;
  post_reactions_by_type_total?: Maybe<Scalars['JSObject']>;
  post_activity_by_action_type?: Maybe<Scalars['JSObject']>;
  post_activity_by_action_type_unique?: Maybe<Scalars['JSObject']>;
  post_reactionsTotal?: Maybe<Scalars['Int']>;
};

export type FacebookPostStats = {
  __typename?: 'FacebookPostStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Int']>;
  post_activity?: Maybe<MetricSummary>;
  post_activity_percentageOfReach?: Maybe<MetricSummary>;
  post_activity_unique?: Maybe<MetricSummary>;
  post_engaged_users?: Maybe<MetricSummary>;
  post_engaged_users_percentageOfReach?: Maybe<MetricSummary>;
  post_impressions?: Maybe<MetricSummary>;
  post_impressions_unique?: Maybe<MetricSummary>;
  post_reactions_like_total?: Maybe<MetricSummary>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FacebookPrivacy = {
  __typename?: 'FacebookPrivacy';
  allow?: Maybe<Scalars['String']>;
  deny?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  friends?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type FacebookProfile = {
  __typename?: 'FacebookProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  tokenUserId?: Maybe<Scalars['ObjectId']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  facebookVideoStats?: Maybe<FacebookVideoStats>;
  facebookReelStats?: Maybe<FacebookVideoStats>;
  historicalFacebookStats?: Maybe<Array<Maybe<FacebookPageStats>>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  rankedFacebookVideos?: Maybe<RankedFacebookVideos>;
  rankedFacebookReels?: Maybe<RankedFacebookVideos>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  latestFacebookVideos?: Maybe<Array<FacebookVideo>>;
  latestFacebookReels?: Maybe<Array<FacebookVideo>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  facebookVideoTrends?: Maybe<FacebookVideoTrends>;
  facebookReelTrends?: Maybe<FacebookVideoTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  rankedInstagramStories?: Maybe<RankedInstagramStories>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  picture?: Maybe<FacebookProfilePicture>;
  about?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  category_list?: Maybe<Array<Maybe<FacebookPageCategory>>>;
  engagement?: Maybe<FacebookPageEngagement>;
  website?: Maybe<Scalars['String']>;
};

export type FacebookProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FacebookProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FacebookProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type FacebookProfileInput = {
  pageId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FacebookProfilePicture = {
  __typename?: 'FacebookProfilePicture';
  data?: Maybe<FacebookProfilePictureData>;
};

export type FacebookProfilePictureData = {
  __typename?: 'FacebookProfilePictureData';
  url?: Maybe<Scalars['String']>;
};

export type FacebookTabStats = {
  __typename?: 'FacebookTabStats';
  HOME?: Maybe<Scalars['Int']>;
};

export type FacebookTag = {
  __typename?: 'FacebookTag';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type FacebookUser = {
  __typename?: 'FacebookUser';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type FacebookVideo = {
  __typename?: 'FacebookVideo';
  _id: Scalars['ObjectId'];
  profileId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  post_id?: Maybe<Scalars['String']>;
  created_time?: Maybe<Scalars['Date']>;
  icon?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  permalink_url?: Maybe<Scalars['String']>;
  privacy?: Maybe<FacebookPrivacy>;
  published?: Maybe<Scalars['Boolean']>;
  updated_time?: Maybe<Scalars['Date']>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  archived_at?: Maybe<Scalars['Date']>;
  marketingInsights?: Maybe<FacebookMarketingInsights>;
  content_category?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  post_views?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  video_insights?: Maybe<FacebookVideoInsights>;
  isFacebookReel?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<FacebookVideoHistorical>>>;
};

export type FacebookVideoAverages = {
  __typename?: 'FacebookVideoAverages';
  date?: Maybe<Scalars['Date']>;
  views?: Maybe<Scalars['Float']>;
  video_insights?: Maybe<FacebookVideoInsightAverages>;
};

export type FacebookVideoHistorical = {
  __typename?: 'FacebookVideoHistorical';
  id?: Maybe<Scalars['String']>;
  pageId?: Maybe<Scalars['String']>;
  video_insights?: Maybe<FacebookVideoInsights>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type FacebookVideoInsightAverages = {
  __typename?: 'FacebookVideoInsightAverages';
  post_impressions_unique?: Maybe<Scalars['Float']>;
  post_video_view_time?: Maybe<Scalars['Float']>;
  post_video_avg_time_watched?: Maybe<Scalars['Float']>;
  reel_engagementTotal?: Maybe<Scalars['Float']>;
  total_video_impressions?: Maybe<Scalars['Float']>;
  total_video_impressions_unique?: Maybe<Scalars['Float']>;
  total_video_view_total_time?: Maybe<Scalars['Float']>;
  video_reactionsTotal?: Maybe<Scalars['Float']>;
};

export type FacebookVideoInsights = {
  __typename?: 'FacebookVideoInsights';
  views?: Maybe<Scalars['Int']>;
  total_video_ad_break_ad_cpm?: Maybe<Scalars['Int']>;
  total_video_ad_break_ad_impressions?: Maybe<Scalars['Int']>;
  total_video_ad_break_earnings?: Maybe<Scalars['Int']>;
  blue_reels_play_count?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  post_video_avg_time_watched?: Maybe<Scalars['Int']>;
  post_video_likes_by_reaction_type?: Maybe<Scalars['JSObject']>;
  post_video_social_actions?: Maybe<Scalars['JSObject']>;
  post_video_view_time?: Maybe<Scalars['Int']>;
  reel_engagementTotal?: Maybe<Scalars['Int']>;
  total_video_views?: Maybe<Scalars['Int']>;
  total_video_views_unique?: Maybe<Scalars['Int']>;
  total_video_views_autoplayed?: Maybe<Scalars['Int']>;
  total_video_views_clicked_to_play?: Maybe<Scalars['Int']>;
  total_video_views_organic?: Maybe<Scalars['Int']>;
  total_video_views_organic_unique?: Maybe<Scalars['Int']>;
  total_video_views_paid?: Maybe<Scalars['Int']>;
  total_video_views_paid_unique?: Maybe<Scalars['Int']>;
  total_video_views_sound_on?: Maybe<Scalars['Int']>;
  total_video_complete_views?: Maybe<Scalars['Int']>;
  total_video_complete_views_unique?: Maybe<Scalars['Int']>;
  total_video_complete_views_auto_played?: Maybe<Scalars['Int']>;
  total_video_complete_views_clicked_to_play?: Maybe<Scalars['Int']>;
  total_video_complete_views_organic?: Maybe<Scalars['Int']>;
  total_video_complete_views_organic_unique?: Maybe<Scalars['Int']>;
  total_video_complete_views_paid?: Maybe<Scalars['Int']>;
  total_video_complete_views_paid_unique?: Maybe<Scalars['Int']>;
  total_video_10s_views?: Maybe<Scalars['Int']>;
  total_video_10s_views_unique?: Maybe<Scalars['Int']>;
  total_video_10s_views_auto_played?: Maybe<Scalars['Int']>;
  total_video_10s_views_clicked_to_play?: Maybe<Scalars['Int']>;
  total_video_10s_views_organic?: Maybe<Scalars['Int']>;
  total_video_10s_views_paid?: Maybe<Scalars['Int']>;
  total_video_10s_views_sound_on?: Maybe<Scalars['Int']>;
  total_video_15s_views?: Maybe<Scalars['Int']>;
  total_video_60s_excludes_shorter_views?: Maybe<Scalars['Int']>;
  total_video_retention_graph?: Maybe<Scalars['JSObject']>;
  total_video_retention_graph_autoplayed?: Maybe<Scalars['JSObject']>;
  total_video_retention_graph_clicked_to_play?: Maybe<Scalars['JSObject']>;
  total_video_avg_time_watched?: Maybe<Scalars['Int']>;
  total_video_view_total_time?: Maybe<Scalars['BigInt']>;
  total_video_view_total_time_organic?: Maybe<Scalars['BigInt']>;
  total_video_view_total_time_paid?: Maybe<Scalars['BigInt']>;
  total_video_impressions?: Maybe<Scalars['Int']>;
  total_video_impressions_unique?: Maybe<Scalars['Int']>;
  total_video_impressions_paid_unique?: Maybe<Scalars['Int']>;
  total_video_impressions_paid?: Maybe<Scalars['Int']>;
  total_video_impressions_organic_unique?: Maybe<Scalars['Int']>;
  total_video_impressions_organic?: Maybe<Scalars['Int']>;
  total_video_impressions_viral_unique?: Maybe<Scalars['Int']>;
  total_video_impressions_viral?: Maybe<Scalars['Int']>;
  total_video_impressions_fan_unique?: Maybe<Scalars['Int']>;
  total_video_impressions_fan?: Maybe<Scalars['Int']>;
  total_video_impressions_fan_paid_unique?: Maybe<Scalars['Int']>;
  total_video_impressions_fan_paid?: Maybe<Scalars['Int']>;
  total_video_stories_by_action_type?: Maybe<Scalars['JSObject']>;
  total_video_reactions_by_type_total?: Maybe<Scalars['JSObject']>;
  total_video_view_time_by_age_bucket_and_gender?: Maybe<Scalars['JSObject']>;
  total_video_view_time_by_region_id?: Maybe<Scalars['JSObject']>;
  total_video_views_by_distribution_type?: Maybe<Scalars['JSObject']>;
  total_video_view_time_by_distribution_type?: Maybe<Scalars['JSObject']>;
  video_reactionsTotal?: Maybe<Scalars['Int']>;
};

export type FacebookVideoStats = {
  __typename?: 'FacebookVideoStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Int']>;
  views?: Maybe<MetricSummary>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  post_impressions_unique?: Maybe<MetricSummary>;
  post_video_view_time?: Maybe<MetricSummary>;
  post_video_avg_time_watched?: Maybe<MetricSummary>;
  reel_engagementTotal?: Maybe<MetricSummary>;
  total_video_impressions?: Maybe<MetricSummary>;
  total_video_impressions_unique?: Maybe<MetricSummary>;
  total_video_view_total_time?: Maybe<MetricSummary>;
  video_reactionsTotal?: Maybe<MetricSummary>;
};

export type FacebookVideoTrends = {
  __typename?: 'FacebookVideoTrends';
  dailyAverages?: Maybe<Array<Maybe<FacebookVideoAverages>>>;
  recentAverages?: Maybe<FacebookVideoAverages>;
  comparisonAverages?: Maybe<FacebookVideoAverages>;
};

export type FacebookWeeklyStats = {
  __typename?: 'FacebookWeeklyStats';
  _id?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
  /** @deprecated Use profileId instead */
  pageId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  change?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostAverages>;
  page_impressions?: Maybe<Scalars['Int']>;
  page_post_engagements?: Maybe<Scalars['Int']>;
  page_total_actions?: Maybe<Scalars['Int']>;
  page_video_views?: Maybe<Scalars['Int']>;
  page_actions_post_reactionsTotal?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_anger_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_haha_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_like_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_love_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_sorry_total?: Maybe<Scalars['Int']>;
  page_actions_post_reactions_wow_total?: Maybe<Scalars['Int']>;
  page_positive_feedbackTotal?: Maybe<Scalars['Int']>;
  page_negative_feedback_by_type?: Maybe<Page_Negative_Feedback_By_Type>;
  page_negative_feedbackTotal?: Maybe<Scalars['Int']>;
  page_positive_feedback_by_type?: Maybe<Page_Positive_Feedback_By_Type>;
  page_call_phone_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_content_activity?: Maybe<Scalars['Int']>;
  page_content_activity_by_action_type_unique?: Maybe<FacebookActionStats>;
  page_get_directions_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  page_places_checkin_mobile?: Maybe<Scalars['Int']>;
  page_posts_impressions?: Maybe<Scalars['Int']>;
  page_views_by_profile_tab_total?: Maybe<FacebookTabStats>;
  page_views_total?: Maybe<Scalars['Int']>;
  page_website_clicks_logged_in_unique?: Maybe<Scalars['Int']>;
  post_activity_unique?: Maybe<Scalars['Int']>;
  post_impressions_unique?: Maybe<Scalars['Int']>;
  page_fans?: Maybe<Scalars['Int']>;
  page_fans_locale?: Maybe<Scalars['JSObject']>;
  page_fans_city?: Maybe<Scalars['JSObject']>;
  page_fans_country?: Maybe<Scalars['JSObject']>;
  page_fans_gender?: Maybe<AudienceGender>;
  page_fans_age?: Maybe<Scalars['JSObject']>;
  page_fan_adds?: Maybe<Scalars['Int']>;
  page_fan_adds_unique?: Maybe<Scalars['Int']>;
  page_fans_by_like_source?: Maybe<Scalars['JSObject']>;
  page_fans_by_like_source_unique?: Maybe<Scalars['JSObject']>;
  page_fan_removes?: Maybe<Scalars['Int']>;
  page_fan_removes_unique?: Maybe<Scalars['Int']>;
  page_fans_by_unlike_source_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_gender_unique?: Maybe<AudienceGender>;
  page_content_activity_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_content_activity_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_gender_unique?: Maybe<AudienceGender>;
  page_impressions_by_age_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_impressions_by_country_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_cta_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_cta_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_call_phone_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_call_phone_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_get_directions_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_get_directions_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_gender_logged_in_unique?: Maybe<AudienceGender>;
  page_website_clicks_by_age_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_logged_in_by_city_unique?: Maybe<Scalars['JSObject']>;
  page_website_clicks_by_country_logged_in_unique?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_gender?: Maybe<AudienceGender>;
  page_places_checkins_by_age?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_locale?: Maybe<Scalars['JSObject']>;
  page_places_checkins_by_country?: Maybe<Scalars['JSObject']>;
  page_fans_netChange?: Maybe<Scalars['Int']>;
  page_fans_bySource?: Maybe<FacebookFanSource>;
  page_tab_clicksTotal?: Maybe<Scalars['Int']>;
  topGender?: Maybe<TopDemographic>;
  topCity?: Maybe<TopDemographic>;
  order?: Maybe<Scalars['Int']>;
  latestRecordedDate?: Maybe<Scalars['Date']>;
  dayDirectory?: Maybe<Scalars['JSObject']>;
  days?: Maybe<Array<Maybe<FacebookPageStats>>>;
  archived_at?: Maybe<Scalars['Date']>;
};

export type FeatureUsage = {
  __typename?: 'FeatureUsage';
  count?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type FeaturedInsightField = {
  __typename?: 'FeaturedInsightField';
  key?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type FeaturedInsightInput = {
  key?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type FeaturedInsightsContent = {
  __typename?: 'FeaturedInsightsContent';
  type?: Maybe<ElementType>;
  featured_insights_content?: Maybe<Array<Maybe<FeaturedInsightField>>>;
};

export type FileInput = {
  title?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  link?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type FilesContent = {
  __typename?: 'FilesContent';
  type?: Maybe<ElementType>;
  file_content?: Maybe<Array<Maybe<LinkPair>>>;
};

export type FloatRange = {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type FollowerBracketPerformance = {
  __typename?: 'FollowerBracketPerformance';
  key?: Maybe<Scalars['Int']>;
  followersMax?: Maybe<Scalars['Int']>;
  followersMin?: Maybe<Scalars['Int']>;
  average?: Maybe<Scalars['Float']>;
  profilesCount?: Maybe<Scalars['Float']>;
  media?: Maybe<MediaPerformanceByType>;
  instagramFeed?: Maybe<MediaPerformanceByType>;
  instagramReels?: Maybe<MediaPerformanceByType>;
  stories?: Maybe<MediaPerformanceByType>;
};

export type GalleryContent = {
  __typename?: 'GalleryContent';
  type?: Maybe<ElementType>;
  gallery_content?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GalleryInput = {
  link?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
};

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Unspecified = 'UNSPECIFIED'
}

export type GenderGroup = {
  __typename?: 'GenderGroup';
  gender?: Maybe<Gender>;
  viewerPercentage?: Maybe<Scalars['String']>;
};

export type ImageContent = {
  __typename?: 'ImageContent';
  type?: Maybe<ElementType>;
  image_content?: Maybe<Scalars['String']>;
};

export type ImageListContent = {
  __typename?: 'ImageListContent';
  type?: Maybe<ElementType>;
  image_list_content?: Maybe<Array<Maybe<ImageListField>>>;
};

export type ImageListField = {
  __typename?: 'ImageListField';
  url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  compressedImageFile?: Maybe<ImageListField>;
};

export type ImageListInput = {
  url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  file?: Maybe<Scalars['Upload']>;
  type?: Maybe<Scalars['String']>;
  compressedFile?: Maybe<Scalars['Upload']>;
  compressedImageFile?: Maybe<CompressedImageInput>;
};

export type InfluencerPlan = {
  __typename?: 'InfluencerPlan';
  _id: Scalars['ObjectId'];
  code?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type InfluencerPlanUpdates = {
  code?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type InfluencerQuery = {
  searchBar?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile_type?: Maybe<Scalars['String']>;
  true_reach?: Maybe<Scalars['Float']>;
  top_country?: Maybe<Scalars['String']>;
  top_age?: Maybe<Scalars['String']>;
  top_gender?: Maybe<Scalars['String']>;
  engagement?: Maybe<Scalars['Float']>;
  agent?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type InsightIqAffinityEntry = {
  __typename?: 'InsightIqAffinityEntry';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type InsightIqAudienceLikes = {
  __typename?: 'InsightIqAudienceLikes';
  credibility?: Maybe<InsightIqAudienceLikesRange>;
};

export type InsightIqAudienceLikesRange = {
  __typename?: 'InsightIqAudienceLikesRange';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  total_profile_count?: Maybe<Scalars['Int']>;
};

export type InsightIqAudienceLocationFilter = {
  location_id?: Maybe<Scalars['String']>;
  percentage_value?: Maybe<Scalars['Float']>;
  operator?: Maybe<InsightIqFilterOperator>;
};

export type InsightIqAutocompleteSearchProfile = {
  __typename?: 'InsightIqAutocompleteSearchProfile';
  user_id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  followers?: Maybe<Scalars['Int']>;
  subscriber_count?: Maybe<Scalars['Int']>;
  is_verified?: Maybe<Scalars['Boolean']>;
};

export type InsightIqAutocompleteSearchResult = {
  __typename?: 'InsightIqAutocompleteSearchResult';
  id?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  autocompleteSearchProfile?: Maybe<InsightIqAutocompleteSearchProfile>;
  talentNetworkResult?: Maybe<ProfileBrowseResult>;
};

export enum InsightIqAvailableChannel {
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE',
  Twitter = 'TWITTER',
  Twitch = 'TWITCH',
  Facebook = 'FACEBOOK',
  Substack = 'SUBSTACK',
  Shopify = 'SHOPIFY',
  Etsy = 'ETSY',
  Stripe = 'STRIPE'
}

export type InsightIqCityEntry = {
  __typename?: 'InsightIqCityEntry';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type InsightIqContactDetails = {
  __typename?: 'InsightIqContactDetails';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type InsightIqContactFilter = {
  type?: Maybe<InsightIqContactType>;
  preference?: Maybe<InsightIqContactPreference>;
};

export enum InsightIqContactPreference {
  MustHave = 'MUST_HAVE',
  ShouldHave = 'SHOULD_HAVE'
}

export enum InsightIqContactType {
  Bbm = 'BBM',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Itunes = 'ITUNES',
  Kakao = 'KAKAO',
  Kik = 'KIK',
  Lineid = 'LINEID',
  Linktree = 'LINKTREE',
  Phone = 'PHONE',
  Pinterest = 'PINTEREST',
  Sarahah = 'SARAHAH',
  Sayat = 'SAYAT',
  Skype = 'SKYPE',
  Snapchat = 'SNAPCHAT',
  Telegram = 'TELEGRAM',
  Tiktok = 'TIKTOK',
  Tumblr = 'TUMBLR',
  Twitchtv = 'TWITCHTV',
  Twitter = 'TWITTER',
  Viber = 'VIBER',
  Vk = 'VK',
  Wechat = 'WECHAT',
  Weibo = 'WEIBO',
  Whatsapp = 'WHATSAPP',
  Youtube = 'YOUTUBE'
}

export type InsightIqContentEngagement = {
  __typename?: 'InsightIqContentEngagement';
  like_count?: Maybe<Scalars['Int']>;
  comment_count?: Maybe<Scalars['Int']>;
};

export enum InsightIqContentType {
  Video = 'VIDEO',
  Post = 'POST',
  Story = 'STORY',
  Tweet = 'TWEET',
  Blog = 'BLOG',
  Image = 'IMAGE',
  Thread = 'THREAD',
  Podcast = 'PODCAST',
  Track = 'TRACK',
  Other = 'OTHER',
  Reels = 'REELS',
  Stream = 'STREAM',
  Feed = 'FEED',
  Igtv = 'IGTV',
  Photo = 'PHOTO'
}

export type InsightIqCountryEntry = {
  __typename?: 'InsightIqCountryEntry';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export enum InsightIqCreatorGenderOptions {
  Any = 'ANY',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type InsightIqCreatorProfileBasicDetails = {
  __typename?: 'InsightIqCreatorProfileBasicDetails';
  platform_username?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  follower_count?: Maybe<Scalars['Int']>;
  subscriber_count?: Maybe<Scalars['Int']>;
  is_verified?: Maybe<Scalars['Boolean']>;
};

export type InsightIqDiscoveryAnalyticsProfile = {
  __typename?: 'InsightIqDiscoveryAnalyticsProfile';
  platform_username?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  follower_count?: Maybe<Scalars['Int']>;
  subscriber_count?: Maybe<Scalars['Int']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  full_name?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  platform_account_type?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  age_group?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  average_likes?: Maybe<Scalars['Int']>;
  average_comments?: Maybe<Scalars['Int']>;
  average_views?: Maybe<Scalars['Int']>;
  average_reels_views?: Maybe<Scalars['Int']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  content_count?: Maybe<Scalars['Int']>;
  sponsored_posts_performance?: Maybe<Scalars['Float']>;
  reputation_history?: Maybe<Array<Maybe<InsightIqReputationMonth>>>;
  location?: Maybe<InsightIqLocation>;
  top_hashtags?: Maybe<Array<Maybe<InsightIqMentionEntry>>>;
  top_mentions?: Maybe<Array<Maybe<InsightIqMentionEntry>>>;
  top_interests?: Maybe<Array<Maybe<InsightIqMentionEntry>>>;
  brand_affinity?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  top_contents?: Maybe<Array<Maybe<InsightIqProfileContent>>>;
  recent_contents?: Maybe<Array<Maybe<InsightIqProfileContent>>>;
  posts_hidden_likes_percentage_value?: Maybe<Scalars['Float']>;
  sponsored_contents?: Maybe<Array<Maybe<InsightIqProfileContent>>>;
  lookalikes?: Maybe<Array<Maybe<InsightIqCreatorProfileBasicDetails>>>;
  audience?: Maybe<InsightIqFollowerInsights>;
  engagement_rate_histogram?: Maybe<Array<Maybe<InsightIqEngagementRateHistogram>>>;
  audience_likes?: Maybe<Array<Maybe<InsightIqAudienceLikes>>>;
  audience_likers?: Maybe<InsightIqLikerInsights>;
  contact_details?: Maybe<Array<Maybe<InsightIqContactDetails>>>;
};

export type InsightIqDiscoveryAnalyticsResult = {
  __typename?: 'InsightIqDiscoveryAnalyticsResult';
  work_platform?: Maybe<InsightIqWorkPlatform>;
  profile?: Maybe<InsightIqDiscoveryAnalyticsProfile>;
  lookalikesOnKitly?: Maybe<Array<Maybe<Profile>>>;
};

export type InsightIqDiscoveryMetadata = {
  __typename?: 'InsightIqDiscoveryMetadata';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total_results?: Maybe<Scalars['Int']>;
};

export type InsightIqDiscoverySearchFilter = {
  follower_count?: Maybe<InsightIqRangeFilter>;
  subscriber_count?: Maybe<InsightIqRangeFilter>;
  content_count?: Maybe<InsightIqRangeFilter>;
  audience_gender?: Maybe<InsightIqGenderFilter>;
  creator_gender?: Maybe<InsightIqCreatorGenderOptions>;
  audience_age?: Maybe<InsightIqRangeFilter>;
  creator_age?: Maybe<InsightIqRangeFilter>;
  description_keywords?: Maybe<Scalars['String']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  has_contact_details?: Maybe<Scalars['Boolean']>;
  specific_contact_details?: Maybe<Array<Maybe<InsightIqContactFilter>>>;
  audience_language?: Maybe<Array<Maybe<InsightIqLanguagePercentageFilter>>>;
  creator_language?: Maybe<InsightIqLanguagePercentageFilter>;
  audience_interests?: Maybe<Array<Maybe<Scalars['String']>>>;
  audience_interest_affinities?: Maybe<Array<Maybe<InsightIqInterestAffinityFilter>>>;
  creator_interests?: Maybe<Array<Maybe<Scalars['String']>>>;
  audience_brand_affinities?: Maybe<Array<Maybe<Scalars['String']>>>;
  creator_brand_affinities?: Maybe<Array<Maybe<Scalars['String']>>>;
  average_likes?: Maybe<InsightIqRangeFilter>;
  average_views?: Maybe<InsightIqRangeFilter>;
  engagement_rate?: Maybe<InsightIqPercentageFilter>;
  has_sponsored_posts?: Maybe<Scalars['Boolean']>;
  brand_sponsors?: Maybe<Array<Maybe<Scalars['String']>>>;
  instagram_options?: Maybe<InsightIqInstagramFilter>;
  audience_locations?: Maybe<Array<Maybe<InsightIqAudienceLocationFilter>>>;
  creator_locations?: Maybe<Array<Maybe<Scalars['String']>>>;
  follower_growth?: Maybe<InsightIqFollowerGrowthFilter>;
  subscriber_growth?: Maybe<InsightIqFollowerGrowthFilter>;
  bio_phrase?: Maybe<Scalars['String']>;
  hashtags?: Maybe<Array<Maybe<InsightIqHashtagFilter>>>;
  mentions?: Maybe<Array<Maybe<InsightIqMentionFilter>>>;
  topic_relevance?: Maybe<InsightIqTopicFilter>;
  audience_lookalikes?: Maybe<Scalars['String']>;
  platform_account_type?: Maybe<InsightIqPlatformAccountType>;
  creator_account_type?: Maybe<InsightIqPlatformAccountType>;
  creator_lookalikes?: Maybe<Scalars['String']>;
  creator_location?: Maybe<Array<Maybe<Scalars['String']>>>;
  audience_location?: Maybe<Array<Maybe<InsightIqLocationFilter>>>;
  sort_by?: Maybe<InsightIqSortFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type InsightIqDiscoverySearchProfile = {
  __typename?: 'InsightIqDiscoverySearchProfile';
  platform_username?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  follower_count?: Maybe<Scalars['Int']>;
  subscriber_count?: Maybe<Scalars['Int']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  work_platform?: Maybe<InsightIqWorkPlatform>;
  full_name?: Maybe<Scalars['String']>;
  introduction?: Maybe<Scalars['String']>;
  platform_account_type?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  age_group?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  content_count?: Maybe<Scalars['Int']>;
  engagement_rate?: Maybe<Scalars['Float']>;
  average_likes?: Maybe<Scalars['Int']>;
  average_views?: Maybe<Scalars['Int']>;
  location?: Maybe<InsightIqLocation>;
  contact_details?: Maybe<Array<Maybe<InsightIqContactDetails>>>;
};

export type InsightIqDiscoverySearchResult = {
  __typename?: 'InsightIqDiscoverySearchResult';
  data?: Maybe<Array<Maybe<InsightIqDiscoverySearchProfile>>>;
  metadata?: Maybe<InsightIqDiscoveryMetadata>;
};

export type InsightIqEngagementRateHistogram = {
  __typename?: 'InsightIqEngagementRateHistogram';
  engagement_rate_band?: Maybe<InsightIqMetricRange>;
};

export enum InsightIqFilterOperator {
  Gt = 'GT',
  Lt = 'LT'
}

export type InsightIqFollowerEntry = {
  __typename?: 'InsightIqFollowerEntry';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type InsightIqFollowerGrowthFilter = {
  interval?: Maybe<Scalars['Int']>;
  interval_unit?: Maybe<InsightIqIntervalUnit>;
  operator?: Maybe<InsightIqFilterOperator>;
  percentage_value?: Maybe<Scalars['Int']>;
};

export type InsightIqFollowerInsights = {
  __typename?: 'InsightIqFollowerInsights';
  ethnicities?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  languages?: Maybe<Array<Maybe<InsightIqLanguagesEntry>>>;
  brand_affinity?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  interests?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  follower_types?: Maybe<Array<Maybe<InsightIqFollowerEntry>>>;
  lookalikes?: Maybe<Array<Maybe<InsightIqCreatorProfileBasicDetails>>>;
  credibility_score?: Maybe<Scalars['Float']>;
  credibility_score_band?: Maybe<InsightIqAudienceLikesRange>;
  significant_followers_percentage?: Maybe<Scalars['Float']>;
  significant_followers?: Maybe<Array<Maybe<InsightIqCreatorProfileBasicDetails>>>;
  countries?: Maybe<Array<Maybe<InsightIqCountryEntry>>>;
  cities?: Maybe<Array<Maybe<InsightIqCityEntry>>>;
  gender_age_distribution?: Maybe<Array<Maybe<InsightIqGenderAgeEntry>>>;
};

export type InsightIqGenderAgeEntry = {
  __typename?: 'InsightIqGenderAgeEntry';
  gender?: Maybe<InsightIqGenderOptions>;
  age_range?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type InsightIqGenderFilter = {
  type?: Maybe<InsightIqGenderOptions>;
  operator?: Maybe<InsightIqFilterOperator>;
  percentage_value?: Maybe<Scalars['Float']>;
};

export enum InsightIqGenderOptions {
  Any = 'ANY',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type InsightIqHashtagFilter = {
  name?: Maybe<Scalars['String']>;
};

export type InsightIqInstagramFilter = {
  reel_views?: Maybe<InsightIqRangeFilter>;
};

export type InsightIqInterestAffinityFilter = {
  value?: Maybe<Scalars['String']>;
  operator?: Maybe<InsightIqFilterOperator>;
  percentage_value?: Maybe<Scalars['Float']>;
};

export enum InsightIqIntervalUnit {
  Month = 'MONTH'
}

export type InsightIqLanguageFilter = {
  code?: Maybe<Scalars['String']>;
};

export type InsightIqLanguageOption = {
  __typename?: 'InsightIqLanguageOption';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type InsightIqLanguagePercentageFilter = {
  code?: Maybe<Scalars['String']>;
  operator?: Maybe<Scalars['String']>;
  percentage_value?: Maybe<Scalars['Float']>;
};

export type InsightIqLanguagesEntry = {
  __typename?: 'InsightIqLanguagesEntry';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type InsightIqLikerInsights = {
  __typename?: 'InsightIqLikerInsights';
  countries?: Maybe<Array<Maybe<InsightIqCountryEntry>>>;
  cities?: Maybe<Array<Maybe<InsightIqCityEntry>>>;
  gender_age_distribution?: Maybe<Array<Maybe<InsightIqGenderAgeEntry>>>;
  ethnicities?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  languages?: Maybe<Array<Maybe<InsightIqLanguagesEntry>>>;
  brand_affinity?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  interests?: Maybe<Array<Maybe<InsightIqAffinityEntry>>>;
  follower_types?: Maybe<Array<Maybe<InsightIqFollowerEntry>>>;
  lookalikes?: Maybe<Scalars['String']>;
  significant_likers_percentage?: Maybe<Scalars['Float']>;
  credibility_score?: Maybe<Scalars['Float']>;
  significant_likers?: Maybe<Array<Maybe<InsightIqCreatorProfileBasicDetails>>>;
};

export type InsightIqLocation = {
  __typename?: 'InsightIqLocation';
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type InsightIqLocationFilter = {
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  percentage_value?: Maybe<Scalars['Int']>;
  operator?: Maybe<InsightIqFilterOperator>;
};

export type InsightIqLocationOption = {
  __typename?: 'InsightIqLocationOption';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type InsightIqMentionEntry = {
  __typename?: 'InsightIqMentionEntry';
  name?: Maybe<Scalars['String']>;
};

export type InsightIqMentionFilter = {
  name?: Maybe<Scalars['String']>;
};

export type InsightIqMetricRange = {
  __typename?: 'InsightIqMetricRange';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type InsightIqPercentageFilter = {
  percentage_value?: Maybe<Scalars['Float']>;
};

export enum InsightIqPlatformAccountType {
  Personal = 'PERSONAL',
  Creator = 'CREATOR',
  Business = 'BUSINESS',
  Any = 'ANY'
}

export type InsightIqProductSupport = {
  __typename?: 'InsightIqProductSupport';
  is_supported?: Maybe<Scalars['Boolean']>;
  audience?: Maybe<InsightIqProductSupport>;
};

export type InsightIqProfileContent = {
  __typename?: 'InsightIqProfileContent';
  type?: Maybe<InsightIqContentType>;
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  engagement?: Maybe<InsightIqContentEngagement>;
  published_at?: Maybe<Scalars['String']>;
};

export type InsightIqQueryFieldOption = {
  __typename?: 'InsightIqQueryFieldOption';
  name?: Maybe<Scalars['String']>;
};

export type InsightIqRangeFilter = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type InsightIqReputationMonth = {
  __typename?: 'InsightIqReputationMonth';
  month?: Maybe<Scalars['String']>;
  follower_count?: Maybe<Scalars['Int']>;
  subscriber_count?: Maybe<Scalars['Int']>;
  following_count?: Maybe<Scalars['Int']>;
  average_likes?: Maybe<Scalars['Int']>;
};

export enum InsightIqSortField {
  AverageLikes = 'AVERAGE_LIKES',
  FollowerCount = 'FOLLOWER_COUNT',
  EngagementRate = 'ENGAGEMENT_RATE',
  Description = 'DESCRIPTION',
  AverageViews = 'AVERAGE_VIEWS',
  ContentCount = 'CONTENT_COUNT',
  ReelsViews = 'REELS_VIEWS',
  FollowerGrowth = 'FOLLOWER_GROWTH',
  TotalViewsGrowth = 'TOTAL_VIEWS_GROWTH',
  TotalLikesGrowth = 'TOTAL_LIKES_GROWTH',
  AudienceLocations = 'AUDIENCE_LOCATIONS',
  AudienceLanguage = 'AUDIENCE_LANGUAGE',
  AudienceBrandAffinities = 'AUDIENCE_BRAND_AFFINITIES',
  AudienceInterests = 'AUDIENCE_INTERESTS',
  AudienceAge = 'AUDIENCE_AGE',
  CreatorLookalikes = 'CREATOR_LOOKALIKES',
  AudienceLookalikes = 'AUDIENCE_LOOKALIKES',
  AverageLike = 'AVERAGE_LIKE',
  AudienceLocation = 'AUDIENCE_LOCATION'
}

export type InsightIqSortFilter = {
  field?: Maybe<InsightIqSortField>;
  order?: Maybe<InsightIqSortOrder>;
};

export enum InsightIqSortOrder {
  Descending = 'DESCENDING',
  Ascending = 'ASCENDING'
}

export type InsightIqTopicFilter = {
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  weight?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Float']>;
};

export type InsightIqWorkPlatform = {
  __typename?: 'InsightIqWorkPlatform';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  category?: Maybe<InsightIqWorkPlatformCategory>;
  status?: Maybe<InsightIqWorkPlatformStatus>;
  url?: Maybe<Scalars['String']>;
  products?: Maybe<InsightIqWorkPlatformProducts>;
};

export enum InsightIqWorkPlatformCategory {
  Media = 'MEDIA',
  Education = 'EDUCATION',
  Social = 'SOCIAL',
  Entertainment = 'ENTERTAINMENT',
  Lifestyle = 'LIFESTYLE',
  Business = 'BUSINESS'
}

export type InsightIqWorkPlatformProducts = {
  __typename?: 'InsightIqWorkPlatformProducts';
  identity?: Maybe<InsightIqProductSupport>;
  engagement?: Maybe<InsightIqProductSupport>;
  income?: Maybe<InsightIqProductSupport>;
  switch?: Maybe<InsightIqProductSupport>;
  activity?: Maybe<InsightIqProductSupport>;
  publish?: Maybe<InsightIqProductSupport>;
};

export enum InsightIqWorkPlatformStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type InstagramAccount = {
  __typename?: 'InstagramAccount';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  followers_count?: Maybe<Scalars['Int']>;
  followersCount?: Maybe<Scalars['Int']>;
};

export type InstagramAccountInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  followers_count?: Maybe<Scalars['Int']>;
};

export type InstagramChildMedia = {
  __typename?: 'InstagramChildMedia';
  id?: Maybe<Scalars['String']>;
  media_url?: Maybe<Scalars['String']>;
  media_type?: Maybe<InstagramMediaType>;
  username?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type InstagramCombinedAverages = {
  __typename?: 'InstagramCombinedAverages';
  date?: Maybe<Scalars['Date']>;
  like_count?: Maybe<Scalars['Float']>;
  comments_count?: Maybe<Scalars['Float']>;
  insights?: Maybe<InstagramCombinedInsightAverages>;
};

export type InstagramCombinedInsightAverages = {
  __typename?: 'InstagramCombinedInsightAverages';
  impressions?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  engagement_percentageOfReach?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Float']>;
  exits?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  taps_forward?: Maybe<Scalars['Float']>;
  taps_back?: Maybe<Scalars['Float']>;
  viewRate?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  plays?: Maybe<Scalars['Float']>;
  shares?: Maybe<Scalars['Float']>;
  total_interactions?: Maybe<Scalars['Float']>;
  total_interactions_percentageOfReach?: Maybe<Scalars['Float']>;
};

export type InstagramCombinedTrends = {
  __typename?: 'InstagramCombinedTrends';
  recentAverages?: Maybe<InstagramCombinedAverages>;
  comparisonAverages?: Maybe<InstagramCombinedAverages>;
};

export type InstagramHistorical = {
  __typename?: 'InstagramHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  start?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  profileId?: Maybe<Scalars['String']>;
  followers_count?: Maybe<Scalars['Int']>;
  follower_count?: Maybe<Scalars['Int']>;
  followers_lost?: Maybe<Scalars['Int']>;
  followers_net?: Maybe<Scalars['Int']>;
  follows_count?: Maybe<Scalars['Int']>;
  profile_views?: Maybe<Scalars['Int']>;
  email_contacts?: Maybe<Scalars['Int']>;
  profileRequestCount?: Maybe<Scalars['Int']>;
  get_directions_clicks?: Maybe<Scalars['Int']>;
  phone_call_clicks?: Maybe<Scalars['Int']>;
  text_message_clicks?: Maybe<Scalars['Int']>;
  website_clicks?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
  impressions?: Maybe<Scalars['Int']>;
  change?: Maybe<InstagramHistorical>;
  storyStats?: Maybe<InstagramStoryInsightAverages>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<InstagramPostInsightAverages>;
  instagramFeedStats?: Maybe<InstagramPostInsightAverages>;
  instagramReelStats?: Maybe<InstagramPostInsightAverages>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  ig_id?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Int']>;
  archived_at?: Maybe<Scalars['Date']>;
};

export type InstagramHistoricalStats = {
  __typename?: 'InstagramHistoricalStats';
  start?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  latestRecordedDate?: Maybe<Scalars['Date']>;
  dayDirectory?: Maybe<Scalars['JSObject']>;
  count?: Maybe<Scalars['Int']>;
  follower_count?: Maybe<MetricSummary>;
  followers_count?: Maybe<MetricSummary>;
  follows_count?: Maybe<MetricSummary>;
  media_count?: Maybe<MetricSummary>;
  profileRequestCount?: Maybe<MetricSummary>;
  email_contacts?: Maybe<MetricSummary>;
  profile_views?: Maybe<MetricSummary>;
  get_directions_clicks?: Maybe<MetricSummary>;
  phone_call_clicks?: Maybe<MetricSummary>;
  text_message_clicks?: Maybe<MetricSummary>;
  website_clicks?: Maybe<MetricSummary>;
  reach?: Maybe<MetricSummary>;
  impressions?: Maybe<MetricSummary>;
  storyStats?: Maybe<InstagramStoryInsightAverages>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<InstagramPostInsightAverages>;
  instagramFeedStats?: Maybe<InstagramPostInsightAverages>;
  instagramReelStats?: Maybe<InstagramPostInsightAverages>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
};

export type InstagramMarketingInsights = {
  __typename?: 'InstagramMarketingInsights';
  reach?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
};

export type InstagramMedia = {
  __typename?: 'InstagramMedia';
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  profileId?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  media_type?: Maybe<InstagramMediaType>;
  media_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  webhook_timestamp?: Maybe<Scalars['Date']>;
  insights?: Maybe<InstagramPostInsights>;
  marketingInsights?: Maybe<InstagramMarketingInsights>;
  like_count?: Maybe<Scalars['Int']>;
  comments_count?: Maybe<Scalars['Int']>;
  video_title?: Maybe<Scalars['String']>;
  media_product_type?: Maybe<Scalars['String']>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentioned?: Maybe<Array<Maybe<Scalars['String']>>>;
  taggedUsernames?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAd?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<InstagramChildMedia>>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  archived_at?: Maybe<Scalars['Date']>;
  latest_record?: Maybe<InstagramMedia>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<InstagramMediaHistorical>>>;
};

export type InstagramMediaAverages = {
  __typename?: 'InstagramMediaAverages';
  date?: Maybe<Scalars['Date']>;
  like_count?: Maybe<Scalars['Float']>;
  comments_count?: Maybe<Scalars['Float']>;
  insights?: Maybe<InstagramPostInsightAverages>;
  topHandles?: Maybe<Array<PerformanceReportMention>>;
  topHashtags?: Maybe<Array<PerformanceReportMention>>;
};

export type InstagramMediaHistorical = {
  __typename?: 'InstagramMediaHistorical';
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  like_count?: Maybe<Scalars['Float']>;
  comments_count?: Maybe<Scalars['Float']>;
  insights?: Maybe<InstagramPostInsights>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type InstagramMediaTrends = {
  __typename?: 'InstagramMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<InstagramMediaAverages>>>;
  recentAverages?: Maybe<InstagramMediaAverages>;
  comparisonAverages?: Maybe<InstagramMediaAverages>;
};

export enum InstagramMediaType {
  Image = 'IMAGE',
  CarouselAlbum = 'CAROUSEL_ALBUM',
  Video = 'VIDEO'
}

export type InstagramPostInsightAverages = {
  __typename?: 'InstagramPostInsightAverages';
  impressions?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  engagement_percentageOfReach?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Float']>;
  viewRate?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['Float']>;
  likes?: Maybe<Scalars['Float']>;
  plays?: Maybe<Scalars['Float']>;
  shares?: Maybe<Scalars['Float']>;
  total_interactions?: Maybe<Scalars['Float']>;
  total_interactions_percentageOfReach?: Maybe<Scalars['Float']>;
};

export type InstagramPostInsights = {
  __typename?: 'InstagramPostInsights';
  impressions?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
  engagement?: Maybe<Scalars['Int']>;
  engagement_percentageOfReach?: Maybe<Scalars['Float']>;
  saved?: Maybe<Scalars['Int']>;
  viewRate?: Maybe<Scalars['Float']>;
  video_views?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  total_interactions?: Maybe<Scalars['Int']>;
  total_interactions_percentageOfReach?: Maybe<Scalars['Float']>;
};

export type InstagramProfile = {
  __typename?: 'InstagramProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  tokenUserId?: Maybe<Scalars['ObjectId']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Int']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  facebookVideoStats?: Maybe<FacebookVideoStats>;
  facebookReelStats?: Maybe<FacebookVideoStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  rankedFacebookVideos?: Maybe<RankedFacebookVideos>;
  rankedFacebookReels?: Maybe<RankedFacebookVideos>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  latestFacebookVideos?: Maybe<Array<FacebookVideo>>;
  latestFacebookReels?: Maybe<Array<FacebookVideo>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  facebookVideoTrends?: Maybe<FacebookVideoTrends>;
  facebookReelTrends?: Maybe<FacebookVideoTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  rankedInstagramStories?: Maybe<RankedInstagramStories>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<Maybe<InstagramMedia>>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  hasInstagramInsightsSince?: Maybe<Scalars['Date']>;
};

export type InstagramProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InstagramProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InstagramProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type InstagramStory = {
  __typename?: 'InstagramStory';
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  profileId?: Maybe<Scalars['String']>;
  shortcode?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  media_type?: Maybe<InstagramMediaType>;
  media_product_type?: Maybe<Scalars['String']>;
  media_url?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  webhook_timestamp?: Maybe<Scalars['Date']>;
  insights?: Maybe<InstagramStoryInsights>;
  marketingInsights?: Maybe<InstagramMarketingInsights>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAd?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  archived_at?: Maybe<Scalars['Date']>;
  latest_record?: Maybe<InstagramStory>;
  profile?: Maybe<Profile>;
};

export type InstagramStoryAverages = {
  __typename?: 'InstagramStoryAverages';
  date?: Maybe<Scalars['Date']>;
  insights?: Maybe<InstagramStoryInsightAverages>;
  topHandles?: Maybe<Array<PerformanceReportMention>>;
  topHashtags?: Maybe<Array<PerformanceReportMention>>;
};

export type InstagramStoryInsightAverages = {
  __typename?: 'InstagramStoryInsightAverages';
  exits?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
  replies?: Maybe<Scalars['Float']>;
  taps_forward?: Maybe<Scalars['Float']>;
  taps_back?: Maybe<Scalars['Float']>;
  viewRate?: Maybe<Scalars['Float']>;
  retention?: Maybe<Scalars['Float']>;
  storyFrames?: Maybe<Scalars['Float']>;
};

export type InstagramStoryInsights = {
  __typename?: 'InstagramStoryInsights';
  exits?: Maybe<Scalars['Int']>;
  impressions?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
  replies?: Maybe<Scalars['Int']>;
  taps_forward?: Maybe<Scalars['Int']>;
  taps_back?: Maybe<Scalars['Int']>;
};

export type InstagramStoryTrends = {
  __typename?: 'InstagramStoryTrends';
  dailyAverages?: Maybe<Array<Maybe<InstagramStoryAverages>>>;
  recentAverages?: Maybe<InstagramStoryAverages>;
  comparisonAverages?: Maybe<InstagramStoryAverages>;
};

export type InvoiceShareContact = {
  __typename?: 'InvoiceShareContact';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type InvoiceShareContactInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Sent = 'SENT',
  Overdue = 'OVERDUE',
  Paid = 'PAID',
  Void = 'VOID'
}

export type LandingPageRequestEmailPayload = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  primaryBrandColor?: Maybe<Scalars['String']>;
  secondaryBrandColor?: Maybe<Scalars['String']>;
  imageFile?: Maybe<Scalars['Upload']>;
  changeRequest?: Maybe<Scalars['Boolean']>;
};

export type LifetimeInsights = {
  __typename?: 'LifetimeInsights';
  topCountry?: Maybe<TopDemographic>;
  topGender?: Maybe<TopDemographic>;
  topCity?: Maybe<TopDemographic>;
  audience_gender?: Maybe<AudienceGender>;
  audience_age?: Maybe<Scalars['JSObject']>;
  audience_country?: Maybe<Scalars['JSObject']>;
  audience_city?: Maybe<Scalars['JSObject']>;
};

export type LimitUsage = {
  __typename?: 'LimitUsage';
  reports?: Maybe<Scalars['String']>;
  talent?: Maybe<Scalars['String']>;
  handles?: Maybe<Scalars['String']>;
  ambassadors?: Maybe<Scalars['String']>;
  brandProfiles?: Maybe<Scalars['String']>;
  mediaKitShares?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'Link';
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type LinkInput = {
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type LinkPair = {
  __typename?: 'LinkPair';
  title?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
};

export type LinkedAccountsContent = {
  __typename?: 'LinkedAccountsContent';
  type?: Maybe<ElementType>;
  linked_accounts_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type LinksContent = {
  __typename?: 'LinksContent';
  type?: Maybe<ElementType>;
  links_content?: Maybe<Array<Maybe<LinkPair>>>;
};

export type Localized = {
  __typename?: 'Localized';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type LocationGroup = {
  __typename?: 'LocationGroup';
  country?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

export type LoginResults = {
  __typename?: 'LoginResults';
  intercomUserHash?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  error?: Maybe<Scalars['String']>;
  delegate?: Maybe<Delegate>;
  success?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<UserLoginToken>;
  phoneVerificationToken?: Maybe<Scalars['String']>;
  signedBrowserSessionToken?: Maybe<Scalars['String']>;
};

export type MediaByDayStat = {
  __typename?: 'MediaByDayStat';
  count?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['String']>;
};

export type MediaByType = {
  __typename?: 'MediaByType';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<Array<InstagramMedia>>;
  instagramFeed?: Maybe<Array<InstagramMedia>>;
  instagramReels?: Maybe<Array<InstagramMedia>>;
  stories?: Maybe<Array<InstagramStory>>;
  videos?: Maybe<Array<YoutubeMedia>>;
  posts?: Maybe<Array<FacebookPost>>;
  facebookVideos?: Maybe<Array<FacebookVideo>>;
  facebookReels?: Maybe<Array<FacebookVideo>>;
  tiktokMedia?: Maybe<Array<TiktokMedia>>;
};

export type MediaCounts = {
  __typename?: 'MediaCounts';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<MediaCountsByCategory>;
  instagramFeed?: Maybe<MediaCountsByCategory>;
  instagramReels?: Maybe<MediaCountsByCategory>;
  stories?: Maybe<MediaCountsByCategory>;
};

export type MediaCountsByCategory = {
  __typename?: 'MediaCountsByCategory';
  ads?: Maybe<Scalars['Int']>;
  all?: Maybe<Scalars['Int']>;
};

export type MediaForConfig = {
  __typename?: 'MediaForConfig';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<Array<InstagramMedia>>;
  instagramFeed?: Maybe<Array<InstagramMedia>>;
  instagramReels?: Maybe<Array<InstagramMedia>>;
  stories?: Maybe<Array<InstagramStory>>;
  videos?: Maybe<Array<YoutubeMedia>>;
  posts?: Maybe<Array<FacebookPost>>;
  facebookVideos?: Maybe<Array<FacebookVideo>>;
  facebookReels?: Maybe<Array<FacebookVideo>>;
  tiktokMedia?: Maybe<Array<TiktokMedia>>;
  profiles?: Maybe<Array<Profile>>;
  profilesAll?: Maybe<Array<Profile>>;
  supplementMedia?: Maybe<Array<SupplementMedia>>;
};

export type MediaKit = {
  __typename?: 'MediaKit';
  bio?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Maybe<Link>>>;
  rates?: Maybe<Scalars['String']>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MediaKitConfig = {
  __typename?: 'MediaKitConfig';
  _id: Scalars['ObjectId'];
  ownerId?: Maybe<Scalars['ObjectId']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  title?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  status?: Maybe<MediaKitStatus>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  draft?: Maybe<MediaKitConfigDraft>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  owner?: Maybe<User>;
  ambassador?: Maybe<Ambassador>;
  profile?: Maybe<Profile>;
  profiles?: Maybe<Array<Profile>>;
  elements?: Maybe<Array<Maybe<MediaKitElement>>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
  pdfLink?: Maybe<Scalars['String']>;
  socialChannels?: Maybe<Array<Maybe<SocialChannel>>>;
  dataAccessToken?: Maybe<Scalars['String']>;
};

export type MediaKitConfigDraft = {
  __typename?: 'MediaKitConfigDraft';
  title?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  profiles?: Maybe<Array<Profile>>;
  elements?: Maybe<Array<Maybe<MediaKitElement>>>;
};

export type MediaKitConfigUpdates = {
  title?: Maybe<Scalars['String']>;
  headerImage?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type MediaKitElement = {
  __typename?: 'MediaKitElement';
  _id: Scalars['ObjectId'];
  title?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['ObjectId']>;
  target?: Maybe<ElementTarget>;
  content?: Maybe<ElementContentType>;
  draft?: Maybe<MediaKitElementDraft>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  stringifiedContent?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
};

export type MediaKitElementContentInput = {
  type?: Maybe<ElementType>;
  text_content?: Maybe<Scalars['String']>;
  rich_text_content?: Maybe<Scalars['String']>;
  image_content?: Maybe<Scalars['Upload']>;
  image_list_content?: Maybe<Array<Maybe<ImageListInput>>>;
  gallery_content?: Maybe<Array<Maybe<GalleryInput>>>;
  links_content?: Maybe<Array<Maybe<LinkInput>>>;
  featured_insights_content?: Maybe<Array<Maybe<FeaturedInsightInput>>>;
  video_content?: Maybe<Scalars['Upload']>;
  rate_content?: Maybe<Array<Maybe<RateInput>>>;
  deliverable_rates_content?: Maybe<Array<Maybe<DeliverableRateInput>>>;
  linked_accounts_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  file_content?: Maybe<Array<Maybe<FileInput>>>;
  audience_demographics_content?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  selected_media_content?: Maybe<SelectedMediaInput>;
};

export type MediaKitElementDraft = {
  __typename?: 'MediaKitElementDraft';
  title?: Maybe<Scalars['String']>;
  content?: Maybe<ElementContentType>;
  stringifiedContent?: Maybe<Scalars['String']>;
};

export type MediaKitLink = {
  __typename?: 'MediaKitLink';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  isOpened?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['Date']>;
  expiresAt?: Maybe<Scalars['Date']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  colorTheme?: Maybe<MediaKitTheme>;
  colorHex?: Maybe<Scalars['String']>;
  backgroundColorTheme?: Maybe<MediaKitTheme>;
  backgroundColorHex?: Maybe<Scalars['String']>;
  elementIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  shortenedLink?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  contact?: Maybe<Contact>;
  profile?: Maybe<Profile>;
  elements?: Maybe<Array<Maybe<MediaKitElement>>>;
  profiles?: Maybe<Array<Maybe<Profile>>>;
  dataAccessToken?: Maybe<Scalars['String']>;
};

export type MediaKitLinkForCode = {
  __typename?: 'MediaKitLinkForCode';
  mediaKitLinkId?: Maybe<Scalars['ObjectId']>;
  dataAccessToken?: Maybe<Scalars['String']>;
};

export type MediaKitShareContact = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
};

export enum MediaKitStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum MediaKitTheme {
  Default = 'DEFAULT',
  KbPurple = 'KB_PURPLE',
  Pink = 'PINK',
  Teal = 'TEAL',
  Aqua = 'AQUA',
  Salmon = 'SALMON',
  Gray = 'GRAY',
  Lightsalmon = 'LIGHTSALMON',
  Lightgray = 'LIGHTGRAY',
  Lightorange = 'LIGHTORANGE',
  Mediumpurple = 'MEDIUMPURPLE',
  Linen = 'LINEN',
  Snow = 'SNOW',
  Darkblue = 'DARKBLUE',
  Paleblue = 'PALEBLUE',
  Turquoise = 'TURQUOISE'
}

export type MediaMetrics = {
  __typename?: 'MediaMetrics';
  average?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Float']>;
};

export type MediaPerformance = {
  __typename?: 'MediaPerformance';
  /** @deprecated Use instagramFeed or instagramReels */
  media?: Maybe<PerformanceByCategory>;
  instagramFeed?: Maybe<PerformanceByCategory>;
  instagramReels?: Maybe<PerformanceByCategory>;
  stories?: Maybe<PerformanceByCategory>;
};

export type MediaPerformanceByType = {
  __typename?: 'MediaPerformanceByType';
  all?: Maybe<MediaPerformanceStats>;
  ads?: Maybe<MediaPerformanceStats>;
};

export type MediaPerformanceStats = {
  __typename?: 'MediaPerformanceStats';
  averageReach?: Maybe<Scalars['Float']>;
  averageEngagementPercentage?: Maybe<Scalars['Float']>;
  averageNumberOfMediaPosted?: Maybe<Scalars['Float']>;
  averageNumberOfStoriesPosted?: Maybe<Scalars['Float']>;
};

export type MediaStats = {
  __typename?: 'MediaStats';
  count?: Maybe<Scalars['Float']>;
  days?: Maybe<Scalars['Float']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  like_count?: Maybe<MetricSummary>;
  comments_count?: Maybe<MetricSummary>;
  reach?: Maybe<MetricSummary>;
  impressions?: Maybe<MetricSummary>;
  engagement?: Maybe<MetricSummary>;
  engagement_percentageOfReach?: Maybe<MetricSummary>;
  saved?: Maybe<MetricSummary>;
  video_views?: Maybe<MetricSummary>;
  exits?: Maybe<MetricSummary>;
  replies?: Maybe<MetricSummary>;
  taps_forward?: Maybe<MetricSummary>;
  taps_back?: Maybe<MetricSummary>;
  retention?: Maybe<MetricSummary>;
  storyFrames?: Maybe<MetricSummary>;
  comments?: Maybe<MetricSummary>;
  likes?: Maybe<MetricSummary>;
  plays?: Maybe<MetricSummary>;
  shares?: Maybe<MetricSummary>;
  total_interactions?: Maybe<MetricSummary>;
  total_interactions_percentageOfReach?: Maybe<MetricSummary>;
};

export type MetricSummary = {
  __typename?: 'MetricSummary';
  actual?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  average?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCollaboration?: Maybe<Scalars['ObjectId']>;
  updateCollaboration: CollaborationUpdateResponse;
  updateMultipleCollaborationStages: CollaborationUpdateResponse;
  duplicateCollaboration: CollaborationUpdateResponse;
  deleteCollaboration: ServerResponse;
  createDeliverable: Scalars['String'];
  updateDeliverable: ServerResponse;
  createMultipleDeliverables: ServerResponse;
  updateAndCreateDeliverables: ServerResponse;
  viewDeliverable: ServerResponse;
  deleteDeliverable: ServerResponse;
  deleteDeliverablesByIds: ServerResponse;
  updateDeliverableValueAddedMediaIds?: Maybe<ServerResponse>;
  generateInstagramRankings: ServerResponse;
  inviteAmbassador: ServerResponse;
  createAmbassadorManually: ServerResponse;
  inviteManualAmbassador: ServerResponse;
  createAmbassador: ServerResponse;
  deleteAmbassador: ServerResponse;
  deleteAmbassadorByUserId: ServerResponse;
  updateAmbassadorInvitation: ServerResponse;
  updateAmbassadorInfo: ServerResponse;
  sendReauthenticationEmail?: Maybe<ServerResponse>;
  registerAmbassador?: Maybe<AmbassadorRegistrationResult>;
  resendAmbassadorInvitation: ServerResponse;
  updateUserSubscription: ServerResponse;
  processAppPurchaseResponse: ServerResponse;
  updateTransactionInfo: ServerResponse;
  testAppSubscription: ServerResponse;
  deletePaymentMethod: Scalars['Boolean'];
  setPaymentMethodToDefault: Scalars['Boolean'];
  connectContact: ConnectReponse;
  createContact?: Maybe<ServerResponse>;
  updateContact: ServerResponse;
  deleteContact: ServerResponse;
  connectUserToContact: ServerResponse;
  respondToUserContactConnection: ServerResponse;
  adminCreateCoupon: CouponCode;
  adminUpdateCoupon?: Maybe<CouponCode>;
  adminDeleteCoupon: ServerResponse;
  addDelegate: ServerResponse;
  updateDelegate: ServerResponse;
  deleteDelegate: ServerResponse;
  adminDeleteDelegate: ServerResponse;
  registerDelegate: DelegateRegistrationResult;
  setFacebookProfile: ServerResponse;
  updateUserToken: ServerResponse;
  adminCreatePlan?: Maybe<InfluencerPlan>;
  adminUpdatePlan?: Maybe<InfluencerPlan>;
  adminDeletePlan?: Maybe<ServerResponse>;
  updateWebhookSubscriptions: ServerResponse;
  openCollaborationInvoiceLink: Scalars['String'];
  createCollaborationInvoiceLink: CollaborationInvoiceLinkCreationResult;
  shareCollaborationInvoice: ServerResponse;
  createCollaborationInvoice: Scalars['ObjectId'];
  updateCollaborationInvoice: ServerResponse;
  deleteCollaborationInvoice: ServerResponse;
  createMediaKitLink: Scalars['String'];
  shareMediaKitToContact: ServerResponse;
  shareAparToContact: ServerResponse;
  createMediaKitConfig: Scalars['String'];
  updateMediaKitConfig: ServerResponse;
  updateMediaKitDraftConfig: ServerResponse;
  publishMediaKitDraftConfig: ServerResponse;
  deleteMediaKitConfig: ServerResponse;
  adminClearOrphanedElements?: Maybe<ServerResponse>;
  createElement?: Maybe<Scalars['ObjectId']>;
  updateElement: ServerResponse;
  updateElementDraft: ServerResponse;
  deleteElement: ServerResponse;
  updateNotificationReadStatus: ServerResponse;
  adminUpdatePersistedUser?: Maybe<PersistedUser>;
  updateProfileNote: ServerResponse;
  createProfile: Scalars['ObjectId'];
  updateProfile: ServerResponse;
  unassignAmbassadorFromProfile: ServerResponse;
  deleteProfile: ServerResponse;
  adminUpdateProfile: ServerResponse;
  adminDeleteProfile: ServerResponse;
  adminCreateEternalLink?: Maybe<Scalars['String']>;
  restoreProfileData?: Maybe<ServerResponse>;
  logReceivedPushNotification: ServerResponse;
  updatePushNotificationToken: ServerResponse;
  addRecent?: Maybe<ServerResponse>;
  createSupplementMedia: ServerResponse;
  insertSupplementMediaList: ServerResponse;
  updateSupplementMedia: ServerResponse;
  deleteSupplementMedia: ServerResponse;
  createReportConfig: ReportConfig;
  updateReportConfig: ServerResponse;
  deleteReportConfig: ServerResponse;
  deleteMultipleReportConfigs: ServerResponse;
  approveTrackingRequest: ServerResponse;
  mergeReportConfigs: ReportConfig;
  setReportViewedForId?: Maybe<ServerResponse>;
  createReportLink: Scalars['String'];
  shareReportToContact: ServerResponse;
  generateReportView: Scalars['String'];
  hideSentEmailRecord: ServerResponse;
  resendEmailInvite: ServerResponse;
  sendEmailInvite: ServerResponse;
  sendTalentNetworkContactEmail: ServerResponse;
  sendLandingPageRequestEmail: ServerResponse;
  deleteSubscriptionScheduleForCustomer: ServerResponse;
  deleteStripePaymentMethod: Scalars['Boolean'];
  setStripePaymentMethodToDefault: Scalars['Boolean'];
  applyStripeCouponToSubscription: ServerResponse;
  createTiktokProfile?: Maybe<ServerResponse>;
  connectTiktokProfile: ServerResponse;
  disconnectTiktokProfile: ServerResponse;
  createTrackingLink: TrackingLink;
  deleteTrackingLink: ServerResponse;
  clearOrphanedLinks: ServerResponse;
  login: ServerResponse;
  updateUser: ServerResponse;
  updateWalkthroughs: ServerResponse;
  updateCollabCategories: ServerResponse;
  updateCollabStages: ServerResponse;
  veryfyUserEmail: ServerResponse;
  sendVerificationCodeToPhone: ServerResponse;
  resendEmailVerificationCode: ServerResponse;
  deleteMyUser: ServerResponse;
  adminDeleteUser: ServerResponse;
  adminUpdateUser?: Maybe<User>;
  adminRefreshSubscriptions: ServerResponse;
  updateAccountProgress: ServerResponse;
  updateBrandAmbassadorCategory: ServerResponse;
  inviteBrand: ServerResponse;
  logout: ServerResponse;
};

export type MutationCreateCollaborationArgs = {
  updates?: Maybe<CollaborationUpdates>;
};

export type MutationUpdateCollaborationArgs = {
  updates?: Maybe<CollaborationUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationUpdateMultipleCollaborationStagesArgs = {
  existingStage?: Maybe<Scalars['String']>;
  updatedStage?: Maybe<Scalars['String']>;
  updatedCollabStages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationDuplicateCollaborationArgs = {
  updates?: Maybe<CollaborationUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationDeleteCollaborationArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateDeliverableArgs = {
  updates?: Maybe<DeliverableUpdates>;
};

export type MutationUpdateDeliverableArgs = {
  updates?: Maybe<DeliverableUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationCreateMultipleDeliverablesArgs = {
  updates?: Maybe<DeliverableUpdates>;
  newTrackingLink?: Maybe<TrackingLinkInput>;
};

export type MutationUpdateAndCreateDeliverablesArgs = {
  updates?: Maybe<DeliverableUpdates>;
  newTrackingLink?: Maybe<TrackingLinkInput>;
  id: Scalars['ObjectId'];
};

export type MutationViewDeliverableArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeleteDeliverableArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeleteDeliverablesByIdsArgs = {
  ids: Array<Scalars['ObjectId']>;
};

export type MutationUpdateDeliverableValueAddedMediaIdsArgs = {
  remove?: Maybe<Scalars['String']>;
  add?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
};

export type MutationGenerateInstagramRankingsArgs = {
  shouldSave?: Maybe<Scalars['Boolean']>;
  shouldNotify?: Maybe<Scalars['Boolean']>;
};

export type MutationInviteAmbassadorArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  requestingAccountName?: Maybe<Scalars['String']>;
};

export type MutationCreateAmbassadorManuallyArgs = {
  inviteByEmail?: Maybe<Scalars['Boolean']>;
  addToCollaborationId?: Maybe<Scalars['ObjectId']>;
  updates: AmbassadorInput;
};

export type MutationInviteManualAmbassadorArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type MutationCreateAmbassadorArgs = {
  profileUsername?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationDeleteAmbassadorArgs = {
  ambassadorId?: Maybe<Scalars['ObjectId']>;
};

export type MutationDeleteAmbassadorByUserIdArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
};

export type MutationUpdateAmbassadorInvitationArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  ambassadorInput?: Maybe<AmbassadorInput>;
};

export type MutationUpdateAmbassadorInfoArgs = {
  updates: AmbassadorInput;
  id: Scalars['ObjectId'];
};

export type MutationSendReauthenticationEmailArgs = {
  userId: Scalars['ObjectId'];
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationRegisterAmbassadorArgs = {
  payload: RegisterAmbassadorInput;
  userId: Scalars['ObjectId'];
};

export type MutationResendAmbassadorInvitationArgs = {
  ambassadorId?: Maybe<Scalars['ObjectId']>;
};

export type MutationUpdateUserSubscriptionArgs = {
  subscription?: Maybe<AppSubscriptionInput>;
  installerName?: Maybe<Scalars['String']>;
};

export type MutationProcessAppPurchaseResponseArgs = {
  purchases?: Maybe<Array<Maybe<AppSubscriptionInput>>>;
  installerName?: Maybe<Scalars['String']>;
};

export type MutationUpdateTransactionInfoArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['String'];
};

export type MutationSetPaymentMethodToDefaultArgs = {
  id: Scalars['String'];
};

export type MutationConnectContactArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateContactArgs = {
  updates?: Maybe<ContactUpdates>;
};

export type MutationUpdateContactArgs = {
  updates?: Maybe<ContactUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['ObjectId'];
};

export type MutationConnectUserToContactArgs = {
  contactId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type MutationRespondToUserContactConnectionArgs = {
  contactId: Scalars['ObjectId'];
  accepted: Scalars['Boolean'];
};

export type MutationAdminCreateCouponArgs = {
  updates?: Maybe<CouponCodeUpdates>;
};

export type MutationAdminUpdateCouponArgs = {
  couponCodeId?: Maybe<Scalars['String']>;
  updates?: Maybe<CouponCodeUpdates>;
};

export type MutationAdminDeleteCouponArgs = {
  couponCodeId?: Maybe<Scalars['String']>;
};

export type MutationAddDelegateArgs = {
  delegate?: Maybe<DelegateCreationInput>;
  delegates?: Maybe<Array<Maybe<DelegateCreationInput>>>;
};

export type MutationUpdateDelegateArgs = {
  delegateUpdates?: Maybe<DelegateUserUpdates>;
};

export type MutationDeleteDelegateArgs = {
  id: Scalars['ObjectId'];
};

export type MutationAdminDeleteDelegateArgs = {
  id: Scalars['ObjectId'];
};

export type MutationRegisterDelegateArgs = {
  payload: DelegateRegistrationInput;
  delegate_id: Scalars['ObjectId'];
};

export type MutationSetFacebookProfileArgs = {
  updates?: Maybe<FacebookProfileInput>;
};

export type MutationUpdateUserTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationAdminCreatePlanArgs = {
  updates?: Maybe<InfluencerPlanUpdates>;
};

export type MutationAdminUpdatePlanArgs = {
  influencerPlanId?: Maybe<Scalars['ObjectId']>;
  updates?: Maybe<InfluencerPlanUpdates>;
};

export type MutationAdminDeletePlanArgs = {
  influencerPlanId?: Maybe<Scalars['ObjectId']>;
};

export type MutationUpdateWebhookSubscriptionsArgs = {
  shouldIncludeMentions?: Maybe<Scalars['Boolean']>;
  profileId: Scalars['String'];
};

export type MutationOpenCollaborationInvoiceLinkArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateCollaborationInvoiceLinkArgs = {
  collaborationInvoiceId?: Maybe<Scalars['ObjectId']>;
};

export type MutationShareCollaborationInvoiceArgs = {
  id: Scalars['ObjectId'];
  shareDetails?: Maybe<ShareCollaborationInvoiceDetails>;
};

export type MutationCreateCollaborationInvoiceArgs = {
  updates?: Maybe<CollaborationInvoiceUpdates>;
};

export type MutationUpdateCollaborationInvoiceArgs = {
  updates?: Maybe<CollaborationInvoiceUpdates>;
  id: Scalars['ObjectId'];
};

export type MutationDeleteCollaborationInvoiceArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateMediaKitLinkArgs = {
  mediaKitId?: Maybe<Scalars['ObjectId']>;
};

export type MutationShareMediaKitToContactArgs = {
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<MediaKitShareContact>;
  coverLetter?: Maybe<Scalars['String']>;
};

export type MutationShareAparToContactArgs = {
  aparLink?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<MediaKitShareContact>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationCreateMediaKitConfigArgs = {
  updates?: Maybe<MediaKitConfigUpdates>;
};

export type MutationUpdateMediaKitConfigArgs = {
  id: Scalars['ObjectId'];
  updates?: Maybe<MediaKitConfigUpdates>;
};

export type MutationUpdateMediaKitDraftConfigArgs = {
  id: Scalars['ObjectId'];
  updates?: Maybe<MediaKitConfigUpdates>;
};

export type MutationPublishMediaKitDraftConfigArgs = {
  id: Scalars['ObjectId'];
};

export type MutationDeleteMediaKitConfigArgs = {
  id: Scalars['ObjectId'];
};

export type MutationAdminClearOrphanedElementsArgs = {
  shouldDelete?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateElementArgs = {
  type?: Maybe<ElementType>;
  title?: Maybe<Scalars['String']>;
  mediaKitId?: Maybe<Scalars['ObjectId']>;
  index?: Maybe<Scalars['Int']>;
};

export type MutationUpdateElementArgs = {
  id: Scalars['ObjectId'];
  content?: Maybe<MediaKitElementContentInput>;
};

export type MutationUpdateElementDraftArgs = {
  id: Scalars['ObjectId'];
  content?: Maybe<MediaKitElementContentInput>;
};

export type MutationDeleteElementArgs = {
  id: Scalars['ObjectId'];
};

export type MutationUpdateNotificationReadStatusArgs = {
  notificationId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminUpdatePersistedUserArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
  userUpdates?: Maybe<PersistedUserUpdates>;
};

export type MutationUpdateProfileNoteArgs = {
  accountId?: Maybe<Scalars['String']>;
  updates?: Maybe<ProfileNoteInput>;
};

export type MutationCreateProfileArgs = {
  info: NewProfileInfo;
};

export type MutationUpdateProfileArgs = {
  updates: ProfileUpdates;
  profileId: Scalars['ObjectId'];
};

export type MutationUnassignAmbassadorFromProfileArgs = {
  profileId: Scalars['ObjectId'];
};

export type MutationDeleteProfileArgs = {
  pageId?: Maybe<Scalars['String']>;
  profileId: Scalars['ObjectId'];
};

export type MutationAdminUpdateProfileArgs = {
  updates?: Maybe<ProfileUpdates>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminDeleteProfileArgs = {
  pageId?: Maybe<Scalars['String']>;
  profileId: Scalars['ObjectId'];
};

export type MutationAdminCreateEternalLinkArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MutationRestoreProfileDataArgs = {
  shouldSave?: Maybe<Scalars['Boolean']>;
  profileType?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type MutationLogReceivedPushNotificationArgs = {
  content?: Maybe<Scalars['String']>;
};

export type MutationUpdatePushNotificationTokenArgs = {
  pushNotificationTokenInput?: Maybe<PushNotificationTokenInput>;
};

export type MutationAddRecentArgs = {
  accountId?: Maybe<Scalars['String']>;
};

export type MutationCreateSupplementMediaArgs = {
  input?: Maybe<SupplementMediaInput>;
};

export type MutationInsertSupplementMediaListArgs = {
  input?: Maybe<Array<Maybe<SupplementMediaInput>>>;
};

export type MutationUpdateSupplementMediaArgs = {
  id: Scalars['ObjectId'];
  updates?: Maybe<SupplementMediaInput>;
};

export type MutationDeleteSupplementMediaArgs = {
  id: Scalars['ObjectId'];
};

export type MutationCreateReportConfigArgs = {
  config?: Maybe<ReportConfigInput>;
  trackingLinks?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  supplementMedia?: Maybe<Array<Maybe<SupplementMediaInput>>>;
};

export type MutationUpdateReportConfigArgs = {
  reportId?: Maybe<Scalars['ObjectId']>;
  config?: Maybe<ReportConfigInput>;
};

export type MutationDeleteReportConfigArgs = {
  reportId?: Maybe<Scalars['ObjectId']>;
};

export type MutationDeleteMultipleReportConfigsArgs = {
  reportIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type MutationApproveTrackingRequestArgs = {
  token?: Maybe<Scalars['String']>;
};

export type MutationMergeReportConfigsArgs = {
  report_name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type MutationSetReportViewedForIdArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type MutationCreateReportLinkArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
};

export type MutationShareReportToContactArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<ReportShareContact>;
};

export type MutationGenerateReportViewArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  reportLinkId?: Maybe<Scalars['ObjectId']>;
  reportLinkToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  browserSessionToken?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type MutationHideSentEmailRecordArgs = {
  id: Scalars['ObjectId'];
};

export type MutationResendEmailInviteArgs = {
  id: Scalars['ObjectId'];
};

export type MutationSendEmailInviteArgs = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type MutationSendTalentNetworkContactEmailArgs = {
  emailPayload?: Maybe<TalentNetworkContactEmailPayload>;
};

export type MutationSendLandingPageRequestEmailArgs = {
  payload?: Maybe<LandingPageRequestEmailPayload>;
};

export type MutationDeleteStripePaymentMethodArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationSetStripePaymentMethodToDefaultArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationApplyStripeCouponToSubscriptionArgs = {
  couponCode?: Maybe<Scalars['String']>;
};

export type MutationCreateTiktokProfileArgs = {
  username?: Maybe<Scalars['String']>;
};

export type MutationConnectTiktokProfileArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MutationDisconnectTiktokProfileArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MutationCreateTrackingLinkArgs = {
  updates?: Maybe<TrackingLinkInput>;
};

export type MutationDeleteTrackingLinkArgs = {
  trackingLinkId?: Maybe<Scalars['ObjectId']>;
};

export type MutationLoginArgs = {
  input?: Maybe<UserLoginInput>;
};

export type MutationUpdateUserArgs = {
  updates?: Maybe<UserUpdates>;
};

export type MutationUpdateWalkthroughsArgs = {
  update?: Maybe<Scalars['String']>;
};

export type MutationUpdateCollabCategoriesArgs = {
  deleteCategory?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
};

export type MutationUpdateCollabStagesArgs = {
  stages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationVeryfyUserEmailArgs = {
  token?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type MutationSendVerificationCodeToPhoneArgs = {
  phoneNumber?: Maybe<Scalars['String']>;
};

export type MutationResendEmailVerificationCodeArgs = {
  shouldUpdateUserEmail?: Maybe<Scalars['Boolean']>;
  alternateEmail?: Maybe<Scalars['String']>;
};

export type MutationAdminDeleteUserArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminUpdateUserArgs = {
  updates?: Maybe<UserUpdates>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type MutationAdminRefreshSubscriptionsArgs = {
  userId?: Maybe<Scalars['ObjectId']>;
  logging?: Maybe<Scalars['Boolean']>;
  shouldCheckAll?: Maybe<Scalars['Boolean']>;
};

export type MutationUpdateAccountProgressArgs = {
  steps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationUpdateBrandAmbassadorCategoryArgs = {
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationInviteBrandArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
};

export type NewProfileInfo = {
  id?: Maybe<Scalars['String']>;
  profileType?: Maybe<SocialChannel>;
  name?: Maybe<Scalars['String']>;
  channel?: Maybe<YoutubeChannelInput>;
  thumbnails?: Maybe<Scalars['JSObject']>;
  picture?: Maybe<Scalars['JSObject']>;
  instagram_business_account?: Maybe<InstagramAccountInput>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Page_Negative_Feedback_By_Type = {
  __typename?: 'PAGE_NEGATIVE_FEEDBACK_BY_TYPE';
  hide_clicks?: Maybe<Scalars['Int']>;
  hide_all_clicks?: Maybe<Scalars['Int']>;
  report_spam_clicks?: Maybe<Scalars['Int']>;
  unlike_page_clicks?: Maybe<Scalars['Int']>;
};

export type Page_Positive_Feedback_By_Type = {
  __typename?: 'PAGE_POSITIVE_FEEDBACK_BY_TYPE';
  answer?: Maybe<Scalars['Int']>;
  claim?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['Int']>;
  like?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
  other?: Maybe<Scalars['Int']>;
  rsvp?: Maybe<Scalars['Int']>;
};

export type PendingNotification = {
  __typename?: 'PendingNotification';
  _id?: Maybe<Scalars['ObjectId']>;
  userId?: Maybe<Scalars['ObjectId']>;
  userIdList?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  type?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  mediaKitConfigId?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
  delayHours?: Maybe<Scalars['Int']>;
};

export type PerformanceBenchmark = {
  __typename?: 'PerformanceBenchmark';
  _id: Scalars['ObjectId'];
  date?: Maybe<Scalars['Date']>;
  averagesByGroup?: Maybe<Array<Maybe<FollowerBracketPerformance>>>;
};

export type PerformanceByCategory = {
  __typename?: 'PerformanceByCategory';
  ads?: Maybe<PerformanceRanking>;
  all?: Maybe<PerformanceRanking>;
};

export type PerformanceRanking = {
  __typename?: 'PerformanceRanking';
  value?: Maybe<Scalars['Float']>;
  average?: Maybe<Scalars['Float']>;
  comparisonBracket?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  percentile?: Maybe<Scalars['Int']>;
};

export type PerformanceReport = {
  __typename?: 'PerformanceReport';
  _id: Scalars['ObjectId'];
  date?: Maybe<Scalars['Date']>;
  profileId?: Maybe<Scalars['String']>;
  followerBracket?: Maybe<Scalars['Int']>;
  comparisonBracket?: Maybe<Scalars['Int']>;
  performance?: Maybe<PerformanceRanking>;
  mediaPerformance?: Maybe<MediaPerformance>;
  mediaCounts?: Maybe<MediaCounts>;
  trends?: Maybe<InstagramCombinedAverages>;
  /** @deprecated Use instagramFeedTrends or instagramReelTrends */
  mediaTrends?: Maybe<InstagramMediaAverages>;
  instagramFeedTrends?: Maybe<InstagramMediaAverages>;
  instagramReelTrends?: Maybe<InstagramMediaAverages>;
  storyTrends?: Maybe<InstagramStoryAverages>;
  isLimited?: Maybe<Scalars['Boolean']>;
  isViewed?: Maybe<Scalars['Boolean']>;
  imageUrl?: Maybe<Scalars['String']>;
  incomingMentions?: Maybe<Array<PerformanceReportMention>>;
  startingStats?: Maybe<InstagramHistorical>;
  endingStats?: Maybe<InstagramHistorical>;
  profile?: Maybe<Profile>;
  lastReport?: Maybe<PerformanceReport>;
  instagramMedia?: Maybe<Array<InstagramMedia>>;
  instagramFeed?: Maybe<Array<InstagramMedia>>;
  instagramReels?: Maybe<Array<InstagramMedia>>;
  instagramStories?: Maybe<Array<InstagramStory>>;
};

export type PerformanceReportMention = {
  __typename?: 'PerformanceReportMention';
  displayName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type PersistedUser = {
  __typename?: 'PersistedUser';
  _id?: Maybe<Scalars['ObjectId']>;
  email?: Maybe<Scalars['String']>;
  signupDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  deleteReason?: Maybe<Scalars['String']>;
};

export type PersistedUserUpdates = {
  notes?: Maybe<Scalars['String']>;
};

export type PlanStat = {
  __typename?: 'PlanStat';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
};

export type PostsForHashtagResponse = {
  __typename?: 'PostsForHashtagResponse';
  posts?: Maybe<Array<Maybe<TiktokMedia>>>;
  userList?: Maybe<Array<Maybe<TiktokUser>>>;
};

export type PredictionStats = {
  __typename?: 'PredictionStats';
  coefficientOfDetermination?: Maybe<Scalars['JSObject']>;
};

export type Profile = {
  __typename?: 'Profile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  tokenUserId?: Maybe<Scalars['ObjectId']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  facebookVideoStats?: Maybe<FacebookVideoStats>;
  facebookReelStats?: Maybe<FacebookVideoStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  rankedFacebookVideos?: Maybe<RankedFacebookVideos>;
  rankedFacebookReels?: Maybe<RankedFacebookVideos>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  latestFacebookVideos?: Maybe<Array<FacebookVideo>>;
  latestFacebookReels?: Maybe<Array<FacebookVideo>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  facebookVideoTrends?: Maybe<FacebookVideoTrends>;
  facebookReelTrends?: Maybe<FacebookVideoTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  rankedInstagramStories?: Maybe<RankedInstagramStories>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
};

export type ProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ProfileBrowseFilters = {
  channels?: Maybe<Array<Maybe<SocialChannel>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  followers?: Maybe<FloatRange>;
  trueReach?: Maybe<FloatRange>;
  engagementPercentage?: Maybe<FloatRange>;
  talentType?: Maybe<Scalars['String']>;
  agencyUserId?: Maybe<Scalars['ObjectId']>;
  timezone?: Maybe<Scalars['String']>;
  timezoneList?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  usernames?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<DemographicFilter>;
  age?: Maybe<DemographicFilter>;
  gender?: Maybe<DemographicFilter>;
};

export type ProfileBrowseResult = {
  __typename?: 'ProfileBrowseResult';
  profile?: Maybe<Profile>;
  profileNoteList?: Maybe<Array<Maybe<ProfileNote>>>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
};

export type ProfileCacheError = {
  __typename?: 'ProfileCacheError';
  _id?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['String']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
};

export type ProfileForCode = {
  __typename?: 'ProfileForCode';
  profileId?: Maybe<Scalars['ObjectId']>;
  dataAccessToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  profile?: Maybe<Profile>;
  user?: Maybe<User>;
};

export type ProfileListResponse = {
  __typename?: 'ProfileListResponse';
  list?: Maybe<Array<Maybe<Profile>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProfileNote = {
  __typename?: 'ProfileNote';
  _id: Scalars['ObjectId'];
  accountId?: Maybe<Scalars['String']>;
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  notes?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  noteAuditReportLink?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
};

export type ProfileNoteInput = {
  notes?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
};

export type ProfileNotification = {
  __typename?: 'ProfileNotification';
  _id: Scalars['ObjectId'];
  text?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  recipient?: Maybe<Scalars['ObjectId']>;
};

export type ProfileUpdates = {
  agentId?: Maybe<Scalars['String']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PushNotificationToken = {
  __typename?: 'PushNotificationToken';
  _id: Scalars['ObjectId'];
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type PushNotificationTokenInput = {
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
};

export type Query = {
  __typename?: 'Query';
  getDeliverableList?: Maybe<Array<Maybe<Deliverable>>>;
  getAmbassadorForId?: Maybe<Ambassador>;
  getAmbassadorList: Array<Ambassador>;
  getAmbassadorProfiles: Array<Profile>;
  getAmbassadorInvitations: Array<User>;
  adminGetAmbassadorList: Array<Ambassador>;
  getAmbassadorListByUserId: Array<Ambassador>;
  getAmbassadorActivity: Array<AmbassadorActivity>;
  getDataAccessForAmbassador?: Maybe<UserForCode>;
  adminGetSubscriptionList: Array<BraintreeSubscription>;
  adminGetCustomerList: Array<BraintreeCustomer>;
  adminGetTransactionList: Array<BraintreeTransaction>;
  adminGetSubscriptionGrowth: Array<SubscriptionsAtDate>;
  adminGetAllCollaborations: Array<Collaboration>;
  getCollaborationList: Array<Collaboration>;
  getCollaborationTotals: CollaborationTotalsResponse;
  getContactList: Array<Contact>;
  getContactConnectionRequestList: Array<Contact>;
  getConnectedContactList: Array<Contact>;
  getPlatformAd?: Maybe<ContentfulPlatformAd>;
  getCoupons: Array<CouponCode>;
  adminGetCouponList: Array<CouponCode>;
  getDelegateList: Array<Delegate>;
  getDelegate?: Maybe<Delegate>;
  getFacebookPages: Array<FacebookPageInfo>;
  adminGetPlansList: Array<InfluencerPlan>;
  searchPublicProfiles: DiscoverySearchResult;
  analyseProfile: InsightIqDiscoveryAnalyticsResult;
  autocompleteProfileSearch: Array<InsightIqAutocompleteSearchResult>;
  getDiscoveryFilterLanguages: Array<InsightIqLanguageOption>;
  getDiscoveryFilterLocations: Array<InsightIqLocationOption>;
  getDiscoveryFilterInterests: Array<InsightIqQueryFieldOption>;
  getDiscoveryFilterBrands: Array<InsightIqQueryFieldOption>;
  searchUsername?: Maybe<InstagramProfile>;
  getMediaForId: Array<InstagramMedia>;
  getStoriesForDate: Array<InstagramStory>;
  getStoriesForMonth: StoriesForMonthResponse;
  adminGetAllInvoices: Array<CollaborationInvoice>;
  viewCollaborationInvoice?: Maybe<CollaborationInvoice>;
  getCollaborationInvoices: Array<CollaborationInvoice>;
  getCollaborationInvoiceTotals?: Maybe<CollaborationInvoiceTotalsResponse>;
  getMediaKitConfig?: Maybe<MediaKitConfig>;
  getMediaKitConfigList: Array<MediaKitConfig>;
  getElement?: Maybe<MediaKitElement>;
  getElementList: Array<MediaKitElement>;
  getMediaKitFromToken?: Maybe<MediaKitLink>;
  getMediaKitLink?: Maybe<MediaKitLink>;
  getMediaKitLinkList: Array<MediaKitLink>;
  getMediaKitAccessForCode: MediaKitLinkForCode;
  getNotification?: Maybe<ProfileNotification>;
  getLatestPerformanceBenchmark?: Maybe<PerformanceBenchmark>;
  getPerformanceReportForId?: Maybe<PerformanceReport>;
  viewPerformanceReport?: Maybe<PerformanceReport>;
  adminGetDeletedUserList: Array<PersistedUser>;
  getDataAccessForProfileNote?: Maybe<ProfileForCode>;
  getSingleProfile: Profile;
  getProfileForId: Profile;
  getMultipleProfiles: Array<Profile>;
  getProfiles: Array<Profile>;
  getProfilesByUserIds: Array<Profile>;
  searchProfiles: Array<Scalars['String']>;
  searchAllProfiles: Array<Profile>;
  getProfileList: ProfileListResponse;
  adminGetProfileList?: Maybe<Array<Profile>>;
  browseProfiles: Array<ProfileBrowseResult>;
  getDataAccessForProfile?: Maybe<ProfileForCode>;
  adminProfilesInfo: AdminProfileInfo;
  getProfilesFromReceivedReports: Array<Profile>;
  getRecents?: Maybe<Array<Maybe<RecentProfile>>>;
  getReportForId?: Maybe<ReportConfig>;
  getReportConfigList: Array<ReportConfig>;
  getMediaForReportId?: Maybe<MediaForConfig>;
  adminQueryReports: AdminReportStats;
  getReportFromToken?: Maybe<ReportLink>;
  getReportLink?: Maybe<ReportLink>;
  getReportLinkList: Array<ReportLink>;
  getSentInvites: Array<SentEmail>;
  getConnectedAccountLink?: Maybe<StripeAccountLink>;
  getConnectedAccount?: Maybe<StripeConnectedAccount>;
  getSupplementMediaForReport: Array<SupplementMedia>;
  getTiktokProfiles: Array<TiktokProfile>;
  getTiktokMediaForId: Array<TiktokMedia>;
  getTiktokHistoricalForId: Array<TiktokHistorical>;
  getWeeklyTiktokStats: Array<TiktokHistoricalAverages>;
  getTrackingLinkList: Array<TrackingLink>;
  getTrackingLinksForIds: Array<TrackingLink>;
  getRewards: Array<UserReward>;
  getLatestAppUpdate?: Maybe<AppUpdate>;
  getUserById: User;
  getUserList: Array<User>;
  myUser?: Maybe<User>;
  getListOfAgencies: Array<User>;
  getListOfKbUsers: Array<User>;
  adminGetPayingUsers: UserListResponse;
  adminGetUserList: UserListResponse;
  adminGenerateDailyUpdate: ServerResponse;
  getDataAccessForUser?: Maybe<UserForCode>;
  getBearerToken?: Maybe<Scalars['String']>;
  recentYoutubeMedia: RecentYoutubeMediaResult;
  youtubeMediaByViews?: Maybe<YoutubeMediaListResult>;
  youtubeMediaHistorical: YoutubeMediaHistoryResult;
  listYoutubeChannels: Array<YoutubeChannel>;
  listYoutubeProfiles: Array<YoutubeProfile>;
};

export type QueryGetDeliverableListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  socialChannel?: Maybe<SocialChannel>;
  socialDeliverable?: Maybe<SocialDeliverable>;
};

export type QueryGetAmbassadorForIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetAmbassadorActivityArgs = {
  since?: Maybe<Scalars['Date']>;
  activityType?: Maybe<AmbassadorActivityType>;
};

export type QueryGetDataAccessForAmbassadorArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryAdminGetSubscriptionListArgs = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryAdminGetSubscriptionGrowthArgs = {
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryGetCollaborationListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  status?: Maybe<CollaborationStatus>;
  canEdit?: Maybe<Scalars['Boolean']>;
  delegateId?: Maybe<Scalars['ObjectId']>;
  assignedToMaster?: Maybe<Scalars['Boolean']>;
  contactId?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetCollaborationTotalsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type QueryGetContactListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ContactType>;
};

export type QueryGetContactConnectionRequestListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ContactType>;
};

export type QueryGetConnectedContactListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  type?: Maybe<ContactType>;
};

export type QueryGetPlatformAdArgs = {
  params?: Maybe<ContentfulPlatformAdParams>;
};

export type QueryGetDelegateArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QuerySearchPublicProfilesArgs = {
  filters?: Maybe<InsightIqDiscoverySearchFilter>;
  channel: InsightIqAvailableChannel;
};

export type QueryAnalyseProfileArgs = {
  identifier?: Maybe<Scalars['String']>;
  work_platform_id?: Maybe<Scalars['String']>;
  channel?: Maybe<InsightIqAvailableChannel>;
};

export type QueryAutocompleteProfileSearchArgs = {
  query_text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  work_platform_id?: Maybe<Scalars['String']>;
  channel?: Maybe<InsightIqAvailableChannel>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type QuerySearchUsernameArgs = {
  username?: Maybe<Scalars['String']>;
};

export type QueryGetMediaForIdArgs = {
  profileId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetStoriesForDateArgs = {
  profileId?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
};

export type QueryGetStoriesForMonthArgs = {
  profileId?: Maybe<Scalars['ObjectId']>;
  date?: Maybe<Scalars['Date']>;
};

export type QueryViewCollaborationInvoiceArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetCollaborationInvoicesArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetCollaborationInvoiceTotalsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type QueryGetMediaKitConfigArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetElementArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetElementListArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryGetMediaKitFromTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type QueryGetMediaKitLinkArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetMediaKitAccessForCodeArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryGetNotificationArgs = {
  recipientId?: Maybe<Scalars['String']>;
  trigger?: Maybe<Scalars['String']>;
};

export type QueryGetPerformanceReportForIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryViewPerformanceReportArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetDataAccessForProfileNoteArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryGetSingleProfileArgs = {
  username?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetProfileForIdArgs = {
  id: Scalars['String'];
};

export type QueryGetMultipleProfilesArgs = {
  _ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryGetProfilesArgs = {
  profileTypeList?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<Scalars['String']>;
};

export type QueryGetProfilesByUserIdsArgs = {
  _ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuerySearchProfilesArgs = {
  username?: Maybe<Scalars['String']>;
};

export type QuerySearchAllProfilesArgs = {
  query?: Maybe<Scalars['String']>;
};

export type QueryGetProfileListArgs = {
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  query?: Maybe<InfluencerQuery>;
};

export type QueryAdminGetProfileListArgs = {
  type?: Maybe<SocialChannel>;
  query?: Maybe<Scalars['String']>;
};

export type QueryBrowseProfilesArgs = {
  filters?: Maybe<ProfileBrowseFilters>;
};

export type QueryGetDataAccessForProfileArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryAdminProfilesInfoArgs = {
  limit?: Maybe<Scalars['Float']>;
};

export type QueryGetProfilesFromReceivedReportsArgs = {
  returnEmpty?: Maybe<Scalars['Boolean']>;
};

export type QueryGetReportForIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetReportConfigListArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetMediaForReportIdArgs = {
  id: Scalars['ObjectId'];
};

export type QueryAdminQueryReportsArgs = {
  id?: Maybe<Scalars['ObjectId']>;
  end?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type QueryGetReportFromTokenArgs = {
  token?: Maybe<Scalars['String']>;
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryGetReportLinkArgs = {
  id: Scalars['ObjectId'];
};

export type QueryGetConnectedAccountLinkArgs = {
  returnPath?: Maybe<Scalars['String']>;
};

export type QueryGetSupplementMediaForReportArgs = {
  reportConfigId?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetTiktokMediaForIdArgs = {
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetTiktokHistoricalForIdArgs = {
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
};

export type QueryGetWeeklyTiktokStatsArgs = {
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryGetTrackingLinkListArgs = {
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
};

export type QueryGetTrackingLinksForIdsArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryGetLatestAppUpdateArgs = {
  version?: Maybe<Scalars['String']>;
};

export type QueryGetUserByIdArgs = {
  id?: Maybe<Scalars['ObjectId']>;
};

export type QueryGetUserListArgs = {
  ids?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type QueryAdminGetPayingUsersArgs = {
  type?: Maybe<UserType>;
};

export type QueryAdminGetUserListArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  sortField?: Maybe<SortField>;
  filter?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  profileQuery?: Maybe<Scalars['String']>;
  hasFilterOutInternal?: Maybe<Scalars['Boolean']>;
  onlyFacebookVerified?: Maybe<Scalars['Boolean']>;
};

export type QueryAdminGenerateDailyUpdateArgs = {
  recipientEmail?: Maybe<Scalars['String']>;
};

export type QueryGetDataAccessForUserArgs = {
  code?: Maybe<Scalars['String']>;
};

export type QueryRecentYoutubeMediaArgs = {
  id?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type QueryYoutubeMediaByViewsArgs = {
  id?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  asc?: Maybe<Scalars['Boolean']>;
};

export type QueryYoutubeMediaHistoricalArgs = {
  mediaId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type QueryListYoutubeChannelsArgs = {
  accessToken?: Maybe<Scalars['String']>;
};

export type RankedFacebookMedia = {
  __typename?: 'RankedFacebookMedia';
  topMedia?: Maybe<Array<Maybe<FacebookPost>>>;
  worstMedia?: Maybe<Array<Maybe<FacebookPost>>>;
};

export type RankedFacebookVideos = {
  __typename?: 'RankedFacebookVideos';
  topMedia?: Maybe<Array<Maybe<FacebookVideo>>>;
  worstMedia?: Maybe<Array<Maybe<FacebookVideo>>>;
};

export type RankedInstagramMedia = {
  __typename?: 'RankedInstagramMedia';
  topMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
  worstMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
};

export type RankedInstagramStories = {
  __typename?: 'RankedInstagramStories';
  topMedia?: Maybe<Array<Maybe<InstagramStory>>>;
  worstMedia?: Maybe<Array<Maybe<InstagramStory>>>;
};

export type RankedYoutubeMedia = {
  __typename?: 'RankedYoutubeMedia';
  topMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  worstMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
};

export type RateField = {
  __typename?: 'RateField';
  heading?: Maybe<Scalars['String']>;
  rates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RateInput = {
  heading?: Maybe<Scalars['String']>;
  rates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RatesContent = {
  __typename?: 'RatesContent';
  type?: Maybe<ElementType>;
  rate_content?: Maybe<Array<Maybe<RateField>>>;
};

export type RecentInstagramMediaStats = {
  __typename?: 'RecentInstagramMediaStats';
  grid?: Maybe<RecentMediaStats>;
  reels?: Maybe<RecentMediaStats>;
  stories?: Maybe<RecentMediaStats>;
};

export type RecentMediaStats = {
  __typename?: 'RecentMediaStats';
  last30Days?: Maybe<Array<RecentMediaSummary>>;
  last14Days?: Maybe<Array<RecentMediaSummary>>;
  last7Days?: Maybe<Array<RecentMediaSummary>>;
};

export type RecentMediaSummary = {
  __typename?: 'RecentMediaSummary';
  key?: Maybe<Scalars['String']>;
  median?: Maybe<Scalars['Float']>;
};

export type RecentProfile = {
  __typename?: 'RecentProfile';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  accountId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
};

export type RecentYoutubeMediaResult = {
  __typename?: 'RecentYoutubeMediaResult';
  mediaCount?: Maybe<Scalars['Int']>;
  youtubeMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  youtubeMediaAverages?: Maybe<YoutubeMediaStats>;
};

export type RegisterAmbassadorInput = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  detected_timezone?: Maybe<Scalars['String']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
};

export type ReportConfig = {
  __typename?: 'ReportConfig';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  /** @deprecated Use reportName. */
  report_name?: Maybe<Scalars['String']>;
  reportName?: Maybe<Scalars['String']>;
  /** @deprecated Use reportStart. */
  report_start?: Maybe<Scalars['Date']>;
  reportStart?: Maybe<Scalars['Date']>;
  /** @deprecated Use reportEnd. */
  report_end?: Maybe<Scalars['Date']>;
  reportEnd?: Maybe<Scalars['Date']>;
  channels?: Maybe<Array<Maybe<SocialChannel>>>;
  profileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use brandHandle. */
  brand_handle?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandHandle?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  textQuery?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_facebook_videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_tiktok_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  collaborationId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  shareToContactId?: Maybe<Scalars['ObjectId']>;
  viewedByContactAt?: Maybe<Scalars['Date']>;
  pdfLink?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  bitlyActionByCountry?: Maybe<Scalars['JSObject']>;
  challengeName?: Maybe<Scalars['String']>;
  /** @deprecated Use instagram_feed_count or instagram_reel_count */
  media_count?: Maybe<Scalars['Int']>;
  instagram_feed_count?: Maybe<Scalars['Int']>;
  instagram_reel_count?: Maybe<Scalars['Int']>;
  story_count?: Maybe<Scalars['Int']>;
  video_count?: Maybe<Scalars['Int']>;
  post_count?: Maybe<Scalars['Int']>;
  facebook_videos_count?: Maybe<Scalars['Int']>;
  facebook_reel_count?: Maybe<Scalars['Int']>;
  tiktok_media_count?: Maybe<Scalars['Int']>;
  trackMediaFrom?: Maybe<Scalars['Date']>;
  source?: Maybe<ReportConfigSource>;
  user?: Maybe<User>;
  reportStartFormatted?: Maybe<Scalars['String']>;
  reportLength?: Maybe<Scalars['Int']>;
  reportEndFormatted?: Maybe<Scalars['String']>;
  bitly?: Maybe<Scalars['JSObject']>;
  reportResults?: Maybe<ReportResults>;
  predictedInsights?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use reportResults and choose subfields */
  results?: Maybe<Scalars['JSObject']>;
  profiles?: Maybe<Array<Profile>>;
  reportLinks?: Maybe<Array<ReportLink>>;
  client?: Maybe<Contact>;
};

export type ReportConfigBitlyArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigReportResultsArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigPredictedInsightsArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigResultsArgs = {
  handleFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileIdFilter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportConfigInput = {
  report_name?: Maybe<Scalars['String']>;
  report_start?: Maybe<Scalars['Date']>;
  report_end?: Maybe<Scalars['Date']>;
  channels?: Maybe<Array<Maybe<SocialChannel>>>;
  profileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand_handle?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentions?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  textQuery?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_facebook_videos?: Maybe<Array<Maybe<Scalars['String']>>>;
  selected_tiktok_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  collaborationId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  shareToContactId?: Maybe<Scalars['ObjectId']>;
  challengeName?: Maybe<Scalars['String']>;
  trackMediaFrom?: Maybe<Scalars['Date']>;
  source?: Maybe<ReportConfigSource>;
};

export enum ReportConfigSource {
  Generated = 'GENERATED',
  Merged = 'MERGED',
  Received = 'RECEIVED'
}

export type ReportInstagramMediaStats = {
  __typename?: 'ReportInstagramMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  like_count?: Maybe<ReportMetric>;
  comments_count?: Maybe<ReportMetric>;
  reach?: Maybe<ReportMetric>;
  impressions?: Maybe<ReportMetric>;
  engagement?: Maybe<ReportMetric>;
  saved?: Maybe<ReportMetric>;
  video_views?: Maybe<ReportMetric>;
  comments?: Maybe<ReportMetric>;
  likes?: Maybe<ReportMetric>;
  plays?: Maybe<ReportMetric>;
  shares?: Maybe<ReportMetric>;
  total_interactions?: Maybe<ReportMetric>;
};

export type ReportInstagramStoryStats = {
  __typename?: 'ReportInstagramStoryStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  reach?: Maybe<ReportMetric>;
  impressions?: Maybe<ReportMetric>;
  exits?: Maybe<ReportMetric>;
  replies?: Maybe<ReportMetric>;
  taps_forward?: Maybe<ReportMetric>;
  taps_back?: Maybe<ReportMetric>;
};

export type ReportLink = {
  __typename?: 'ReportLink';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  contactId?: Maybe<Scalars['ObjectId']>;
  isOpened?: Maybe<Scalars['Boolean']>;
  openedAt?: Maybe<Scalars['Date']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  shortenedLink?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  reportConfig?: Maybe<ReportConfig>;
  contact?: Maybe<Contact>;
};

export type ReportMediaCounts = {
  __typename?: 'ReportMediaCounts';
  /** @deprecated Use instagramFeedCount and instagramReelsCount */
  mediaCount?: Maybe<Scalars['Int']>;
  instagramFeedCount?: Maybe<Scalars['Int']>;
  instagramReelsCount?: Maybe<Scalars['Int']>;
  storiesCount?: Maybe<Scalars['Int']>;
  videoCount?: Maybe<Scalars['Int']>;
  postCount?: Maybe<Scalars['Int']>;
  facebookVideoCount?: Maybe<Scalars['Int']>;
  facebookReelsCount?: Maybe<Scalars['Int']>;
  tiktokMediaCount?: Maybe<Scalars['Int']>;
  supplementMediaCounts?: Maybe<Array<Maybe<ReportSupplementMediaCounts>>>;
};

export type ReportMediaStats = {
  __typename?: 'ReportMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
};

export type ReportMentions = {
  __typename?: 'ReportMentions';
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportMetric = {
  __typename?: 'ReportMetric';
  average?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Int']>;
  latest?: Maybe<Scalars['Int']>;
};

export type ReportProfile = {
  __typename?: 'ReportProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  tokenUserId?: Maybe<Scalars['ObjectId']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<Scalars['JSObject']>;
  facebookVideoStats?: Maybe<Scalars['JSObject']>;
  facebookReelStats?: Maybe<Scalars['JSObject']>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  rankedFacebookVideos?: Maybe<Array<Maybe<FacebookVideo>>>;
  rankedFacebookReels?: Maybe<Array<Maybe<FacebookVideo>>>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  latestFacebookVideos?: Maybe<Array<FacebookVideo>>;
  latestFacebookReels?: Maybe<Array<FacebookVideo>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  facebookVideoTrends?: Maybe<FacebookVideoTrends>;
  facebookReelTrends?: Maybe<FacebookVideoTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<Array<Maybe<InstagramMedia>>>;
  rankedInstagramReels?: Maybe<Array<Maybe<InstagramMedia>>>;
  rankedInstagramStories?: Maybe<RankedInstagramStories>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<ReportInstagramMediaStats>;
  instagramFeedStats?: Maybe<ReportInstagramMediaStats>;
  instagramReelStats?: Maybe<ReportInstagramMediaStats>;
  storyStats?: Maybe<ReportInstagramStoryStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<ReportYoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<Scalars['JSObject']>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  notApproved?: Maybe<Scalars['Boolean']>;
  totalReach?: Maybe<Scalars['Int']>;
  totalImpressions?: Maybe<Scalars['Int']>;
  mediaByDay?: Maybe<Array<Maybe<MediaByDayStat>>>;
  /** @deprecated Use instagramFeed and instagramReels */
  media?: Maybe<Array<Maybe<InstagramMedia>>>;
  /** @deprecated Use rankedInstagramFeed or rankedInstagramReels */
  rankedMedia?: Maybe<Array<Maybe<InstagramMedia>>>;
  instagramFeed?: Maybe<Array<Maybe<InstagramMedia>>>;
  instagramReels?: Maybe<Array<Maybe<InstagramMedia>>>;
  stories?: Maybe<Array<Maybe<InstagramStory>>>;
  rankedStories?: Maybe<Array<Maybe<InstagramStory>>>;
  videos?: Maybe<Array<Maybe<YoutubeMedia>>>;
  rankedVideos?: Maybe<Array<Maybe<YoutubeMedia>>>;
  posts?: Maybe<Array<Maybe<FacebookPost>>>;
  rankedPosts?: Maybe<Array<Maybe<FacebookPost>>>;
  facebookVideos?: Maybe<Array<Maybe<FacebookVideo>>>;
  facebookReels?: Maybe<Array<Maybe<FacebookVideo>>>;
  tiktokMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
  supplementMediaStats?: Maybe<Array<Maybe<Scalars['JSObject']>>>;
  supplementMedia?: Maybe<Array<Maybe<SupplementMedia>>>;
  fromReceivedReports?: Maybe<Array<Maybe<ReportConfig>>>;
};

export type ReportProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ReportProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ReportProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type ReportProfileMetric = {
  __typename?: 'ReportProfileMetric';
  actual?: Maybe<Scalars['Int']>;
  average?: Maybe<Scalars['Float']>;
};

export type ReportResults = {
  __typename?: 'ReportResults';
  payload?: Maybe<ReportConfig>;
  profiles?: Maybe<Array<ReportProfile>>;
  profilesAll?: Maybe<Array<ReportProfile>>;
  mediaByDay?: Maybe<Array<MediaByDayStat>>;
  /** @deprecated Use rankedInstagramFeed or rankedInstagramReels */
  rankedMedia?: Maybe<Array<InstagramMedia>>;
  rankedInstagramFeed?: Maybe<Array<InstagramMedia>>;
  rankedInstagramReels?: Maybe<Array<InstagramMedia>>;
  rankedStories?: Maybe<Array<InstagramStory>>;
  rankedVideos?: Maybe<Array<YoutubeMedia>>;
  rankedPosts?: Maybe<Array<FacebookPost>>;
  rankedFacebookVideos?: Maybe<Array<FacebookVideo>>;
  rankedFacebookReels?: Maybe<Array<FacebookVideo>>;
  rankedTiktokMedia?: Maybe<Array<TiktokMedia>>;
  supplementMedia?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use allInstagramReels or allInstagramFeed */
  allMedia?: Maybe<Array<InstagramMedia>>;
  allInstagramReels?: Maybe<Array<InstagramMedia>>;
  allInstagramFeed?: Maybe<Array<InstagramMedia>>;
  allStories?: Maybe<Array<InstagramStory>>;
  allVideos?: Maybe<Array<YoutubeMedia>>;
  allPosts?: Maybe<Array<FacebookPost>>;
  allFacebookVideos?: Maybe<Array<FacebookVideo>>;
  allFacebookReels?: Maybe<Array<FacebookVideo>>;
  allTiktokMedia?: Maybe<Array<TiktokMedia>>;
  bitlyStats?: Maybe<Scalars['JSObject']>;
  totals?: Maybe<ReportTotals>;
  profileMediaCounts?: Maybe<ReportMediaCounts>;
  predictedInsights?: Maybe<Scalars['JSObject']>;
};

export type ReportShareContact = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
};

export type ReportSupplementMediaCounts = {
  __typename?: 'ReportSupplementMediaCounts';
  type?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ReportTotals = {
  __typename?: 'ReportTotals';
  profileStats?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use instagramFeedStats and instagramReelStats */
  mediaStats?: Maybe<ReportInstagramMediaStats>;
  instagramFeedStats?: Maybe<ReportInstagramMediaStats>;
  instagramReelStats?: Maybe<ReportInstagramMediaStats>;
  storyStats?: Maybe<ReportInstagramStoryStats>;
  videoStats?: Maybe<ReportYoutubeMediaStats>;
  postStats?: Maybe<Scalars['JSObject']>;
  facebookVideoStats?: Maybe<Scalars['JSObject']>;
  facebookReelStats?: Maybe<Scalars['JSObject']>;
  tiktokMediaStats?: Maybe<Scalars['JSObject']>;
  supplementMediaStats?: Maybe<Scalars['JSObject']>;
};

export type ReportYoutubeMediaStats = {
  __typename?: 'ReportYoutubeMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  views?: Maybe<ReportMetric>;
  averageViewDuration?: Maybe<ReportMetric>;
  averageViewPercentage?: Maybe<ReportMetric>;
  estimatedMinutesWatched?: Maybe<ReportMetric>;
  cardClicks?: Maybe<ReportMetric>;
  cardTeaserClicks?: Maybe<ReportMetric>;
  likes?: Maybe<ReportMetric>;
  comments?: Maybe<ReportMetric>;
};

export type ResourceId = {
  __typename?: 'ResourceId';
  kind?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type RichTextContent = {
  __typename?: 'RichTextContent';
  type?: Maybe<ElementType>;
  rich_text_content?: Maybe<Scalars['String']>;
};

export type RybbonReward = {
  __typename?: 'RybbonReward';
  rybbon_reference_code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SelectedMediaContent = {
  __typename?: 'SelectedMediaContent';
  type?: Maybe<ElementType>;
  selected_media_content?: Maybe<SelectedMediaField>;
};

export type SelectedMediaField = {
  __typename?: 'SelectedMediaField';
  profileId?: Maybe<Scalars['String']>;
  instagram_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  instagram_media_data?: Maybe<Array<Maybe<InstagramMedia>>>;
  instagram_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  youtube_media?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SelectedMediaInput = {
  profileId?: Maybe<Scalars['String']>;
  instagram_media?: Maybe<Array<Maybe<Scalars['String']>>>;
  instagram_stories?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebook_posts?: Maybe<Array<Maybe<Scalars['String']>>>;
  youtube_media?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SentEmail = {
  __typename?: 'SentEmail';
  _id: Scalars['ObjectId'];
  recipientEmail?: Maybe<Scalars['String']>;
  recipientFirstName?: Maybe<Scalars['String']>;
  recipientLastName?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['ObjectId']>;
  kind?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  shouldHideInUI?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  messageText?: Maybe<Scalars['String']>;
  /** @deprecated Use recipientUser */
  user?: Maybe<User>;
  recipientUser?: Maybe<User>;
  senderUser?: Maybe<User>;
};

export type ServerResponse = {
  __typename?: 'ServerResponse';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  insertedId?: Maybe<Scalars['ObjectId']>;
  insertedIdList?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type ShareCollaborationInvoiceDetails = {
  contactId?: Maybe<Scalars['ObjectId']>;
  contactIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  contactDetails?: Maybe<InvoiceShareContactInput>;
  saveContact?: Maybe<Scalars['Boolean']>;
};

export enum SocialChannel {
  Instagram = 'INSTAGRAM',
  Youtube = 'YOUTUBE',
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
  Twitch = 'TWITCH',
  Snapchat = 'SNAPCHAT',
  Tiktok = 'TIKTOK',
  Blog = 'BLOG',
  Linkedin = 'LINKEDIN',
  Videography = 'VIDEOGRAPHY',
  Photography = 'PHOTOGRAPHY',
  Podcast = 'PODCAST',
  Appearance = 'APPEARANCE',
  Custom = 'CUSTOM'
}

export enum SocialDeliverable {
  IgTv = 'IG_TV',
  IgStory = 'IG_STORY',
  IgGridPost = 'IG_GRID_POST',
  IgReel = 'IG_REEL',
  IgLive = 'IG_LIVE',
  IgImageCarousel = 'IG_IMAGE_CAROUSEL',
  Photoshoot = 'PHOTOSHOOT',
  YtVideo = 'YT_VIDEO',
  FbPagePost = 'FB_PAGE_POST',
  ScVideo = 'SC_VIDEO',
  ScImage = 'SC_IMAGE',
  Retweet = 'RETWEET',
  TwVideo = 'TW_VIDEO',
  TwImage = 'TW_IMAGE',
  Tweet = 'TWEET',
  TtVideo = 'TT_VIDEO',
  TtShare = 'TT_SHARE',
  Article = 'ARTICLE',
  BlVideo = 'BL_VIDEO',
  BlShare = 'BL_SHARE',
  Byline = 'BYLINE',
  LiStory = 'LI_STORY',
  LiPost = 'LI_POST',
  LiShare = 'LI_SHARE',
  LiPublishedArticle = 'LI_PUBLISHED_ARTICLE',
  Custom = 'CUSTOM',
  LinkInBio = 'LINK_IN_BIO',
  IgStoryHighlight = 'IG_STORY_HIGHLIGHT',
  TcVideo = 'TC_VIDEO',
  TcLiveStream = 'TC_LIVE_STREAM',
  TcMention = 'TC_MENTION',
  TcTournament = 'TC_TOURNAMENT',
  ViOneHourShoot = 'VI_ONE_HOUR_SHOOT',
  ViHalfDayShoot = 'VI_HALF_DAY_SHOOT',
  ViFullDayShoot = 'VI_FULL_DAY_SHOOT',
  ViEditing = 'VI_EDITING',
  PhOneHourShoot = 'PH_ONE_HOUR_SHOOT',
  PhHalfDayShoot = 'PH_HALF_DAY_SHOOT',
  PhFullDayShoot = 'PH_FULL_DAY_SHOOT',
  PhEditing = 'PH_EDITING',
  PoMention = 'PO_MENTION',
  PoSegmentAd = 'PO_SEGMENT_AD',
  PoInterview = 'PO_INTERVIEW',
  ApMeetAndGreet = 'AP_MEET_AND_GREET',
  ApInterview = 'AP_INTERVIEW',
  ApVideoMessage = 'AP_VIDEO_MESSAGE',
  ApKeyNote = 'AP_KEY_NOTE'
}

export type SocialProfile = {
  __typename?: 'SocialProfile';
  _id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  cache_error?: Maybe<Scalars['String']>;
};

export type SortField = {
  field?: Maybe<Scalars['String']>;
  sortAscending?: Maybe<Scalars['Boolean']>;
};

export type StoriesForMonthResponse = {
  __typename?: 'StoriesForMonthResponse';
  stories?: Maybe<Array<Maybe<InstagramStory>>>;
  date?: Maybe<Scalars['Date']>;
};

export type StripeAccountLink = {
  __typename?: 'StripeAccountLink';
  object?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  expires_at?: Maybe<Scalars['StripeTimestamp']>;
  url?: Maybe<Scalars['String']>;
};

export type StripeCard = {
  __typename?: 'StripeCard';
  exp_year?: Maybe<Scalars['Int']>;
  exp_month?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export type StripeCharge = {
  __typename?: 'StripeCharge';
  id: Scalars['ID'];
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  refunded?: Maybe<Scalars['Boolean']>;
  receipt_url?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
};

export type StripeConnectedAccount = {
  __typename?: 'StripeConnectedAccount';
  id: Scalars['ID'];
  business_profile?: Maybe<Scalars['JSObject']>;
  charges_enabled?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  default_currency?: Maybe<Scalars['String']>;
  details_submitted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSObject']>;
  payouts_enabled?: Maybe<Scalars['Boolean']>;
  business_type?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSObject']>;
  type?: Maybe<Scalars['String']>;
  expressDashboardLink?: Maybe<Scalars['String']>;
};

export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  id: Scalars['ID'];
  address?: Maybe<Scalars['JSObject']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSObject']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['JSObject']>;
  balance?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  currency?: Maybe<Scalars['String']>;
  default_source?: Maybe<Scalars['String']>;
  delinquent?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<Scalars['JSObject']>;
  invoice_prefix?: Maybe<Scalars['String']>;
  invoice_settings?: Maybe<Scalars['JSObject']>;
  livemode?: Maybe<Scalars['Boolean']>;
  next_invoice_sequence?: Maybe<Scalars['Int']>;
  preferred_locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  sources?: Maybe<Scalars['JSObject']>;
  tax_exempt?: Maybe<Scalars['String']>;
  defaultPaymentMethod?: Maybe<Scalars['String']>;
  firstSubscription?: Maybe<StripeSubscription>;
  activeSubscriptionSchedules?: Maybe<Array<StripeSubscriptionSchedule>>;
  cards?: Maybe<Array<StripePaymentMethod>>;
  charges?: Maybe<Array<StripeCharge>>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  id: Scalars['ID'];
  account_country?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  account_tax_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  amount_due?: Maybe<Scalars['Int']>;
  amount_paid?: Maybe<Scalars['Int']>;
  amount_remaining?: Maybe<Scalars['Int']>;
  application_fee_amount?: Maybe<Scalars['Int']>;
  attempt_count?: Maybe<Scalars['Int']>;
  attempted?: Maybe<Scalars['Boolean']>;
  automatic_tax?: Maybe<Scalars['JSObject']>;
  auto_advance?: Maybe<Scalars['Boolean']>;
  billing_reason?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['ID']>;
  collection_method?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  custom_fields?: Maybe<Array<Scalars['JSObject']>>;
  customer_address?: Maybe<Scalars['JSObject']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_phone?: Maybe<Scalars['String']>;
  customer_shipping?: Maybe<Scalars['JSObject']>;
  customer_tax_exempt?: Maybe<Scalars['String']>;
  customer_tax_ids?: Maybe<Array<Scalars['ID']>>;
  default_payment_method?: Maybe<Scalars['String']>;
  default_source?: Maybe<Scalars['String']>;
  default_tax_rates?: Maybe<Scalars['JSObject']>;
  discounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['StripeTimestamp']>;
  ending_balance?: Maybe<Scalars['Int']>;
  footer?: Maybe<Scalars['String']>;
  invoice_pdf?: Maybe<Scalars['String']>;
  hosted_invoice_url?: Maybe<Scalars['String']>;
  last_finalization_error?: Maybe<Scalars['JSObject']>;
  lines?: Maybe<Scalars['JSObject']>;
  livemode?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSObject']>;
  next_payment_attempt?: Maybe<Scalars['StripeTimestamp']>;
  number?: Maybe<Scalars['String']>;
  on_behalf_of?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  payment_intent?: Maybe<Scalars['String']>;
  period_end?: Maybe<Scalars['StripeTimestamp']>;
  period_start?: Maybe<Scalars['StripeTimestamp']>;
  status?: Maybe<Scalars['String']>;
  status_transitions?: Maybe<StripeStatusTransition>;
  subscription?: Maybe<Scalars['ID']>;
  subscription_proration_date?: Maybe<Scalars['Int']>;
  subtotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type StripePaymentMethod = {
  __typename?: 'StripePaymentMethod';
  id: Scalars['ID'];
  card?: Maybe<StripeCard>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  id: Scalars['ID'];
  unit_amount_decimal?: Maybe<Scalars['String']>;
  unit_amount?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  billing_scheme?: Maybe<Scalars['String']>;
};

export type StripeStatusTransition = {
  __typename?: 'StripeStatusTransition';
  paid_at?: Maybe<Scalars['Float']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  id: Scalars['ID'];
  application_fee_percent?: Maybe<Scalars['Float']>;
  billing_cycle_anchor?: Maybe<Scalars['StripeTimestamp']>;
  cancel_at?: Maybe<Scalars['StripeTimestamp']>;
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  canceled_at?: Maybe<Scalars['StripeTimestamp']>;
  collection_method?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  current_period_end?: Maybe<Scalars['StripeTimestamp']>;
  current_period_start?: Maybe<Scalars['StripeTimestamp']>;
  customer?: Maybe<Scalars['String']>;
  days_until_due?: Maybe<Scalars['Int']>;
  default_payment_method?: Maybe<Scalars['ID']>;
  default_source?: Maybe<Scalars['ID']>;
  default_tax_rates?: Maybe<Array<Maybe<Scalars['JSObject']>>>;
  discount?: Maybe<Scalars['JSObject']>;
  ended_at?: Maybe<Scalars['StripeTimestamp']>;
  items?: Maybe<Scalars['JSObject']>;
  latest_invoice?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSObject']>;
  next_pending_invoice_item_invoice?: Maybe<Scalars['StripeTimestamp']>;
  status?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['StripeTimestamp']>;
  transfer_data?: Maybe<Scalars['JSObject']>;
  trial_end?: Maybe<Scalars['StripeTimestamp']>;
  trial_start?: Maybe<Scalars['StripeTimestamp']>;
  firstItem?: Maybe<StripeSubscriptionItem>;
};

export type StripeSubscriptionItem = {
  __typename?: 'StripeSubscriptionItem';
  id: Scalars['ID'];
  subscription?: Maybe<Scalars['String']>;
  price?: Maybe<StripePrice>;
  plan?: Maybe<Scalars['JSObject']>;
  metadata?: Maybe<Scalars['JSObject']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  billing_thresholds?: Maybe<Scalars['JSObject']>;
  tax_rates?: Maybe<Array<Scalars['JSObject']>>;
};

export type StripeSubscriptionSchedule = {
  __typename?: 'StripeSubscriptionSchedule';
  id: Scalars['ID'];
  subscription?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  released_at?: Maybe<Scalars['StripeTimestamp']>;
  phases?: Maybe<Array<Maybe<Scalars['JSObject']>>>;
  metadata?: Maybe<Scalars['JSObject']>;
  end_behavior?: Maybe<Scalars['JSObject']>;
  default_settings?: Maybe<Scalars['JSObject']>;
  customer?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  current_phase?: Maybe<StripeSubscriptionScheduleCurrentPhase>;
  completed_at?: Maybe<Scalars['StripeTimestamp']>;
  canceled_at?: Maybe<Scalars['StripeTimestamp']>;
};

export type StripeSubscriptionScheduleCurrentPhase = {
  __typename?: 'StripeSubscriptionScheduleCurrentPhase';
  start_date?: Maybe<Scalars['StripeTimestamp']>;
  end_date?: Maybe<Scalars['StripeTimestamp']>;
};

export type StripeTaxRate = {
  __typename?: 'StripeTaxRate';
  id: Scalars['ID'];
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['StripeTimestamp']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  jurisdiction?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSObject']>;
  percentage?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
};

export type SubscriberChange = {
  __typename?: 'SubscriberChange';
  day?: Maybe<Scalars['String']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
};

export type SubscriptionsAtDate = {
  __typename?: 'SubscriptionsAtDate';
  date?: Maybe<Scalars['Date']>;
  plans?: Maybe<Array<Maybe<PlanStat>>>;
};

export type SupplementMedia = {
  __typename?: 'SupplementMedia';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  mediaType?: Maybe<SupplementMediaType>;
  insights?: Maybe<SupplementMediaInsights>;
  mediaUrl?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  mediaIsVideo?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  profile?: Maybe<Profile>;
  reportConfig?: Maybe<ReportConfig>;
};

export type SupplementMediaInput = {
  profileId?: Maybe<Scalars['String']>;
  reportConfigId?: Maybe<Scalars['ObjectId']>;
  mediaType?: Maybe<SupplementMediaType>;
  insights?: Maybe<SupplementMediaInsightsInput>;
  mediaUrl?: Maybe<Scalars['String']>;
  mediaUpload?: Maybe<Scalars['Upload']>;
  caption?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  mediaIsVideo?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['String']>;
  mediaInputUrl?: Maybe<Scalars['String']>;
};

export type SupplementMediaInsights = {
  __typename?: 'SupplementMediaInsights';
  reach?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  contentInteractions?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  saves?: Maybe<Scalars['Int']>;
};

export type SupplementMediaInsightsInput = {
  reach?: Maybe<Scalars['Int']>;
  plays?: Maybe<Scalars['Int']>;
  contentInteractions?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  saves?: Maybe<Scalars['Int']>;
};

export enum SupplementMediaType {
  InstagramReel = 'INSTAGRAM_REEL',
  InstagramStory = 'INSTAGRAM_STORY',
  TiktokVideo = 'TIKTOK_VIDEO'
}

export type TalentNetworkContactEmailPayload = {
  senderName?: Maybe<Scalars['String']>;
  senderCompanyName?: Maybe<Scalars['String']>;
  recipientUserId?: Maybe<Scalars['ObjectId']>;
  messageText?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  collaborationId?: Maybe<Scalars['ObjectId']>;
};

export type TextContent = {
  __typename?: 'TextContent';
  type?: Maybe<ElementType>;
  text_content?: Maybe<Scalars['String']>;
};

export type ThumbnailInfo = {
  __typename?: 'ThumbnailInfo';
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type Thumbnails = {
  __typename?: 'Thumbnails';
  default?: Maybe<ThumbnailInfo>;
  medium?: Maybe<ThumbnailInfo>;
  high?: Maybe<ThumbnailInfo>;
  standard?: Maybe<ThumbnailInfo>;
  maxres?: Maybe<ThumbnailInfo>;
};

export type TikTokPublicReport = {
  __typename?: 'TikTokPublicReport';
  profile?: Maybe<TiktokProfile>;
  media?: Maybe<Array<Maybe<TiktokPublicVideo>>>;
};

export type TikTokRankedMedia = {
  __typename?: 'TikTokRankedMedia';
  topMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
  worstMedia?: Maybe<Array<Maybe<TiktokMedia>>>;
};

export type TiktokChallenge = {
  __typename?: 'TiktokChallenge';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  profileThumb?: Maybe<Scalars['String']>;
  profileMedium?: Maybe<Scalars['String']>;
  profileLarger?: Maybe<Scalars['String']>;
  coverThumb?: Maybe<Scalars['String']>;
  coverMedium?: Maybe<Scalars['String']>;
  coverLarger?: Maybe<Scalars['String']>;
  isCommerce?: Maybe<Scalars['Boolean']>;
};

export type TiktokHistorical = {
  __typename?: 'TiktokHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['String']>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  change?: Maybe<TiktokHistorical>;
  archived_at?: Maybe<Scalars['Date']>;
};

export type TiktokHistoricalAverages = {
  __typename?: 'TiktokHistoricalAverages';
  start?: Maybe<Scalars['String']>;
  days?: Maybe<Array<Maybe<TiktokHistorical>>>;
  public?: Maybe<TiktokPublicUserAverages>;
  tiktokMediaStats?: Maybe<TiktokMediaStatsAverages>;
};

export type TiktokMedia = {
  __typename?: 'TiktokMedia';
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  hashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  handles?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Date']>;
  totalEngagement?: Maybe<Scalars['Int']>;
  isViralVideo?: Maybe<Scalars['Boolean']>;
  official?: Maybe<TiktokOfficialVideo>;
  public?: Maybe<TiktokPublicVideo>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  archived_at?: Maybe<Scalars['Date']>;
  latest_record?: Maybe<TiktokMedia>;
  profile?: Maybe<Profile>;
  cachedCoverImageUrl?: Maybe<Scalars['String']>;
  cachedVideoUrl?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<TiktokMediaHistorical>>>;
};

export type TiktokMediaAverages = {
  __typename?: 'TiktokMediaAverages';
  date?: Maybe<Scalars['Date']>;
  totalEngagement?: Maybe<Scalars['Float']>;
  official?: Maybe<TiktokMediaOfficialAverages>;
  public?: Maybe<TiktokMediaPublicAverages>;
};

export type TiktokMediaHistorical = {
  __typename?: 'TiktokMediaHistorical';
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  totalEngagement?: Maybe<Scalars['Float']>;
  official?: Maybe<TiktokOfficialVideo>;
  public?: Maybe<TiktokPublicVideoStats>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type TiktokMediaOfficialAverages = {
  __typename?: 'TiktokMediaOfficialAverages';
  like_count?: Maybe<Scalars['Float']>;
  comment_count?: Maybe<Scalars['Float']>;
  share_count?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
};

export type TiktokMediaPublicAverages = {
  __typename?: 'TiktokMediaPublicAverages';
  commentCount?: Maybe<Scalars['Float']>;
  diggCount?: Maybe<Scalars['Float']>;
  playCount?: Maybe<Scalars['Float']>;
  shareCount?: Maybe<Scalars['Float']>;
};

export type TiktokMediaStats = {
  __typename?: 'TiktokMediaStats';
  count?: Maybe<Scalars['Int']>;
  days?: Maybe<Scalars['Int']>;
  postsPerDay?: Maybe<Scalars['Float']>;
  topHashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  viralVideoPercentage?: Maybe<Scalars['Float']>;
  totalEngagement?: Maybe<MetricSummary>;
  totalEngagement_percentageOfPlays?: Maybe<MetricSummary>;
  view_count?: Maybe<MetricSummary>;
  like_count?: Maybe<MetricSummary>;
  like_count_percentageOfPlays?: Maybe<MetricSummary>;
  comment_count?: Maybe<MetricSummary>;
  comment_count_percentageOfPlays?: Maybe<MetricSummary>;
  share_count?: Maybe<MetricSummary>;
  share_count_percentageOfPlays?: Maybe<MetricSummary>;
  diggCount?: Maybe<MetricSummary>;
  diggCount_percentageOfPlays?: Maybe<MetricSummary>;
};

export type TiktokMediaStatsAverages = {
  __typename?: 'TiktokMediaStatsAverages';
  viralVideoPercentage?: Maybe<Scalars['Float']>;
  totalEngagement?: Maybe<Scalars['Float']>;
  totalEngagement_percentageOfPlays?: Maybe<Scalars['Float']>;
  view_count?: Maybe<Scalars['Float']>;
  like_count?: Maybe<Scalars['Float']>;
  like_count_percentageOfPlays?: Maybe<Scalars['Float']>;
  comment_count?: Maybe<Scalars['Float']>;
  comment_count_percentageOfPlays?: Maybe<Scalars['Float']>;
  share_count?: Maybe<Scalars['Float']>;
  share_count_percentageOfPlays?: Maybe<Scalars['Float']>;
  diggCount?: Maybe<Scalars['Float']>;
  diggCount_percentageOfPlays?: Maybe<Scalars['Float']>;
};

export type TiktokMediaTrends = {
  __typename?: 'TiktokMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<TiktokMediaAverages>>>;
  recentAverages?: Maybe<TiktokMediaAverages>;
  comparisonAverages?: Maybe<TiktokMediaAverages>;
};

export type TiktokMusic = {
  __typename?: 'TiktokMusic';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  playUrl?: Maybe<Scalars['String']>;
  coverThumb?: Maybe<Scalars['String']>;
  coverMedium?: Maybe<Scalars['String']>;
  coverLarge?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  album?: Maybe<Scalars['String']>;
};

export type TiktokOfficialVideo = {
  __typename?: 'TiktokOfficialVideo';
  create_time?: Maybe<Scalars['Float']>;
  cover_image_url?: Maybe<Scalars['String']>;
  share_url?: Maybe<Scalars['String']>;
  video_description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  embed_html?: Maybe<Scalars['String']>;
  embed_link?: Maybe<Scalars['String']>;
  like_count?: Maybe<Scalars['Int']>;
  comment_count?: Maybe<Scalars['Int']>;
  share_count?: Maybe<Scalars['Int']>;
  view_count?: Maybe<Scalars['Int']>;
};

export type TiktokProfile = {
  __typename?: 'TiktokProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  tokenUserId?: Maybe<Scalars['ObjectId']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  facebookVideoStats?: Maybe<FacebookVideoStats>;
  facebookReelStats?: Maybe<FacebookVideoStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  rankedFacebookVideos?: Maybe<RankedFacebookVideos>;
  rankedFacebookReels?: Maybe<RankedFacebookVideos>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  latestFacebookVideos?: Maybe<Array<FacebookVideo>>;
  latestFacebookReels?: Maybe<Array<FacebookVideo>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  facebookVideoTrends?: Maybe<FacebookVideoTrends>;
  facebookReelTrends?: Maybe<FacebookVideoTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  rankedInstagramStories?: Maybe<RankedInstagramStories>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<AgeGroup>>;
  genderBreakdown?: Maybe<Array<GenderGroup>>;
  locationBreakdown?: Maybe<Array<LocationGroup>>;
  myChannelReport?: Maybe<Array<ChannelReport>>;
  subscriberChange?: Maybe<Array<SubscriberChange>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  refreshToken?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  open_id?: Maybe<Scalars['String']>;
  avatar_url?: Maybe<Scalars['String']>;
  avatar_large_url?: Maybe<Scalars['String']>;
  avatar_url_100?: Maybe<Scalars['String']>;
  secUid?: Maybe<Scalars['String']>;
};

export type TiktokProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TiktokProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TiktokProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TiktokPublicUser = {
  __typename?: 'TiktokPublicUser';
  id?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatarThumb?: Maybe<Scalars['String']>;
  avatarMedium?: Maybe<Scalars['String']>;
  avatarLarger?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  secUid?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['Boolean']>;
  ftc?: Maybe<Scalars['Boolean']>;
  relation?: Maybe<Scalars['Int']>;
  openFavorite?: Maybe<Scalars['Boolean']>;
  commentSetting?: Maybe<Scalars['Int']>;
  duetSetting?: Maybe<Scalars['Int']>;
  stitchSetting?: Maybe<Scalars['Int']>;
  privateAccount?: Maybe<Scalars['Boolean']>;
  stats?: Maybe<TiktokUserStats>;
};

export type TiktokPublicUserAverages = {
  __typename?: 'TiktokPublicUserAverages';
  stats?: Maybe<TiktokPublicUserStatAverages>;
};

export type TiktokPublicUserStatAverages = {
  __typename?: 'TiktokPublicUserStatAverages';
  followingCount?: Maybe<Scalars['Float']>;
  followerCount?: Maybe<Scalars['Float']>;
  heartCount?: Maybe<Scalars['Float']>;
  videoCount?: Maybe<Scalars['Float']>;
  diggCount?: Maybe<Scalars['Float']>;
  heart?: Maybe<Scalars['Float']>;
};

export type TiktokPublicVideo = {
  __typename?: 'TiktokPublicVideo';
  id?: Maybe<Scalars['ID']>;
  desc?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Int']>;
  video?: Maybe<TiktokVideo>;
  author?: Maybe<TiktokPublicUser>;
  music?: Maybe<TiktokMusic>;
  challenges?: Maybe<TiktokChallenge>;
  stats?: Maybe<TiktokPublicVideoStats>;
  originalItem?: Maybe<Scalars['Boolean']>;
  officalItem?: Maybe<Scalars['Boolean']>;
  textExtra?: Maybe<Array<Maybe<TiktokTextExtra>>>;
  secret?: Maybe<Scalars['Boolean']>;
  forFriend?: Maybe<Scalars['Boolean']>;
  digged?: Maybe<Scalars['Boolean']>;
  itemCommentStatus?: Maybe<Scalars['Int']>;
  showNotPass?: Maybe<Scalars['Boolean']>;
  vl1?: Maybe<Scalars['Boolean']>;
  itemMute?: Maybe<Scalars['Boolean']>;
  authorStats?: Maybe<TiktokUserStats>;
  privateItem?: Maybe<Scalars['Boolean']>;
  duetEnabled?: Maybe<Scalars['Boolean']>;
  stitchEnabled?: Maybe<Scalars['Boolean']>;
  shareEnabled?: Maybe<Scalars['Boolean']>;
  isAd?: Maybe<Scalars['Boolean']>;
};

export type TiktokPublicVideoStats = {
  __typename?: 'TiktokPublicVideoStats';
  commentCount?: Maybe<Scalars['Int']>;
  diggCount?: Maybe<Scalars['Int']>;
  playCount?: Maybe<Scalars['Int']>;
  shareCount?: Maybe<Scalars['Int']>;
};

export type TiktokTextExtra = {
  __typename?: 'TiktokTextExtra';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  playUrl?: Maybe<Scalars['String']>;
  coverThumb?: Maybe<Scalars['String']>;
  coverMedium?: Maybe<Scalars['String']>;
  coverLarge?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  album?: Maybe<Scalars['String']>;
};

export type TiktokUser = {
  __typename?: 'TiktokUser';
  id?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  avatarThumb?: Maybe<Scalars['String']>;
  avatarMedium?: Maybe<Scalars['String']>;
  avatarLarger?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
  secUid?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['Boolean']>;
  ftc?: Maybe<Scalars['Boolean']>;
  relation?: Maybe<Scalars['Int']>;
  openFavorite?: Maybe<Scalars['Boolean']>;
  commentSetting?: Maybe<Scalars['Int']>;
  duetSetting?: Maybe<Scalars['Int']>;
  stitchSetting?: Maybe<Scalars['Int']>;
  privateAccount?: Maybe<Scalars['Boolean']>;
  shortId?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['Int']>;
  bioLink?: Maybe<Scalars['JSObject']>;
  isADVirtual?: Maybe<Scalars['Boolean']>;
  roomId?: Maybe<Scalars['String']>;
};

export type TiktokUserStats = {
  __typename?: 'TiktokUserStats';
  followingCount?: Maybe<Scalars['Int']>;
  followerCount?: Maybe<Scalars['Int']>;
  heartCount?: Maybe<Scalars['Int']>;
  videoCount?: Maybe<Scalars['Int']>;
  diggCount?: Maybe<Scalars['Int']>;
  heart?: Maybe<Scalars['Int']>;
};

export type TiktokVideo = {
  __typename?: 'TiktokVideo';
  id?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  ratio?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  originCover?: Maybe<Scalars['String']>;
  dynamicCover?: Maybe<Scalars['String']>;
  playAddr?: Maybe<Scalars['String']>;
  downloadAddr?: Maybe<Scalars['String']>;
  shareCover?: Maybe<Array<Maybe<Scalars['String']>>>;
  reflowCover?: Maybe<Scalars['String']>;
};

export type TopDemographic = {
  __typename?: 'TopDemographic';
  key?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  actual?: Maybe<Scalars['Int']>;
};

export type TopMentions = {
  __typename?: 'TopMentions';
  topHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
  topTagHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topTagHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TopStoryMentions = {
  __typename?: 'TopStoryMentions';
  topStoryHandles?: Maybe<Array<Maybe<Scalars['String']>>>;
  topStoryHashes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrackingLink = {
  __typename?: 'TrackingLink';
  _id: Scalars['ObjectId'];
  link?: Maybe<Scalars['String']>;
  bitlinkId?: Maybe<Scalars['String']>;
  longUrl?: Maybe<Scalars['String']>;
  customLink?: Maybe<Scalars['String']>;
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
  actionType?: Maybe<Scalars['String']>;
  assignedProfile?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ObjectId']>;
  countries?: Maybe<Scalars['JSObject']>;
  cities?: Maybe<TrackingLinkCityMetrics>;
  devices?: Maybe<Scalars['JSObject']>;
  referrers?: Maybe<Scalars['JSObject']>;
  referring_domains?: Maybe<Scalars['JSObject']>;
  created_at?: Maybe<Scalars['Date']>;
  report_id?: Maybe<Scalars['String']>;
  totalClicks?: Maybe<Scalars['Int']>;
  historical_from?: Maybe<Scalars['Date']>;
  summary?: Maybe<BitlinkSummary>;
  clicksByCountry?: Maybe<Array<TrackingLinkMetric>>;
};

export type TrackingLinkCityMetrics = {
  __typename?: 'TrackingLinkCityMetrics';
  other_city_clicks?: Maybe<Scalars['Float']>;
  no_city_clicks?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['JSObject']>;
  subregion?: Maybe<Scalars['JSObject']>;
  region?: Maybe<Scalars['JSObject']>;
  country?: Maybe<Scalars['JSObject']>;
};

export type TrackingLinkHistorical = {
  __typename?: 'TrackingLinkHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  bitlinkId?: Maybe<Scalars['String']>;
  countries?: Maybe<Scalars['JSObject']>;
  cities?: Maybe<TrackingLinkCityMetrics>;
  devices?: Maybe<Scalars['JSObject']>;
  referrers?: Maybe<Scalars['JSObject']>;
  referring_domains?: Maybe<Scalars['JSObject']>;
  date?: Maybe<Scalars['Date']>;
  report_id?: Maybe<Scalars['String']>;
  totalClicks?: Maybe<Scalars['Int']>;
};

export type TrackingLinkInput = {
  _id?: Maybe<Scalars['ObjectId']>;
  longUrl?: Maybe<Scalars['String']>;
  customLink?: Maybe<Scalars['String']>;
  type?: Maybe<TrackingLinkType>;
  target?: Maybe<Scalars['String']>;
  actionType?: Maybe<Scalars['String']>;
  assignedProfile?: Maybe<Scalars['String']>;
};

export type TrackingLinkMetric = {
  __typename?: 'TrackingLinkMetric';
  value?: Maybe<Scalars['String']>;
  clicks?: Maybe<Scalars['Int']>;
};

export enum TrackingLinkType {
  Campaign = 'CAMPAIGN',
  Report = 'REPORT',
  Deliverable = 'DELIVERABLE'
}

export type User = {
  __typename?: 'User';
  _id: Scalars['ObjectId'];
  firstName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'firstName'. */
  first_name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'lastName'. */
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profileCount?: Maybe<Scalars['Int']>;
  type?: Maybe<UserType>;
  subType?: Maybe<UserSubType>;
  timezone?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'companyName'. */
  company_name?: Maybe<Scalars['String']>;
  /** @deprecated Use 'companyName'. */
  agency?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<UserAddress>;
  businessIdentifier?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<UserPaymentDetails>;
  avatar?: Maybe<Scalars['String']>;
  inviteSent?: Maybe<Scalars['Date']>;
  invite_sent?: Maybe<Scalars['Date']>;
  walkthrough?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountProgress?: Maybe<Array<Maybe<Scalars['String']>>>;
  signupPlanId?: Maybe<Scalars['String']>;
  invitedByBrandId?: Maybe<Scalars['ObjectId']>;
  invitedByTalentId?: Maybe<Scalars['ObjectId']>;
  invitedFromAmbassadorId?: Maybe<Scalars['ObjectId']>;
  country?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  isNotificationOn?: Maybe<Scalars['Boolean']>;
  planId?: Maybe<Scalars['String']>;
  isMfaEnabled?: Maybe<Scalars['Boolean']>;
  doesRequireReauthentication?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isAppleVerified?: Maybe<Scalars['Boolean']>;
  isYoutubeVerified?: Maybe<Scalars['Boolean']>;
  isFacebookVerified?: Maybe<Scalars['Boolean']>;
  isGoogleVerified?: Maybe<Scalars['Boolean']>;
  isTiktokVerified?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isAdminVerified?: Maybe<Scalars['Boolean']>;
  isAddedToMonday?: Maybe<Scalars['Boolean']>;
  emailVerificationToken?: Maybe<Scalars['String']>;
  /** @deprecated Use 'emailVerificationToken'. */
  email_verification_token?: Maybe<Scalars['String']>;
  adminVerificationToken?: Maybe<Scalars['String']>;
  /** @deprecated Use 'adminVerificationToken'. */
  admin_verification_token?: Maybe<Scalars['String']>;
  braintreeSubscriptionId?: Maybe<Scalars['String']>;
  braintreeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeAccountId?: Maybe<Scalars['String']>;
  stripeTaxRate?: Maybe<Scalars['String']>;
  stripeTaxRateDetails?: Maybe<StripeTaxRate>;
  delegateUserId?: Maybe<Scalars['ObjectId']>;
  profileProgress?: Maybe<Scalars['Int']>;
  longToken?: Maybe<Scalars['String']>;
  facebookMarketingToken?: Maybe<Scalars['String']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  lastLoggedIn?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  activeNotifications?: Maybe<ActiveNotifications>;
  sqs_url?: Maybe<Scalars['String']>;
  awsTemporaryCredentials?: Maybe<AwsTemporaryCredentials>;
  talentInviteLink?: Maybe<Scalars['String']>;
  googleUserId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  facebookAccountName?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  ambassadors?: Maybe<Array<Maybe<Ambassador>>>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  collabCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  collabStages?: Maybe<Array<Maybe<Scalars['String']>>>;
  signupInfo?: Maybe<UserSignupInfo>;
  /** @deprecated Use 'subType'. */
  isAgency?: Maybe<Scalars['Boolean']>;
  websiteUrl?: Maybe<Scalars['String']>;
  tiktokUnionId?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['ObjectId']>;
  facebookUser?: Maybe<FacebookUser>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  signupCouponCode?: Maybe<Scalars['String']>;
  userAuditReportLink?: Maybe<Scalars['String']>;
  dashboardConfiguration?: Maybe<UserDashboardConfiguration>;
  talentConfiguration?: Maybe<UserTalentConfiguration>;
  customLandingPageUrl?: Maybe<Scalars['String']>;
  customLandingPageThumbnail?: Maybe<Scalars['String']>;
  pendingLandingPageRequest?: Maybe<Scalars['Boolean']>;
  discoveryCreditCount?: Maybe<Scalars['Int']>;
  whiteLabelConfig?: Maybe<UserWhiteLabelConfig>;
  whiteLabelDetails?: Maybe<UserWhiteLabelConfig>;
  bearerToken?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isUserVerified?: Maybe<Scalars['Boolean']>;
  longTokenPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  facebookMarketingTokenPermissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  influencerPlan?: Maybe<InfluencerPlan>;
  profiles?: Maybe<Array<Profile>>;
  instagram?: Maybe<Array<SocialProfile>>;
  youtube?: Maybe<Array<SocialProfile>>;
  facebook?: Maybe<Array<SocialProfile>>;
  appSubscription?: Maybe<AppSubscription>;
  braintreeSubscription?: Maybe<BraintreeSubscription>;
  braintreeCustomer?: Maybe<BraintreeCustomer>;
  stripeCustomer?: Maybe<StripeCustomer>;
  accountProgressPercentage?: Maybe<Scalars['Float']>;
  ambassadorsCount?: Maybe<Scalars['Int']>;
  delegatesCount?: Maybe<Scalars['Int']>;
  talentsCount?: Maybe<Scalars['Int']>;
  contactsCount?: Maybe<Scalars['Int']>;
  delegate?: Maybe<Delegate>;
  /** @deprecated No longer relevant. */
  limitUsage?: Maybe<LimitUsage>;
  activeSubscription?: Maybe<Scalars['String']>;
  reportsUsage?: Maybe<FeatureUsage>;
  mediaKitsUsage?: Maybe<FeatureUsage>;
  collaborationsUsage?: Maybe<FeatureUsage>;
  invoicesUsage?: Maybe<FeatureUsage>;
  profilesUsage?: Maybe<FeatureUsage>;
  delegatesUsage?: Maybe<FeatureUsage>;
  ambassadorsUsage?: Maybe<FeatureUsage>;
  intercomHash?: Maybe<Scalars['String']>;
  isFirstLogin?: Maybe<Scalars['Boolean']>;
  trialDaysRemaining?: Maybe<Scalars['Int']>;
  availableFacebookAdAccounts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  streetAddress?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UserAddressUpdate = {
  streetAddress?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export enum UserCurrency {
  Usd = 'USD',
  Gbp = 'GBP',
  Eur = 'EUR',
  Aud = 'AUD',
  Nzd = 'NZD',
  Cad = 'CAD',
  Jpy = 'JPY',
  Inr = 'INR'
}

export type UserDashboardConfiguration = {
  __typename?: 'UserDashboardConfiguration';
  firstColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
  secondColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDashboardConfigurationUpdates = {
  firstColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
  secondColumn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  intercomUserHash?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  error?: Maybe<Scalars['String']>;
  delegate?: Maybe<Delegate>;
};

export type UserForCode = {
  __typename?: 'UserForCode';
  userId?: Maybe<Scalars['ObjectId']>;
  dataAccessToken?: Maybe<Scalars['String']>;
  ambassadorId?: Maybe<Scalars['ObjectId']>;
  user?: Maybe<User>;
  ambassador?: Maybe<Ambassador>;
};

export type UserFriendlyCacheError = {
  __typename?: 'UserFriendlyCacheError';
  error?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  nextSteps?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
};

export type UserListResponse = {
  __typename?: 'UserListResponse';
  list?: Maybe<Array<User>>;
  total?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type UserLoginInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  facebookLoginToken?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  googleUserId?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  appleIdentityToken?: Maybe<Scalars['String']>;
  appleLoginCode?: Maybe<Scalars['String']>;
  appleAuthorizationCode?: Maybe<Scalars['String']>;
  tiktokUnionIdToken?: Maybe<Scalars['String']>;
  trustToken?: Maybe<Scalars['String']>;
  authDebugInfo?: Maybe<Scalars['JSObject']>;
  integrationKey?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  shouldStayLoggedIn?: Maybe<Scalars['Boolean']>;
};

export type UserLoginToken = {
  __typename?: 'UserLoginToken';
  type?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type UserPaymentDetails = {
  __typename?: 'UserPaymentDetails';
  currency?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  /** @deprecated Use 'bankCodes'. */
  accountBsb?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['String']>;
  bankCode1?: Maybe<Scalars['String']>;
  bankValue1?: Maybe<Scalars['String']>;
  bankCode2?: Maybe<Scalars['String']>;
  bankValue2?: Maybe<Scalars['String']>;
};

export type UserPaymentDetailsUpdate = {
  currency?: Maybe<UserCurrency>;
  accountName?: Maybe<Scalars['String']>;
  accountBsb?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxType?: Maybe<Scalars['String']>;
  bankCode1?: Maybe<BankCode>;
  bankValue1?: Maybe<Scalars['String']>;
  bankCode2?: Maybe<BankCode>;
  bankValue2?: Maybe<Scalars['String']>;
};

export type UserReward = {
  __typename?: 'UserReward';
  _id: Scalars['ObjectId'];
  userId?: Maybe<Scalars['String']>;
  rybbonReferenceCode?: Maybe<Scalars['String']>;
  claim_url?: Maybe<Scalars['String']>;
  gift_image_url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  rybbonReward?: Maybe<RybbonReward>;
};

export type UserSignupInfo = {
  __typename?: 'UserSignupInfo';
  whatAreYouLookingFor?: Maybe<Scalars['String']>;
};

export type UserSignupInfoInput = {
  whatAreYouLookingFor?: Maybe<Scalars['String']>;
};

export enum UserSubType {
  TalentAgency = 'TALENT_AGENCY',
  MediaPr = 'MEDIA_PR',
  Brand = 'BRAND'
}

export type UserTalentConfiguration = {
  __typename?: 'UserTalentConfiguration';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandProfileColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserTalentConfigurationUpdates = {
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandProfileColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum UserType {
  Brand = 'BRAND',
  Influencer = 'INFLUENCER',
  MediaPr = 'MEDIA_PR',
  TalentAgency = 'TALENT_AGENCY'
}

export type UserUpdates = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<UserAddressUpdate>;
  businessIdentifier?: Maybe<Scalars['String']>;
  paymentDetails?: Maybe<UserPaymentDetailsUpdate>;
  country?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isFacebookVerified?: Maybe<Scalars['Boolean']>;
  isAdminVerified?: Maybe<Scalars['Boolean']>;
  isAddedToMonday?: Maybe<Scalars['Boolean']>;
  influencerPlan?: Maybe<Scalars['String']>;
  influencer_plan?: Maybe<Scalars['String']>;
  isNotificationOn?: Maybe<Scalars['Boolean']>;
  type?: Maybe<UserType>;
  subType?: Maybe<UserSubType>;
  avatar?: Maybe<Scalars['Upload']>;
  isMfaEnabled?: Maybe<Scalars['Boolean']>;
  marketingOptIn?: Maybe<Scalars['Boolean']>;
  activeNotifications?: Maybe<ActiveNotificationsUpdate>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  ambassadorCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  signupInfo?: Maybe<UserSignupInfoInput>;
  isAgency?: Maybe<Scalars['Boolean']>;
  websiteUrl?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  facebookAccessToken?: Maybe<Scalars['String']>;
  facebookAccountName?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  googleAuthCode?: Maybe<Scalars['String']>;
  googleAccountName?: Maybe<Scalars['String']>;
  tiktokUnionIdToken?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  appleIdentityToken?: Maybe<Scalars['String']>;
  appleAuthorizationCode?: Maybe<Scalars['String']>;
  authDebugInfo?: Maybe<Scalars['JSObject']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  detected_timezone?: Maybe<Scalars['String']>;
  dashboardConfiguration?: Maybe<UserDashboardConfigurationUpdates>;
  talentConfiguration?: Maybe<UserTalentConfigurationUpdates>;
};

export type UserWhiteLabelConfig = {
  __typename?: 'UserWhiteLabelConfig';
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  emailLogoUrl?: Maybe<Scalars['String']>;
  emailLogoWidth?: Maybe<Scalars['String']>;
};

export type UserWhiteLabelDetails = {
  __typename?: 'UserWhiteLabelDetails';
  role?: Maybe<Scalars['String']>;
  signup_email?: Maybe<Scalars['String']>;
  signup_date?: Maybe<Scalars['Date']>;
  signup_company_name?: Maybe<Scalars['String']>;
  signup_referrer_uri?: Maybe<Scalars['String']>;
};

export type VideoContent = {
  __typename?: 'VideoContent';
  type?: Maybe<ElementType>;
  video_content?: Maybe<Scalars['String']>;
};

export type YoutubeChannel = {
  __typename?: 'YoutubeChannel';
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  etag?: Maybe<Scalars['String']>;
  snippet?: Maybe<YoutubeChannelSnippet>;
};

export type YoutubeChannelContentDetails = {
  __typename?: 'YoutubeChannelContentDetails';
  relatedPlaylists?: Maybe<YoutubePlaylistInfo>;
};

export type YoutubeChannelInput = {
  id?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['JSObject']>;
};

export type YoutubeChannelSnippet = {
  __typename?: 'YoutubeChannelSnippet';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<YoutubeThumbnailList>;
  localized?: Maybe<YoutubeLocalizedInfo>;
  contentDetails?: Maybe<YoutubeChannelContentDetails>;
  statistics?: Maybe<YoutubeStatistics>;
};

export type YoutubeHistorical = {
  __typename?: 'YoutubeHistorical';
  _id?: Maybe<Scalars['ObjectId']>;
  count?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['String']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  change?: Maybe<YoutubeHistorical>;
  archived_at?: Maybe<Scalars['Date']>;
};

export type YoutubeHistoricalStats = {
  __typename?: 'YoutubeHistoricalStats';
  _id?: Maybe<Scalars['ObjectId']>;
  count?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  start?: Maybe<Scalars['String']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  change?: Maybe<YoutubeHistorical>;
  archived_at?: Maybe<Scalars['Date']>;
  order?: Maybe<Scalars['Int']>;
  latestRecordedDate?: Maybe<Scalars['Date']>;
  dayDirectory?: Maybe<Scalars['JSObject']>;
  days?: Maybe<Array<Maybe<YoutubeHistorical>>>;
};

export type YoutubeLocalizedInfo = {
  __typename?: 'YoutubeLocalizedInfo';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type YoutubeMedia = {
  __typename?: 'YoutubeMedia';
  publishedAt?: Maybe<Scalars['Date']>;
  channelId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnails>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Scalars['String']>;
  liveBroadcastContent?: Maybe<Scalars['String']>;
  _id: Scalars['ObjectId'];
  id: Scalars['ID'];
  profileId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  playlistId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<ResourceId>;
  created_at?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['Date']>;
  archived_at?: Maybe<Scalars['Date']>;
  /** @deprecated Use views. */
  totalViews?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  redViews?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  videosAddedToPlaylists?: Maybe<Scalars['Int']>;
  videosRemovedFromPlaylists?: Maybe<Scalars['Int']>;
  shares?: Maybe<Scalars['Int']>;
  /** @deprecated Use estimatedMinutesWatched. */
  estimatedTotalMinutesWatched?: Maybe<Scalars['Int']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  estimatedRedMinutesWatched?: Maybe<Scalars['Int']>;
  averageViewDuration?: Maybe<Scalars['Int']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
  annotationCloseRate?: Maybe<Scalars['Float']>;
  annotationImpressions?: Maybe<Scalars['Int']>;
  annotationClickableImpressions?: Maybe<Scalars['Int']>;
  annotationClosableImpressions?: Maybe<Scalars['Int']>;
  annotationClicks?: Maybe<Scalars['Int']>;
  annotationCloses?: Maybe<Scalars['Int']>;
  cardClickRate?: Maybe<Scalars['Float']>;
  cardTeaserClickRate?: Maybe<Scalars['Float']>;
  cardImpressions?: Maybe<Scalars['Int']>;
  cardTeaserImpressions?: Maybe<Scalars['Int']>;
  cardClicks?: Maybe<Scalars['Int']>;
  cardTeaserClicks?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  contentDetails?: Maybe<YoutubeVideoContentDetails>;
  status?: Maybe<YoutubeVideoStatus>;
  topicDetails?: Maybe<YoutubeVideoTopicDetails>;
  fileDetails?: Maybe<YoutubeVideoFileDetails>;
  processingDetails?: Maybe<YoutubeVideoProcessingDetails>;
  liveStreamingDetails?: Maybe<YoutubeVideoLiveStreamingDetails>;
  statistics?: Maybe<YoutubeVideoStatistics>;
  latest_record?: Maybe<YoutubeMedia>;
  timestamp?: Maybe<Scalars['Date']>;
  profile?: Maybe<Profile>;
  history?: Maybe<Array<Maybe<YoutubeMediaHistorical>>>;
};

export type YoutubeMediaAverages = {
  __typename?: 'YoutubeMediaAverages';
  date?: Maybe<Scalars['Date']>;
  views?: Maybe<Scalars['Int']>;
  redViews?: Maybe<Scalars['Int']>;
  /** @deprecated Use views. */
  totalViews?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  subscribersLost?: Maybe<Scalars['Int']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  estimatedRedMinutesWatched?: Maybe<Scalars['Int']>;
  /** @deprecated Use estimatedMinutesWatched. */
  estimatedTotalMinutesWatched?: Maybe<Scalars['Int']>;
  averageViewDuration?: Maybe<Scalars['Float']>;
  averageViewPercentage?: Maybe<Scalars['Float']>;
  annotationClickThroughRate?: Maybe<Scalars['Float']>;
};

export type YoutubeMediaHistorical = {
  __typename?: 'YoutubeMediaHistorical';
  views?: Maybe<Scalars['Int']>;
  videoId?: Maybe<Scalars['String']>;
  estimatedMinutesWatched?: Maybe<Scalars['Int']>;
  likes?: Maybe<Scalars['Int']>;
  dislikes?: Maybe<Scalars['Int']>;
  subscribersGained?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  foundDate?: Maybe<Scalars['Date']>;
};

export type YoutubeMediaHistoricalAverages = {
  __typename?: 'YoutubeMediaHistoricalAverages';
  views?: Maybe<YoutubeMetricSummary>;
  estimatedMinutesWatched?: Maybe<YoutubeMetricSummary>;
  likes?: Maybe<YoutubeMetricSummary>;
  dislikes?: Maybe<YoutubeMetricSummary>;
  subscribersGained?: Maybe<YoutubeMetricSummary>;
  comments?: Maybe<YoutubeMetricSummary>;
};

export type YoutubeMediaHistoryResult = {
  __typename?: 'YoutubeMediaHistoryResult';
  mediaHistoricalCount?: Maybe<Scalars['Int']>;
  youtubeMediaHistorical?: Maybe<Array<Maybe<YoutubeMediaHistorical>>>;
  youtubeMediaHistoricalAverages?: Maybe<YoutubeMediaHistoricalAverages>;
};

export type YoutubeMediaListResult = {
  __typename?: 'YoutubeMediaListResult';
  mediaCount?: Maybe<Scalars['Int']>;
  youtubeMedia?: Maybe<Array<Maybe<YoutubeMedia>>>;
  youtubeMediaAverages?: Maybe<Scalars['JSObject']>;
};

export type YoutubeMediaStats = {
  __typename?: 'YoutubeMediaStats';
  videoCount?: Maybe<Scalars['Int']>;
  /** @deprecated Use views. */
  totalViews?: Maybe<YoutubeMetricSummary>;
  views?: Maybe<YoutubeMetricSummary>;
  redViews?: Maybe<YoutubeMetricSummary>;
  comments?: Maybe<YoutubeMetricSummary>;
  likes?: Maybe<YoutubeMetricSummary>;
  dislikes?: Maybe<YoutubeMetricSummary>;
  videosAddedToPlaylists?: Maybe<YoutubeMetricSummary>;
  videosRemovedFromPlaylists?: Maybe<YoutubeMetricSummary>;
  shares?: Maybe<YoutubeMetricSummary>;
  /** @deprecated Use estimatedMinutesWatched. */
  estimatedTotalMinutesWatched?: Maybe<YoutubeMetricSummary>;
  estimatedMinutesWatched?: Maybe<YoutubeMetricSummary>;
  estimatedRedMinutesWatched?: Maybe<YoutubeMetricSummary>;
  averageViewDuration?: Maybe<YoutubeMetricSummary>;
  annotationClickThroughRate?: Maybe<YoutubeMetricSummary>;
  annotationCloseRate?: Maybe<YoutubeMetricSummary>;
  annotationImpressions?: Maybe<YoutubeMetricSummary>;
  annotationClickableImpressions?: Maybe<YoutubeMetricSummary>;
  annotationClosableImpressions?: Maybe<YoutubeMetricSummary>;
  annotationClicks?: Maybe<YoutubeMetricSummary>;
  annotationCloses?: Maybe<YoutubeMetricSummary>;
  cardClickRate?: Maybe<YoutubeMetricSummary>;
  cardTeaserClickRate?: Maybe<YoutubeMetricSummary>;
  cardImpressions?: Maybe<YoutubeMetricSummary>;
  cardTeaserImpressions?: Maybe<YoutubeMetricSummary>;
  cardClicks?: Maybe<YoutubeMetricSummary>;
  cardTeaserClicks?: Maybe<YoutubeMetricSummary>;
  netSubscribers?: Maybe<YoutubeMetricSummary>;
  subscribersGained?: Maybe<YoutubeMetricSummary>;
  subscribersLost?: Maybe<YoutubeMetricSummary>;
  engagement?: Maybe<YoutubeMetricSummary>;
  engagement_percentageOfViews?: Maybe<YoutubeMetricSummary>;
};

export type YoutubeMediaTrends = {
  __typename?: 'YoutubeMediaTrends';
  dailyAverages?: Maybe<Array<Maybe<YoutubeMediaAverages>>>;
  recentAverages?: Maybe<YoutubeMediaAverages>;
  comparisonAverages?: Maybe<YoutubeMediaAverages>;
};

export type YoutubeMetricSummary = {
  __typename?: 'YoutubeMetricSummary';
  actual?: Maybe<Scalars['Int']>;
  median?: Maybe<Scalars['Float']>;
  average?: Maybe<Scalars['Float']>;
};

export type YoutubePlaylistInfo = {
  __typename?: 'YoutubePlaylistInfo';
  likes?: Maybe<Scalars['String']>;
  favorites?: Maybe<Scalars['String']>;
  uploads?: Maybe<Scalars['String']>;
  watchHistory?: Maybe<Scalars['String']>;
  watchLater?: Maybe<Scalars['String']>;
};

export type YoutubeProfile = {
  __typename?: 'YoutubeProfile';
  _id: Scalars['ObjectId'];
  isAddedByOwner?: Maybe<Scalars['Boolean']>;
  agentId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use cacheError */
  cache_error?: Maybe<Scalars['String']>;
  cache_completed_at?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  historical_from?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  media_cached_at?: Maybe<Scalars['Date']>;
  profileCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use 'profileCategory'. */
  profile_category?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileType?: Maybe<SocialChannel>;
  /** @deprecated Use 'profileType'. */
  profile_type?: Maybe<Scalars['String']>;
  profile_cached_at?: Maybe<Scalars['Date']>;
  profile_media_kit?: Maybe<MediaKit>;
  profile_picture_url?: Maybe<Scalars['String']>;
  redflags_from?: Maybe<Scalars['Date']>;
  redflags_until?: Maybe<Scalars['Date']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['String']>;
  tokenUserId?: Maybe<Scalars['ObjectId']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isPendingConnection?: Maybe<Scalars['Boolean']>;
  hasAcceptedInvite?: Maybe<Scalars['Boolean']>;
  doesRequireTrackApproval?: Maybe<Scalars['Boolean']>;
  selectedMediaKitId?: Maybe<Scalars['ObjectId']>;
  profileAuditReportLink?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  media_count?: Maybe<Scalars['Float']>;
  followers_count?: Maybe<Scalars['Int']>;
  channelStats?: Maybe<ChannelStats>;
  fan_count?: Maybe<Scalars['Int']>;
  published_post_count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  union_id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  profile_cache_attempts?: Maybe<Scalars['Int']>;
  media_cache_attempts?: Maybe<Scalars['Int']>;
  last_cache_attempts?: Maybe<Scalars['Int']>;
  cache_error_at?: Maybe<Scalars['Date']>;
  cache_error_resolved_at?: Maybe<Scalars['Date']>;
  last_cache_error?: Maybe<Scalars['String']>;
  user_notified_at?: Maybe<Scalars['Date']>;
  agency_notified_at?: Maybe<Scalars['Date']>;
  historical_until?: Maybe<Scalars['Date']>;
  assignedToAmbassadorId?: Maybe<Scalars['ObjectId']>;
  profile_added_at?: Maybe<Scalars['Date']>;
  ownerNotes?: Maybe<Scalars['String']>;
  ownerTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  isInstagramIncomingMentionsEnabled?: Maybe<Scalars['Boolean']>;
  isInstagramFeedWebhookEnabled?: Maybe<Scalars['Boolean']>;
  pageStats?: Maybe<FacebookPageStats>;
  postStats?: Maybe<FacebookPostStats>;
  facebookVideoStats?: Maybe<FacebookVideoStats>;
  facebookReelStats?: Maybe<FacebookVideoStats>;
  historicalFacebookStats?: Maybe<Array<FacebookPageStats>>;
  weeklyFacebookStats?: Maybe<Array<FacebookWeeklyStats>>;
  rankedFacebookMedia?: Maybe<RankedFacebookMedia>;
  rankedFacebookVideos?: Maybe<RankedFacebookVideos>;
  rankedFacebookReels?: Maybe<RankedFacebookVideos>;
  latestFacebookPosts?: Maybe<Array<FacebookPost>>;
  latestFacebookVideos?: Maybe<Array<FacebookVideo>>;
  latestFacebookReels?: Maybe<Array<FacebookVideo>>;
  facebookPostTrends?: Maybe<FacebookMediaTrends>;
  facebookVideoTrends?: Maybe<FacebookVideoTrends>;
  facebookReelTrends?: Maybe<FacebookVideoTrends>;
  recentFacebookMediaStats?: Maybe<RecentMediaStats>;
  lifetimeInsights?: Maybe<LifetimeInsights>;
  historicalStats?: Maybe<InstagramHistoricalStats>;
  onlineFollowers?: Maybe<Scalars['JSObject']>;
  /** @deprecated Use rankedInstagramMedia.topMedia instead */
  rankedInstagramPosts?: Maybe<Array<InstagramMedia>>;
  rankedInstagramMedia?: Maybe<RankedInstagramMedia>;
  rankedInstagramFeed?: Maybe<RankedInstagramMedia>;
  rankedInstagramReels?: Maybe<RankedInstagramMedia>;
  rankedInstagramStories?: Maybe<RankedInstagramStories>;
  latestInstagramPosts?: Maybe<Array<InstagramMedia>>;
  latestInstagramFeed?: Maybe<Array<InstagramMedia>>;
  latestInstagramReels?: Maybe<Array<InstagramMedia>>;
  latestInstagramStories?: Maybe<Array<InstagramStory>>;
  dailyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  weeklyInstagramStats?: Maybe<Array<InstagramHistorical>>;
  mediaStats?: Maybe<MediaStats>;
  instagramFeedStats?: Maybe<MediaStats>;
  instagramReelStats?: Maybe<MediaStats>;
  storyStats?: Maybe<MediaStats>;
  instagramFeedTrends?: Maybe<InstagramMediaTrends>;
  instagramReelTrends?: Maybe<InstagramMediaTrends>;
  instagramStoryTrends?: Maybe<InstagramStoryTrends>;
  instagramCombinedTrends?: Maybe<InstagramCombinedTrends>;
  followerBracket?: Maybe<Scalars['Int']>;
  latestPerformanceReport?: Maybe<PerformanceReport>;
  instagramMediaMentions?: Maybe<Array<InstagramMedia>>;
  recentInstagramMediaStats?: Maybe<RecentInstagramMediaStats>;
  subscribedWebhookFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  cacheError?: Maybe<Scalars['String']>;
  userFriendlyCacheError?: Maybe<UserFriendlyCacheError>;
  user?: Maybe<User>;
  agent?: Maybe<User>;
  cacheSeconds?: Maybe<Scalars['Int']>;
  mediaKitConfigs?: Maybe<Array<Maybe<MediaKitConfig>>>;
  ownerMediaKitConfigs?: Maybe<Array<MediaKitConfig>>;
  profileNote?: Maybe<ProfileNote>;
  dataAccessToken?: Maybe<Scalars['String']>;
  followerCount?: Maybe<Scalars['Int']>;
  selectedMediaKit?: Maybe<MediaKitConfig>;
  isVisibleOnNetwork?: Maybe<Scalars['Boolean']>;
  mediaKits?: Maybe<Array<MediaKitConfig>>;
  thumbnails?: Maybe<Thumbnails>;
  title?: Maybe<Scalars['String']>;
  videoStats?: Maybe<YoutubeMediaStats>;
  snippet?: Maybe<Scalars['String']>;
  ageGroupBreakdown?: Maybe<Array<Maybe<AgeGroup>>>;
  genderBreakdown?: Maybe<Array<Maybe<GenderGroup>>>;
  locationBreakdown?: Maybe<Array<Maybe<LocationGroup>>>;
  myChannelReport?: Maybe<Array<Maybe<ChannelReport>>>;
  subscriberChange?: Maybe<Array<Maybe<SubscriberChange>>>;
  youtubeMedia?: Maybe<Array<YoutubeMedia>>;
  youtubeHistorical?: Maybe<Array<YoutubeHistorical>>;
  youtubeWeeklyStats?: Maybe<Array<YoutubeHistoricalStats>>;
  rankedYoutubeMedia?: Maybe<RankedYoutubeMedia>;
  youtubeVideoTrends?: Maybe<YoutubeMediaTrends>;
  recentYoutubeMediaStats?: Maybe<RecentMediaStats>;
  public?: Maybe<TiktokPublicUser>;
  tiktokMediaStats?: Maybe<TiktokMediaStats>;
  tiktokMediaTrends?: Maybe<TiktokMediaTrends>;
  rankedTiktokMedia?: Maybe<TikTokRankedMedia>;
  recentTiktokMediaStats?: Maybe<RecentMediaStats>;
  customUrl?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['Date']>;
  localized?: Maybe<Localized>;
  kind?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type YoutubeProfileWeeklyFacebookStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type YoutubeProfileWeeklyInstagramStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type YoutubeProfileYoutubeWeeklyStatsArgs = {
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type YoutubeStatistics = {
  __typename?: 'YoutubeStatistics';
  viewCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
  subscriberCount?: Maybe<Scalars['Int']>;
  hiddenSubscriberCount?: Maybe<Scalars['Int']>;
  videoCount?: Maybe<Scalars['Int']>;
};

export type YoutubeThumbnail = {
  __typename?: 'YoutubeThumbnail';
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type YoutubeThumbnailList = {
  __typename?: 'YoutubeThumbnailList';
  default?: Maybe<YoutubeThumbnail>;
  medium?: Maybe<YoutubeThumbnail>;
  high?: Maybe<YoutubeThumbnail>;
};

export type YoutubeVideoContentDetails = {
  __typename?: 'YoutubeVideoContentDetails';
  duration?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  definition?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  licensedContent?: Maybe<Scalars['Boolean']>;
  contentRating?: Maybe<Scalars['JSObject']>;
  projection?: Maybe<Scalars['String']>;
  hasCustomThumbnail?: Maybe<Scalars['Boolean']>;
};

export type YoutubeVideoFileDetails = {
  __typename?: 'YoutubeVideoFileDetails';
  fileName?: Maybe<Scalars['String']>;
};

export type YoutubeVideoLiveStreamingDetails = {
  __typename?: 'YoutubeVideoLiveStreamingDetails';
  actualStartTime?: Maybe<Scalars['Date']>;
  actualEndTime?: Maybe<Scalars['Date']>;
};

export type YoutubeVideoProcessingDetails = {
  __typename?: 'YoutubeVideoProcessingDetails';
  processingStatus?: Maybe<Scalars['String']>;
  fileDetailsAvailability?: Maybe<Scalars['String']>;
  processingIssuesAvailability?: Maybe<Scalars['String']>;
  tagSuggestionsAvailability?: Maybe<Scalars['String']>;
  editorSuggestionsAvailability?: Maybe<Scalars['String']>;
  thumbnailsAvailability?: Maybe<Scalars['String']>;
};

export type YoutubeVideoSnippet = {
  __typename?: 'YoutubeVideoSnippet';
  publishedAt?: Maybe<Scalars['Date']>;
  channelId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnails>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Scalars['String']>;
  liveBroadcastContent?: Maybe<Scalars['String']>;
};

export type YoutubeVideoStatistics = {
  __typename?: 'YoutubeVideoStatistics';
  viewCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  dislikeCount?: Maybe<Scalars['Int']>;
  favoriteCount?: Maybe<Scalars['Int']>;
  commentCount?: Maybe<Scalars['Int']>;
};

export type YoutubeVideoStatus = {
  __typename?: 'YoutubeVideoStatus';
  uploadStatus?: Maybe<Scalars['String']>;
  privacyStatus?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
  embeddable?: Maybe<Scalars['Boolean']>;
  publicStatsViewable?: Maybe<Scalars['Boolean']>;
  madeForKids?: Maybe<Scalars['Boolean']>;
};

export type YoutubeVideoTopicDetails = {
  __typename?: 'YoutubeVideoTopicDetails';
  topicCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FullUserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  | '_id'
  | 'adminVerificationToken'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'longToken'
  | 'type'
  | 'country'
  | 'referrer'
  | 'accountProgressPercentage'
  | 'created_at'
  | 'lastLoggedIn'
  | 'activeSubscription'
  | 'googleUserId'
  | 'facebookUserId'
  | 'appleUserId'
  | 'isMfaEnabled'
  | 'isAdmin'
  | 'isEmailVerified'
  | 'isFacebookVerified'
  | 'isTiktokVerified'
  | 'tiktokUnionId'
  | 'isAddedToMonday'
> & {
    instagram?: Maybe<
      Array<{ __typename?: 'SocialProfile' } & Pick<SocialProfile, '_id' | 'displayName' | 'cache_error'>>
    >;
    youtube?: Maybe<Array<{ __typename?: 'SocialProfile' } & Pick<SocialProfile, '_id' | 'displayName'>>>;
    facebook?: Maybe<
      Array<{ __typename?: 'SocialProfile' } & Pick<SocialProfile, '_id' | 'displayName' | 'cache_error'>>
    >;
    delegate?: Maybe<
      { __typename?: 'Delegate' } & Pick<Delegate, '_id'> & {
          master?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'email'>>;
        }
    >;
    influencerPlan?: Maybe<{ __typename?: 'InfluencerPlan' } & Pick<InfluencerPlan, '_id' | 'code' | 'planId'>>;
    braintreeSubscription?: Maybe<
      { __typename?: 'BraintreeSubscription' } & Pick<
        BraintreeSubscription,
        'id' | 'planId' | 'price' | 'status' | 'nextBillingDate'
      >
    >;
    stripeCustomer?: Maybe<
      { __typename?: 'StripeCustomer' } & Pick<StripeCustomer, 'id'> & {
          firstSubscription?: Maybe<
            { __typename?: 'StripeSubscription' } & Pick<StripeSubscription, 'id' | 'status' | 'current_period_end'> & {
                firstItem?: Maybe<
                  { __typename?: 'StripeSubscriptionItem' } & {
                    price?: Maybe<
                      { __typename?: 'StripePrice' } & Pick<
                        StripePrice,
                        | 'id'
                        | 'billing_scheme'
                        | 'currency'
                        | 'nickname'
                        | 'product'
                        | 'type'
                        | 'unit_amount'
                        | 'unit_amount_decimal'
                      >
                    >;
                  }
                >;
              }
          >;
        }
    >;
    reportsUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'limit' | 'count'>>;
    mediaKitsUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'count' | 'limit'>>;
    collaborationsUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'count' | 'limit'>>;
    invoicesUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'count' | 'limit'>>;
    profilesUsage?: Maybe<{ __typename?: 'FeatureUsage' } & Pick<FeatureUsage, 'count' | 'limit'>>;
  };

export type UpdateCouponMutationVariables = Exact<{
  couponCodeId: Scalars['String'];
  updates?: Maybe<CouponCodeUpdates>;
}>;

export type UpdateCouponMutation = { __typename?: 'Mutation' } & {
  adminUpdateCoupon?: Maybe<
    { __typename?: 'CouponCode' } & Pick<
      CouponCode,
      | '_id'
      | 'code'
      | 'firstName'
      | 'lastName'
      | 'planId'
      | 'discountId'
      | 'headerImage'
      | 'headerImageMobile'
      | 'notes'
    >
  >;
};

export type CreateCouponMutationVariables = Exact<{
  updates: CouponCodeUpdates;
}>;

export type CreateCouponMutation = { __typename?: 'Mutation' } & {
  adminCreateCoupon: { __typename?: 'CouponCode' } & Pick<
    CouponCode,
    '_id' | 'code' | 'firstName' | 'lastName' | 'planId' | 'discountId' | 'headerImage' | 'headerImageMobile' | 'notes'
  >;
};

export type DeleteCouponMutationVariables = Exact<{
  couponCodeId: Scalars['String'];
}>;

export type DeleteCouponMutation = { __typename?: 'Mutation' } & {
  adminDeleteCoupon: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type UpdatePlanMutationVariables = Exact<{
  planId: Scalars['ObjectId'];
  updates?: Maybe<InfluencerPlanUpdates>;
}>;

export type UpdatePlanMutation = { __typename?: 'Mutation' } & {
  adminUpdatePlan?: Maybe<
    { __typename?: 'InfluencerPlan' } & Pick<InfluencerPlan, '_id' | 'code' | 'planId' | 'notes' | 'expiresAt'>
  >;
};

export type CreatePlanMutationVariables = Exact<{
  updates: InfluencerPlanUpdates;
}>;

export type CreatePlanMutation = { __typename?: 'Mutation' } & {
  adminCreatePlan?: Maybe<
    { __typename?: 'InfluencerPlan' } & Pick<InfluencerPlan, '_id' | 'code' | 'planId' | 'notes' | 'expiresAt'>
  >;
};

export type DeletePlanMutationVariables = Exact<{
  planId: Scalars['ObjectId'];
}>;

export type DeletePlanMutation = { __typename?: 'Mutation' } & {
  adminDeletePlan?: Maybe<{ __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>>;
};

export type UpdatePersistedUserMutationVariables = Exact<{
  userId: Scalars['ObjectId'];
  userUpdates: PersistedUserUpdates;
}>;

export type UpdatePersistedUserMutation = { __typename?: 'Mutation' } & {
  adminUpdatePersistedUser?: Maybe<
    { __typename?: 'PersistedUser' } & Pick<PersistedUser, '_id' | 'email' | 'signupDate' | 'deletedDate' | 'notes'>
  >;
};

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['ObjectId'];
  updates?: Maybe<UserUpdates>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  adminUpdateUser?: Maybe<{ __typename?: 'User' } & FullUserFieldsFragment>;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ObjectId'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation' } & {
  adminDeleteUser: { __typename?: 'ServerResponse' } & Pick<ServerResponse, 'success'>;
};

export type GetCustomerListQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerListQuery = { __typename?: 'Query' } & {
  adminGetCustomerList: Array<
    { __typename?: 'BraintreeCustomer' } & Pick<BraintreeCustomer, 'id' | 'firstName' | 'lastName'>
  >;
};

export type GetSubscriptionlistQueryVariables = Exact<{ [key: string]: never }>;

export type GetSubscriptionlistQuery = { __typename?: 'Query' } & {
  adminGetSubscriptionList: Array<
    { __typename?: 'BraintreeSubscription' } & Pick<
      BraintreeSubscription,
      | 'id'
      | 'price'
      | 'nextBillingDate'
      | 'nextBillAmount'
      | 'firstBillingDate'
      | 'failureCount'
      | 'planId'
      | 'trialPeriod'
    > & { user?: Maybe<{ __typename?: 'User' } & Pick<User, '_id' | 'email' | 'country' | 'firstName' | 'lastName'>> }
  >;
};

export type GetTransactionListQueryVariables = Exact<{ [key: string]: never }>;

export type GetTransactionListQuery = { __typename?: 'Query' } & {
  adminGetTransactionList: Array<
    { __typename?: 'BraintreeTransaction' } & Pick<
      BraintreeTransaction,
      | 'id'
      | 'amount'
      | 'discountAmount'
      | 'type'
      | 'planId'
      | 'status'
      | 'updatedAt'
      | 'createdAt'
      | 'refundedTransactionId'
    > & {
        subscription?: Maybe<
          { __typename?: 'BraintreeSubscription' } & Pick<BraintreeSubscription, 'id'> & {
              user?: Maybe<
                { __typename?: 'User' } & Pick<User, '_id' | 'email' | 'country' | 'firstName' | 'lastName'>
              >;
            }
        >;
      }
  >;
};

export type GetCouponListQueryVariables = Exact<{ [key: string]: never }>;

export type GetCouponListQuery = { __typename?: 'Query' } & {
  adminGetCouponList: Array<
    { __typename?: 'CouponCode' } & Pick<
      CouponCode,
      | '_id'
      | 'code'
      | 'firstName'
      | 'lastName'
      | 'planId'
      | 'discountId'
      | 'headerImage'
      | 'headerImageMobile'
      | 'notes'
    >
  >;
};

export type GetPlansListQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlansListQuery = { __typename?: 'Query' } & {
  adminGetPlansList: Array<
    { __typename?: 'InfluencerPlan' } & Pick<InfluencerPlan, '_id' | 'code' | 'planId' | 'notes' | 'expiresAt'>
  >;
};

export type GetProfileListQueryVariables = Exact<{
  type?: Maybe<SocialChannel>;
  query?: Maybe<Scalars['String']>;
}>;

export type GetProfileListQuery = { __typename?: 'Query' } & {
  adminGetProfileList?: Maybe<
    Array<
      { __typename?: 'Profile' } & Pick<
        Profile,
        | 'profileType'
        | '_id'
        | 'id'
        | 'displayName'
        | 'created_at'
        | 'profile_cached_at'
        | 'cache_completed_at'
        | 'cacheError'
        | 'profileCategory'
        | 'cacheSeconds'
      > & { user?: Maybe<{ __typename?: 'User' } & Pick<User, 'email'>> }
    >
  >;
};

export type GetDeletedUserListQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeletedUserListQuery = { __typename?: 'Query' } & {
  adminGetDeletedUserList: Array<
    { __typename?: 'PersistedUser' } & Pick<
      PersistedUser,
      '_id' | 'email' | 'signupDate' | 'deletedDate' | 'deletedBy' | 'notes'
    >
  >;
};

export type GetUserListQueryVariables = Exact<{
  pageSize?: Maybe<Scalars['Int']>;
  pageNo?: Maybe<Scalars['Int']>;
  type?: Maybe<UserType>;
  query?: Maybe<Scalars['String']>;
  sortField?: Maybe<SortField>;
  filter?: Maybe<Scalars['String']>;
  profileQuery?: Maybe<Scalars['String']>;
  hasFilterOutInternal?: Maybe<Scalars['Boolean']>;
}>;

export type GetUserListQuery = { __typename?: 'Query' } & {
  adminGetUserList: { __typename?: 'UserListResponse' } & Pick<UserListResponse, 'total' | 'totalPages'> & {
      list?: Maybe<Array<{ __typename?: 'User' } & FullUserFieldsFragment>>;
    };
};

export type GetSubscriptionGrowthQueryVariables = Exact<{
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
}>;

export type GetSubscriptionGrowthQuery = { __typename?: 'Query' } & {
  adminGetSubscriptionGrowth: Array<
    { __typename?: 'SubscriptionsAtDate' } & Pick<SubscriptionsAtDate, 'date'> & {
        plans?: Maybe<Array<Maybe<{ __typename?: 'PlanStat' } & Pick<PlanStat, 'id' | 'label' | 'amount'>>>>;
      }
  >;
};

export const FullUserFieldsFragmentDoc = gql`
  fragment FullUserFields on User {
    _id
    adminVerificationToken
    email
    firstName
    lastName
    longToken
    instagram {
      _id
      displayName
      cache_error
    }
    youtube {
      _id
      displayName
    }
    facebook {
      _id
      displayName
      cache_error
    }
    delegate {
      _id
      master {
        _id
        email
      }
    }
    type
    country
    referrer
    accountProgressPercentage
    created_at
    lastLoggedIn
    activeSubscription
    googleUserId
    facebookUserId
    appleUserId
    influencerPlan {
      _id
      code
      planId
    }
    braintreeSubscription {
      id
      planId
      price
      status
      nextBillingDate
    }
    stripeCustomer {
      id
      firstSubscription {
        id
        status
        current_period_end
        firstItem {
          price {
            id
            billing_scheme
            currency
            nickname
            product
            type
            unit_amount
            unit_amount_decimal
          }
        }
      }
    }
    isMfaEnabled
    isAdmin
    isEmailVerified
    isFacebookVerified
    isTiktokVerified
    tiktokUnionId
    isAddedToMonday
    reportsUsage {
      limit
      count
    }
    mediaKitsUsage {
      count
      limit
    }
    collaborationsUsage {
      count
      limit
    }
    invoicesUsage {
      count
      limit
    }
    profilesUsage {
      count
      limit
    }
  }
`;
export const UpdateCouponDocument = gql`
  mutation UpdateCoupon($couponCodeId: String!, $updates: CouponCodeUpdates) {
    adminUpdateCoupon(couponCodeId: $couponCodeId, updates: $updates) {
      _id
      code
      firstName
      lastName
      planId
      discountId
      headerImage
      headerImageMobile
      notes
    }
  }
`;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      couponCodeId: // value for 'couponCodeId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
}
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<
  UpdateCouponMutation,
  UpdateCouponMutationVariables
>;
export const CreateCouponDocument = gql`
  mutation CreateCoupon($updates: CouponCodeUpdates!) {
    adminCreateCoupon(updates: $updates) {
      _id
      code
      firstName
      lastName
      planId
      discountId
      headerImage
      headerImageMobile
      notes
    }
  }
`;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreateCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
}
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<
  CreateCouponMutation,
  CreateCouponMutationVariables
>;
export const DeleteCouponDocument = gql`
  mutation DeleteCoupon($couponCodeId: String!) {
    adminDeleteCoupon(couponCodeId: $couponCodeId) {
      success
    }
  }
`;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      couponCodeId: // value for 'couponCodeId'
 *   },
 * });
 */
export function useDeleteCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
}
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<
  DeleteCouponMutation,
  DeleteCouponMutationVariables
>;
export const UpdatePlanDocument = gql`
  mutation UpdatePlan($planId: ObjectId!, $updates: InfluencerPlanUpdates) {
    adminUpdatePlan(influencerPlanId: $planId, updates: $updates) {
      _id
      code
      planId
      notes
      expiresAt
    }
  }
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const CreatePlanDocument = gql`
  mutation CreatePlan($updates: InfluencerPlanUpdates!) {
    adminCreatePlan(updates: $updates) {
      _id
      code
      planId
      notes
      expiresAt
    }
  }
`;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
}
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const DeletePlanDocument = gql`
  mutation DeletePlan($planId: ObjectId!) {
    adminDeletePlan(influencerPlanId: $planId) {
      success
    }
  }
`;
export type DeletePlanMutationFn = Apollo.MutationFunction<DeletePlanMutation, DeletePlanMutationVariables>;

/**
 * __useDeletePlanMutation__
 *
 * To run a mutation, you first call `useDeletePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlanMutation, { data, loading, error }] = useDeletePlanMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useDeletePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePlanMutation, DeletePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePlanMutation, DeletePlanMutationVariables>(DeletePlanDocument, options);
}
export type DeletePlanMutationHookResult = ReturnType<typeof useDeletePlanMutation>;
export type DeletePlanMutationResult = Apollo.MutationResult<DeletePlanMutation>;
export type DeletePlanMutationOptions = Apollo.BaseMutationOptions<DeletePlanMutation, DeletePlanMutationVariables>;
export const UpdatePersistedUserDocument = gql`
  mutation UpdatePersistedUser($userId: ObjectId!, $userUpdates: PersistedUserUpdates!) {
    adminUpdatePersistedUser(userId: $userId, userUpdates: $userUpdates) {
      _id
      email
      signupDate
      deletedDate
      notes
    }
  }
`;
export type UpdatePersistedUserMutationFn = Apollo.MutationFunction<
  UpdatePersistedUserMutation,
  UpdatePersistedUserMutationVariables
>;

/**
 * __useUpdatePersistedUserMutation__
 *
 * To run a mutation, you first call `useUpdatePersistedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersistedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersistedUserMutation, { data, loading, error }] = useUpdatePersistedUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userUpdates: // value for 'userUpdates'
 *   },
 * });
 */
export function useUpdatePersistedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersistedUserMutation, UpdatePersistedUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePersistedUserMutation, UpdatePersistedUserMutationVariables>(
    UpdatePersistedUserDocument,
    options
  );
}
export type UpdatePersistedUserMutationHookResult = ReturnType<typeof useUpdatePersistedUserMutation>;
export type UpdatePersistedUserMutationResult = Apollo.MutationResult<UpdatePersistedUserMutation>;
export type UpdatePersistedUserMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersistedUserMutation,
  UpdatePersistedUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($userId: ObjectId!, $updates: UserUpdates) {
    adminUpdateUser(userId: $userId, updates: $updates) {
      ...FullUserFields
    }
  }
  ${FullUserFieldsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($userId: ObjectId!) {
    adminDeleteUser(userId: $userId) {
      success
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetCustomerListDocument = gql`
  query GetCustomerList {
    adminGetCustomerList {
      id
      firstName
      lastName
    }
  }
`;

/**
 * __useGetCustomerListQuery__
 *
 * To run a query within a React component, call `useGetCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, options);
}
export function useGetCustomerListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, options);
}
export type GetCustomerListQueryHookResult = ReturnType<typeof useGetCustomerListQuery>;
export type GetCustomerListLazyQueryHookResult = ReturnType<typeof useGetCustomerListLazyQuery>;
export type GetCustomerListQueryResult = Apollo.QueryResult<GetCustomerListQuery, GetCustomerListQueryVariables>;
export const GetSubscriptionlistDocument = gql`
  query GetSubscriptionlist {
    adminGetSubscriptionList {
      id
      price
      nextBillingDate
      nextBillAmount
      firstBillingDate
      failureCount
      planId
      trialPeriod
      user {
        _id
        email
        country
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetSubscriptionlistQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionlistQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionlistQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSubscriptionlistQuery, GetSubscriptionlistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionlistQuery, GetSubscriptionlistQueryVariables>(
    GetSubscriptionlistDocument,
    options
  );
}
export function useGetSubscriptionlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionlistQuery, GetSubscriptionlistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionlistQuery, GetSubscriptionlistQueryVariables>(
    GetSubscriptionlistDocument,
    options
  );
}
export type GetSubscriptionlistQueryHookResult = ReturnType<typeof useGetSubscriptionlistQuery>;
export type GetSubscriptionlistLazyQueryHookResult = ReturnType<typeof useGetSubscriptionlistLazyQuery>;
export type GetSubscriptionlistQueryResult = Apollo.QueryResult<
  GetSubscriptionlistQuery,
  GetSubscriptionlistQueryVariables
>;
export const GetTransactionListDocument = gql`
  query GetTransactionList {
    adminGetTransactionList {
      id
      amount
      discountAmount
      type
      planId
      status
      updatedAt
      createdAt
      refundedTransactionId
      subscription {
        id
        user {
          _id
          email
          country
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetTransactionListQuery__
 *
 * To run a query within a React component, call `useGetTransactionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTransactionListQuery, GetTransactionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionListQuery, GetTransactionListQueryVariables>(
    GetTransactionListDocument,
    options
  );
}
export function useGetTransactionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionListQuery, GetTransactionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionListQuery, GetTransactionListQueryVariables>(
    GetTransactionListDocument,
    options
  );
}
export type GetTransactionListQueryHookResult = ReturnType<typeof useGetTransactionListQuery>;
export type GetTransactionListLazyQueryHookResult = ReturnType<typeof useGetTransactionListLazyQuery>;
export type GetTransactionListQueryResult = Apollo.QueryResult<
  GetTransactionListQuery,
  GetTransactionListQueryVariables
>;
export const GetCouponListDocument = gql`
  query GetCouponList {
    adminGetCouponList {
      _id
      code
      firstName
      lastName
      planId
      discountId
      headerImage
      headerImageMobile
      notes
    }
  }
`;

/**
 * __useGetCouponListQuery__
 *
 * To run a query within a React component, call `useGetCouponListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCouponListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCouponListQuery, GetCouponListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponListQuery, GetCouponListQueryVariables>(GetCouponListDocument, options);
}
export function useGetCouponListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCouponListQuery, GetCouponListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponListQuery, GetCouponListQueryVariables>(GetCouponListDocument, options);
}
export type GetCouponListQueryHookResult = ReturnType<typeof useGetCouponListQuery>;
export type GetCouponListLazyQueryHookResult = ReturnType<typeof useGetCouponListLazyQuery>;
export type GetCouponListQueryResult = Apollo.QueryResult<GetCouponListQuery, GetCouponListQueryVariables>;
export const GetPlansListDocument = gql`
  query GetPlansList {
    adminGetPlansList {
      _id
      code
      planId
      notes
      expiresAt
    }
  }
`;

/**
 * __useGetPlansListQuery__
 *
 * To run a query within a React component, call `useGetPlansListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlansListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlansListQuery, GetPlansListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlansListQuery, GetPlansListQueryVariables>(GetPlansListDocument, options);
}
export function useGetPlansListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlansListQuery, GetPlansListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlansListQuery, GetPlansListQueryVariables>(GetPlansListDocument, options);
}
export type GetPlansListQueryHookResult = ReturnType<typeof useGetPlansListQuery>;
export type GetPlansListLazyQueryHookResult = ReturnType<typeof useGetPlansListLazyQuery>;
export type GetPlansListQueryResult = Apollo.QueryResult<GetPlansListQuery, GetPlansListQueryVariables>;
export const GetProfileListDocument = gql`
  query GetProfileList($type: SocialChannel, $query: String) {
    adminGetProfileList(type: $type, query: $query) {
      profileType
      _id
      id
      displayName
      user {
        email
      }
      created_at
      profile_cached_at
      cache_completed_at
      cacheError
      profileCategory
      cacheSeconds
    }
  }
`;

/**
 * __useGetProfileListQuery__
 *
 * To run a query within a React component, call `useGetProfileListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetProfileListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProfileListQuery, GetProfileListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileListQuery, GetProfileListQueryVariables>(GetProfileListDocument, options);
}
export function useGetProfileListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProfileListQuery, GetProfileListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileListQuery, GetProfileListQueryVariables>(GetProfileListDocument, options);
}
export type GetProfileListQueryHookResult = ReturnType<typeof useGetProfileListQuery>;
export type GetProfileListLazyQueryHookResult = ReturnType<typeof useGetProfileListLazyQuery>;
export type GetProfileListQueryResult = Apollo.QueryResult<GetProfileListQuery, GetProfileListQueryVariables>;
export const GetDeletedUserListDocument = gql`
  query GetDeletedUserList {
    adminGetDeletedUserList {
      _id
      email
      signupDate
      deletedDate
      deletedBy
      notes
    }
  }
`;

/**
 * __useGetDeletedUserListQuery__
 *
 * To run a query within a React component, call `useGetDeletedUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeletedUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeletedUserListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeletedUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDeletedUserListQuery, GetDeletedUserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeletedUserListQuery, GetDeletedUserListQueryVariables>(
    GetDeletedUserListDocument,
    options
  );
}
export function useGetDeletedUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDeletedUserListQuery, GetDeletedUserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeletedUserListQuery, GetDeletedUserListQueryVariables>(
    GetDeletedUserListDocument,
    options
  );
}
export type GetDeletedUserListQueryHookResult = ReturnType<typeof useGetDeletedUserListQuery>;
export type GetDeletedUserListLazyQueryHookResult = ReturnType<typeof useGetDeletedUserListLazyQuery>;
export type GetDeletedUserListQueryResult = Apollo.QueryResult<
  GetDeletedUserListQuery,
  GetDeletedUserListQueryVariables
>;
export const GetUserListDocument = gql`
  query GetUserList(
    $pageSize: Int
    $pageNo: Int
    $type: UserType
    $query: String
    $sortField: SortField
    $filter: String
    $profileQuery: String
    $hasFilterOutInternal: Boolean
  ) {
    adminGetUserList(
      pageSize: $pageSize
      pageNo: $pageNo
      type: $type
      query: $query
      sortField: $sortField
      filter: $filter
      profileQuery: $profileQuery
      hasFilterOutInternal: $hasFilterOutInternal
    ) {
      total
      totalPages
      list {
        ...FullUserFields
      }
    }
  }
  ${FullUserFieldsFragmentDoc}
`;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageNo: // value for 'pageNo'
 *      type: // value for 'type'
 *      query: // value for 'query'
 *      sortField: // value for 'sortField'
 *      filter: // value for 'filter'
 *      profileQuery: // value for 'profileQuery'
 *      hasFilterOutInternal: // value for 'hasFilterOutInternal'
 *   },
 * });
 */
export function useGetUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserListQuery, GetUserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, options);
}
export function useGetUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserListQuery, GetUserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, options);
}
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<typeof useGetUserListLazyQuery>;
export type GetUserListQueryResult = Apollo.QueryResult<GetUserListQuery, GetUserListQueryVariables>;
export const GetSubscriptionGrowthDocument = gql`
  query GetSubscriptionGrowth($start: Date, $end: Date) {
    adminGetSubscriptionGrowth(start: $start, end: $end) {
      date
      plans {
        id
        label
        amount
      }
    }
  }
`;

/**
 * __useGetSubscriptionGrowthQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionGrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionGrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionGrowthQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetSubscriptionGrowthQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSubscriptionGrowthQuery, GetSubscriptionGrowthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionGrowthQuery, GetSubscriptionGrowthQueryVariables>(
    GetSubscriptionGrowthDocument,
    options
  );
}
export function useGetSubscriptionGrowthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionGrowthQuery, GetSubscriptionGrowthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionGrowthQuery, GetSubscriptionGrowthQueryVariables>(
    GetSubscriptionGrowthDocument,
    options
  );
}
export type GetSubscriptionGrowthQueryHookResult = ReturnType<typeof useGetSubscriptionGrowthQuery>;
export type GetSubscriptionGrowthLazyQueryHookResult = ReturnType<typeof useGetSubscriptionGrowthLazyQuery>;
export type GetSubscriptionGrowthQueryResult = Apollo.QueryResult<
  GetSubscriptionGrowthQuery,
  GetSubscriptionGrowthQueryVariables
>;
