import * as React from 'react';
import alex from './alex.png';
import anthony from './anthony.png';
import david from './david.png';
import felix from './felix.png';
import jeremy from './jeremy.png';
import marsha from './marsha.png';
import './drop_effect.css';

const userImages = [alex, anthony, david, felix, jeremy, marsha];

const getRandomUser = () => userImages[Math.floor(Math.random() * userImages.length)];

const Loader = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          backgroundImage: `url(${getRandomUser()})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
        className={'spinner'}
      ></div>
    </div>
  );
};

export default Loader;
