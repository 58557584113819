import React, { useState } from 'react';
import axios from 'axios';
import api from '../config/api';

import get from 'lodash/get';

import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogContent';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

import BackupIcon from '@mui/icons-material/Backup';

interface ImageUploaderProps {
  onUpload: (path: string) => void;
}

const ImageUploader = (props: ImageUploaderProps) => {
  const { onUpload } = props;

  const [imageFile, setImageFile] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);

  const handleSelectImage = (e: any) => {
    let file = get(e, ['target', 'files', 0]);
    setImageFile(file);
    if (file) {
      setIsLoading(true);
      api(`admin/getUploadUrl`, {
        key: `coupon_signup/${file.name}`
      })
        .then((response) => {
          setIsLoading(false);
          setUploadUrl(get(response, 'data.link'));
        })
        .catch((ex) => {
          setIsLoading(false);
          console.log('Axios upload EXCEPTION', ex);
        });
    }
  };

  const handleUpload = () => {
    if (uploadUrl && imageFile) {
      setIsLoading(true);
      axios
        .put(uploadUrl, imageFile, {
          headers: {
            'Content-Type': imageFile.type,
            'Content-Length': imageFile.size
          }
        })
        .then(() => {
          setIsLoading(false);
          setIsOpen(false);
          setImageFile(null);
          onUpload(`https://media.q-83.com/coupon_signup/${imageFile.name}`);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log('Axios upload ERROR', error);
          console.log(error.response);
        });
    }
  };

  if (isOpen) {
    return (
      <Dialog open={true}>
        <DialogTitle>{'Image uploader'}</DialogTitle>
        <DialogContent>
          {isLoading && <CircularProgress />}
          <DialogContentText style={{ display: isLoading ? 'none' : 'block' }} id='alert-dialog-description'>
            Select an image from your computer
          </DialogContentText>
          <input
            style={{ display: isLoading ? 'none' : 'block' }}
            type='file'
            accept='image/*'
            onChange={handleSelectImage}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsLoading(false);
              setIsOpen(false);
              setImageFile(null);
            }}
            color='secondary'
          >
            Cancel
          </Button>
          {Boolean(imageFile) && (
            <Button onClick={handleUpload} color='primary'>
              Upload!
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Fab
      color='primary'
      size='small'
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <BackupIcon />
    </Fab>
  );
};

export default ImageUploader;
